import React, {createContext, useState} from 'react';
import BannerService from "../services/BannerService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";


export const BannerContext = createContext("BannerContext");

const BannerContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [bannerList, setBannerList] = useState([]);

    const getAllBanner = async params => {
        try {

            setLoading(true);

            const res = await BannerService.getAllBanner(params);
            setBannerList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <BannerContext.Provider
            value={{
                loading,
                totalElements,
                bannerList,
                getAllBanner,
            }}
        >
            {children}
        </BannerContext.Provider>
    );
}

export default BannerContextProvider;
