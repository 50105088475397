import {Locales} from "../Locales";

export const ur_PK = {
    [Locales.urdu]: {
        // common
        "common.error": "خطا ",
        "common.created": "بنایا گیا ",
        "common.added": "شامل کیا گیا ",
        "common.updated": "اپ ڈیٹ کیا گیا ",
        "common.submit": "جمع کریں ",
        "common.email": "ای میل ",
        "common.password": "پاس ورڈ ",
        "common.all": "سب ",
        "common.action": "عمل ",
        "common.reset": "ری سیٹ ",
        "common.search_list": "تلاش کی فہرست ",
        "common.select_language": "زبان منتخب کریں ",
        "common.language_required": "براہ کرم ایک زبان منتخب کریں ",
        "common.image": "تصویر ",
        "common.address": "پتہ ",
        "common.city": "شہر ",
        "common.country": "ملک ",
        "common.zipcode": "زپ کوڈ ",
        "common.assigned": "تفویض شدہ ",
        "common.yes": "ہاں ",
        "common.no": "نہیں ",
        "common.approved": "منظور ",
        "common.approve": "منظوری دینا ",
        "common.approve_small": "منظوری ",
        "common.disable": "غیر فعال ",
        "common.disable_small": "غیر فعال کریں ",
        "common.password_changed": "پاس ورڈ تبدیل ہوگیا ",
        "common.confirm_msg": "کیا آپ واقعی یہ کرنا چاہتے ہیں ",
        "common.the_order": "آرڈر ",
        "common.the_product": "مصنوعات ",
        "common.the_promo": "پروموشن ",
        "common.the_banner": "بینر ",
        "common.the_add_ons": "اضافہ کریں ",
        "common.success_msg_has": "ہوگیا ہے ",
        "common.success": "کامیابی سے ",
        "common.activated": "فعال ",
        "common.mile": "میل ",
        "common.create": "تخلیق کریں ",
        "common.update": "اپ ڈیٹ کریں ",
        "common.optional": "اختیاری ",
        "common.add": "شامل کریں ",
        "common.created_at": "تخلیق کیا گیا ",
        "common.created_date": "تاریخ تخلیق شدہ ",
        "common.cancelled": "منسوخ ",
        "common.download": "ڈاؤن لوڈ کریں ",
        "common.date": "تاریخ ",
        "common.items": "اشیاء ",
        "common.assign_to_branch": "اپنی شاخ کو تفویض کریں ",
        "common.edit": "ترمیم ",
        "common.details": "تفصیلات ",

        // nav header
        "nav_header.profile": "پروفائل ",
        "nav_header.logout": "لاگ آوٹ ",
        "nav_header.english": "انگریزی ",
        "nav_header.bengali": "بنگالی ",
        "nav_header.urdu": "اردو ",
        "nav_header.hindi": "ہندی ",
        "nav_header.pashto": "پشتو ",

        // aside left
        "aside_left.dashboard": "ڈیش بورڈ ",
        "aside_left.user_management": "صارف کی انتظام ",
        "aside_left.user_list": "صارف کی فہرست ",
        "aside_left.product_management": "پروڈکٹ کی انتظام ",
        "aside_left.product": "مصنوعات ",
        "aside_left.product_category": "کیٹیگری ",
        "aside_left.product_add_ons": "اضافہ کریں ",
        "aside_left.branch": "شاخ ",
        "aside_left.branch_list": "شاخ کی فہرست ",
        "aside_left.orders": "آرڈرز ",
        "aside_left.roles": "کردار ",
        "aside_left.assign_employee": "ملازم کو تفویض کریں ",
        "aside_left.requested_product": "درخواست دی گئی مصنوعات ",
        "aside_left.requested_add_ons_list": "درخواست دی گئی مصنوعات ",
        "aside_left.branch_product": "شاخ مصنوعات ",
        "aside_left.branch_add_ons": "شاخ اضافہ کریں ",
        "aside_left.promo": "پروموشن ",
        "aside_left.global": "عالمی ",
        "aside_left.employee_list": "ملازمین کی فہرست ",
        "aside_left.customer_list": "صارف کی فہرست ",
        "aside_left.global_config": "عالمی ترتیب ",
        "aside_left.customer": "صارف ",
        "aside_left.banner": "بینر ",
        "aside_left.analytics": "تجزیات ",
        "aside_left.catering": "کیٹرنگ ",
        "aside_left.catering_products": "کیٹرنگ مصنوعات ",
        "aside_left.catering_sections": "کیٹرنگ سیکشن ",
        "aside_left.catering_menu": "کیٹرنگ مینو ",
        "aside_left.catering_order": "کیٹرنگ آرڈرز ",
        "aside_left.kitchen_display": "باورچی خانہ ڈسپلے ",
        "aside_left.pos": "پوز ",
        "aside_left.ingredient_management": "عنصر کی انتظام ",
        "aside_left.ingredient_category": "انگریڈینٹ کیٹیگری ",
        "aside_left.ingredient": "انگریڈینٹ ",
        "aside_left.requested_ingredient": "درخواست دی گئی انگریڈینٹ ",
        "aside_left.supplier": "سپلائر ",
        "aside_left.stock_management": "اسٹاک کی انتظام ",
        "aside_left.stock": "اسٹاک ",
        "aside_left.stock_in": "اسٹاک ان ",
        "aside_left.stock_out": "اسٹاک آؤٹ ",
        "aside_left.expense": "اخراجات ",
        "aside_left.agent": "ایجنٹ ",
        "aside_left.agent_management": "ایجنٹ کی انتظام ",
        "aside_left.agent_payment": "ایجنٹ کی ادائیگی ",
        "aside_left.branch_config": "شاخ کی ترتیب ",
        "aside_left.stock_summary": "اسٹاک کی خلاصہ ",
        "aside_left.expired_stock": "منقضی ہوئی اسٹاک ",
        "aside_left.table": "ٹیبل ",
        "aside_left.requested_products": "درخواست دی گئی مصنوعات ",
        "aside_left.catering_product": "کیٹرنگ مصنوعات ",
        "aside_left.order": "آرڈر ",
        "aside_left.requested_menu": "درخواست دی گئی مینو ",
        "aside_left.franchises": "فرانچائز",
        "aside_left.order_management": "آرڈر مینجمنٹ",
        "aside_left.order_reports": "آرڈر رپورٹس",
        "aside_left.order_item_reports": "آرڈر آئٹم رپورٹس",
        "aside_left.order_history": "آرڈر کی تاریخ",

        // owner login
        "login.email_required_msg": "ای میل درکار ہے",
        "login.valid_email_msg": "براہ کرم ایک درست ای میل درج کریں",
        "login.password_required_msg": "پاس ورڈ درکار ہے",
        "login.sign_in": "سائن ان",
        "login.sign_in_msg": "سائن ان کرنے کے لئے اپنا ای میل اور پاس ورڈ درج کریں!",

        // user
        "user.total_employee": "کل ملازمین",
        "user.create_user": "صارف بنائیں",
        "user.update_user": "صارف کو اپ ڈیٹ کریں",
        "user.full_name": "پورا نام",
        "user.create_success_msg": "صارف کامیابی سے بنا دیا گیا ہے",
        "user.update_success_msg": "صارف کامیابی سے اپ ڈیٹ کردیا گیا ہے",
        "user.user_data_not_available": "صارف کے ڈیٹا دستیاب نہیں ہے",
        "user.profile_picture": "پروفائل تصویر",
        "user.phone_number": "فون نمبر",
        "user.date_of_birth": "پیدائش کی تاریخ",
        "user.gender": "صنف",
        "user.active": "فعال",
        "user.disabled": "غیر فعال",
        "user.banned": "معطل",
        "user.verified": "تصدیق شدہ",
        "user.email_verified": "ای میل تصدیق شدہ",
        "user.phone_verified": "فون تصدیق شدہ",
        "user.profile": "پروفائل",
        "user.profile_update_msg": "پروفائل کامیابی سے اپ ڈیٹ کردی گئی ہے",
        "user.changed_password_msg": "پاس ورڈ کامیابی سے تبدیل کر دیا گیا ہے",
        "user.create_employee": "ملازم بنائیں",
        "user.update_employee": "ملازم کو اپ ڈیٹ کریں",
        "user.select_role": "براہ کرم کردار منتخب کریں",

        // user form
        "user_form.full_name_required": "پورا نام درکار ہے",
        "user_form.email_required": "ای میل نام درکار ہے",
        "user_form.male": "نر",
        "user_form.female": "مادہ",
        "user_form.others": "دیگر",
        "user_form.role_required": "کردار ضروری ہے",
        "user_form.phone_number_required": "فون نمبر درکار ہے",
        "user_form.invalid_email": "غلط ای میل",
        "user_form.hotline": "ہوٹ لائن",
        "user_form.hotline_required": "ہوٹ لائن درکار ہے",

        // customer
        "customer.total_customer": "کل گاہک",
        "customer.customer_list": "گاہک کی فہرست",
        "customer.customer_name": "گاہک کا نام",
        "customer.customer_create_msg": "گاہک کامیابی سے بنا دیا گیا ہے",
        "customer.invalid_phone": "غلط فون نمبر",
        "customer.customer_name_required": "گاہک کا نام ضروری ہے",
        "customer.cancel": "منسوخ کریں",
        "customer.save": "محفوظ کریں",
        "customer.customer_phone": "گاہک کا فون",

        // role
        "role.role_list": "کردار کی فہرست",
        "role.roles": "کردار",
        "role.create_role": "کردار بنائیں",
        "role.update_role": "کردار کو اپ ڈیٹ کریں",
        "role.create_success_msg": "کردار کامیابی سے بنا دیا گیا ہے",
        "role.update_success_msg": "کردار کامیابی سے اپ ڈیٹ کردیا گیا ہے",
        "role.role_data_not_available": "کردار کا ڈیٹا دستیاب نہیں ہے",
        "role.alias": "عرف",
        "role.role_level": "کردار سطح",
        "role.select_role": "کردار منتخب کریں",

        // role form
        "role_form.predefine_role_msg": "یہ ایک پیش سے تعین شدہ کردار ہے لہذا آپ عرف کو ایڈٹ نہیں کرسکتے",
        "role_form.alias_required_msg": "عرف درکار ہے",
        "role_form.all": "سب",
        "role_form.system": "نظام",
        "role_form.restaurant": "ریستوراں",
        "role_form.branch": "شاخ",
        "role_form.customer": "گاہک",
        "role_form.role_level_required": "کردار کا سطح ضروری ہے",

        // مصنوعات
        "product.product_list": "مصنوعات کی فہرست",
        "product.previous_product_list": "پچھلا آرڈر شدہ مصنوعات کی فہرست",
        "product.branch_product_list": "شاخ مصنوعات کی فہرست",
        "product.create_product": "مصنوعہ بنائیں",
        "product.name": "مصنوعہ کا نام",
        "product.create_success_msg": "مصنوعہ بنانے میں کامیابی",
        "product.update_success_msg": "مصنوعہ کی تازہ کاری کامیاب",
        "product.update_product": "مصنوعہ کی تازہ کاری",
        "product.product_not_available": "مصنوعہ کے اعداد و شمار دستیاب نہیں ہیں",
        "product.type": "مصنوعہ کی قسم",
        "product.select_type": "مصنوعہ کی قسم منتخب کریں",
        "product.category": "مصنوعہ کی قسم",
        "product.price": "قیمت",
        "product.description": "تفصیل",
        "product.single_type": "ایک شہری",
        "product.combo_type": "کومبو",
        "product.select_product": "مصنوعہ منتخب کریں",
        "product.enable": "فعال کریں",
        "product.quantity": "مقدار",
        "product.discount_percent": "ڈسکاؤنٹ فیصد",
        "product.discount_percent_negative": "ڈسکاؤنٹ فیصد مثبت نمبر ہونا چاہئے",
        "product.approved_msg": "مصنوعہ کی منظوری ہوگئی ہے",
        "product.approved_status_msg": "مصنوعہ",
        "product.sold_unit": "فروخت ہوئی یونٹ",
        "product.combo_items": "کومبو مصنوعات",
        "product.popular": "مقبول",
        "product.change_popular_status": "مقبول حیثیت تبدیل کریں",
        "product.popular_status_sure": "کیا آپ واقعی مقبول حیثیت تبدیل کرنا چاہتے ہیں؟",
        "product.related_products": "متعلقہ مصنوعات",
        "product.shortcut_key": "شارٹکٹ کی",
        "product.product_small": "مصنوعہ",

        // درخواست شدہ مصنوعات
        "requested_product.requested_product_list": "درخواست شدہ مصنوعات کی فہرست",

        // شاخ کی درخواست مصنوعات
        "branch_request_product.assign_success_msg": "مصنوعہ کو کامیابی سے تفویض کردیا گیا ہے",
        "branch_request_product.request_product": "مصنوعہ کی درخواست",
        "branch_request_product.create_product": "مصنوعہ کی درخواست بنائیں",
        "branch_request_product.update_product": "مصنوعہ کی درخواست تازہ کاری کریں",
        "branch_request_product.request_success_msg": "مصنوعہ کی درخواست بنا دی گئی ہے",
        "branch_request_product.request_update_success_msg": "مصنوعہ کی درخواست کی تازہ کاری کامیابی سے",

        // مصنوعہ فارم
        "product_form.name_required": "مصنوعہ کا نام ضروری ہے",
        "product_form.type_required": "مصنوعہ کی قسم ضروری ہے",
        "product_form.category_required": "مصنوعہ کی قسم ضروری ہے",
        "product_form.price_required": "مصنوعہ کی قیمت ضروری ہے",
        "product_form.duplicate_product": "آپ نے پہلے ہی اس مصنوعہ کو شامل کر لیا ہے",
        "product_form.negative_price": "قیمت صفر سے زیادہ ہونی چاہئے",
        "product_form.product_sale_threshold": "زیادہ سیل مقدار",
        "product_form.product_sale_threshold_negative": "زیادہ سیل مقدار مثبت ہونی چاہئے",

        // مصنوعہ کی قسم
        "product_category.total_category": "کل قسم",
        "product_category.category_list": "قسم کی فہرست",
        "product_category.create_category": "قسم بنائیں",
        "product_category.update_category": "قسم کی تازہ کاری کریں",
        "product_category.create_success_msg": "قسم بنانے میں کامیابی",
        "product_category.update_success_msg": "قسم کی تازہ کاری کامیاب",
        "product_category.category_data_not_available": "قسم کے اعداد و شمار دستیاب نہیں ہیں",
        "product_category.category_name": "قسم کا نام",
        "product_category.available_from": " دستیاب ہے",
        "product_category.available_to": "تک دستیاب",
        "product_category.available_days": "دستیاب دن",
        "product_category.available_week_days": "ہفتے کے دن دستیاب",


        // عام مصنوعاتی قسم
        "ingredient_category.ingredient_category_list": "مصنوعات کی قسم کی فہرست",
        "ingredient_category.create_ingredient_category": "مصنوعات کی قسم بنائیں",
        "ingredient_category.total_ingredient_category": "کل مصنوعات کی قسم",
        "ingredient_category.ingredient_category_name": "مصنوعات کی قسم کا نام",
        "ingredient_category.description": "تفصیل",

        // مصنوعہ
        "ingredient.ingredient_list": "مصنوعات کی فہرست",
        "ingredient.create_ingredient": "مصنوعہ بنائیں",
        "ingredient.total_ingredient": "کل شاخ مصنوعات",
        "ingredient.ingredient_name": "مصنوعہ کا نام",
        "ingredient.description": "تفصیل",
        "ingredient.category_name": "قسم کا نام",
        "ingredient.unit": "یونٹ",
        "ingredient.quantity": "مقدار",
        "ingredient.default_price": "اعتباری قیمت",
        "ingredient.default_price_positive": "اعتباری قیمت مثبت عدد ہونا ضروری ہے",
        "ingredient.required_ingredient_name": "مصنوعہ کا نام ضروری ہے",
        "ingredient.required_unit": "یونٹ ضروری ہے",
        "ingredient.update_ingredient": "مصنوعہ کی تازہ کاری",
        "ingredient.approved_msg": "مصنوعہ کی منظوری ہوگئی ہے",
        "ingredient.unApproved_msg": "مصنوعہ کی منظوری منسوخ کر دی گئی ہے",
        "ingredient.search_by_name": "مصنوعہ کے نام سے تلاش کریں",
        "ingredient.select_unit": "یونٹ منتخب کریں",
        "ingredient.price_positive": "قیمت مثبت عدد ہونا چاہئے",

        // درخواست شدہ مصنوعات
        "requested_ingredient.requested_ingredient_list": "درخواست شدہ مصنوعات کی فہرست",
        "requested_ingredient.total_requested_ingredient": "کل درخواست شدہ مصنوعات",
        "requested_ingredient.requested_ingredient_name": "درخواست شدہ مصنوعہ کا نام",
        "requested_ingredient.update_requested_ingredient": "درخواست شدہ مصنوعہ کی تازہ کاری",
        "requested_ingredient.create_success_msg": "مصنوعہ کامیابی سے بنایا گیا ہے",

        // شاخ کی عام مصنوعاتی قسم
        "branch_ingredient.create_branch_ingredient": "شاخ مصنوعات بنائیں",
        "branch_ingredient.branch_ingredient_list": "شاخ مصنوعات کی فہرست",
        "branch_ingredient.total_branch_ingredient": "کل شاخ مصنوعات",
        "branch_ingredient.branch_ingredient_name": "شاخ مصنوعات کا نام",
        "branch_ingredient.request_branch_ingredient": "شاخ مصنوعات کی درخواست",
        "branch_ingredient.create_request_branch_ingredient": "شاخ مصنوعات کی درخواست بنائیں",
        "branch_ingredient.update_branch_ingredient": "شاخ مصنوعات کی تازہ کاری",
        "branch_ingredient.update_ingredient": "مصنوعہ کی تازہ کاری",
        "branch_ingredient.quantity": "مقدار",
        "branch_ingredient.assign_supplier": "سپلائر کو تفویض کریں",
        "branch_ingredient.update_msg": "شاخ مصنوعات کی تازہ کاری کامیابی سے ہوگئی ہے",
        "branch_request_ingredient.assign_success_msg": "شاخ مصنوعات کو کامیابی سے تفویض کر دیا گیا ہے",

        // سپلائر
        "supplier.supplier_list": "سپلائر کی فہرست",
        "supplier.create_supplier": "سپلائر بنائیں",
        "supplier.update_supplier": "سپلائر کی تازہ کاری کریں",
        "supplier.total_supplier": "کل سپلائر",
        "supplier.supplier_name": "سپلائر کا نام",
        "supplier.description": "تفصیل",
        "supplier.update_successfully": "سپلائر کامیابی سے اپ ڈیٹ کیا گیا ہے",
        "supplier.supplier_name_required": "سپلائر کا نام ضروری ہے",
        "supplier.supplier_data_unavailable": "سپلائر کی معلومات دستیاب نہیں ہیں",
        "supplier.supplier_assigned_msg": "سپلائر کو کامیابی سے تفویض کر دیا گیا ہے",

        // ایجنٹ
        "agent.agent_list": "ایجنٹ کی فہرست",
        "agent.create_agent": "ایجنٹ بنائیں",
        "agent.update_agent": "ایجنٹ کی تازہ کاری کریں",
        "agent.add_agent": "ایجنٹ شامل کریں",
        "agent.total_agent": "کل ایجنٹ",
        "agent.agent_name": "ایجنٹ کا نام",
        "agent.name": "نام",
        "agent.email": "ای میل",
        "agent.mobile_number": "موبائل نمبر",
        "agent.description": "تفصیل",
        "agent.address": "پتہ",
        "agent.agent_create_msg": "ایجنٹ کامیابی سے بنایا گیا ہے",
        "agent.advance": "اعلی",
        "agent.payments": "ادائیگیاں",

        // ایجنٹ فارم
        "agent_form.name_required": "ایجنٹ کا نام ضروری ہے",
        "agent_form.mobile_num_required": "موبائل نمبر ضروری ہے",
        "agent_form.address_required": "پتہ ضروری ہے",
        "agent.agent_update_msg": "ایجنٹ کی تازہ کاری کامیابی سے",

        // ایجنٹ ادائیگی
        "agent_payment.cancel_msg": "ایجنٹ کی ادائیگی کامیابی سے منسوخ ہوگئی ہے",
        "agent_payment.payment_category": "ادائیگی کی قسم",
        "agent_payment.receive_amount": "رقم وصول کریں",
        "agent_payment.cancel_payment": "ایجنٹ کی ادائیگی منسوخ کریں",
        "agent_payment.cancel_confirm_msg": "کیا آپ واقعی ایجنٹ کی ادائیگی منسوخ کرنا چاہتے ہیں",

        // ایجنٹ ادائیگی فارم
        "agent_payment_form.amount": "رقم",
        "agent_payment_form.amount_required": "رقم ضروری ہے",
        "agent_payment_form.amount_positive": "رقم صفر سے زیادہ ہونی چاہئے",
        "agent_payment_form.payment_category_required": "ادائیگی کی قسم ضروری ہے",
        "agent_payment_form.received": "وصول شدہ",
        "agent_payment_form.payment_type_required": "ادائیگی کی قسم ضروری ہے",
        "agent_payment_form.cash": "نقد",
        "agent_payment_form.mfs": "موبائل فون سروس",
        "agent_payment_form.payment_create_msg": "ایجنٹ کی ادائیگی کامیابی سے بنائی گئی ہے",
        "agent_payment_form.payment_update_msg": "ایجنٹ کی ادائیگی کامیابی سے تازہ کی گئی ہے",
        "agent_payment_form.payment_unavailable": "ادائیگی کی معلومات دستیاب نہیں ہیں",

        // شاخ سپلائر
        "supplier.assign_ingredient": "مصنوعہ تفویض کریں",
        "supplier.assign": "تفویض کریں",
        "supplier.ingredient_required": "مصنوعہ درکار ہے",
        "supplier.supplier_created_successfully": "سپلائر کامیابی سے بنایا گیا ہے",

        // مصنوعاتی قسم فارم
        "product_category_form.required_category_name": "قسم کا نام ضروری ہے",

        // ایڈ-آنز
        "product_add_ons.total_add_on": "کل ایڈ-آنز",
        "product_add_ons.add_ons_list": "ایڈ-آنز کی فہرست",
        "product_add_ons.update_add_ons": "ایڈ-آنز کی تازہ کاری",
        "product_add_ons.branch_add_ons_list": "شاخ ایڈ-آنز کی فہرست",
        "product_add_ons.create_add_ons": "ایڈ-آنز بنائیں",
        "product_add_ons.create_success_msg": "ایڈ-آنز کامیابی سے بنایا گیا ہے",
        "product_add_ons.update_success_msg": "ایڈ-آنز کامیابی سے تازہ کیا گیا ہے",
        "product_add_ons.add_ons_not_available": "ایڈ-آنز کی معلومات دستیاب نہیں ہیں",
        "product_add_ons.add_ons_name": "ایڈ-آنز کا نام",
        "product_add_ons.select_add_ons": "ایڈ-آنز منتخب کریں",
        "product_add_ons.duplicate_add_ons": "یہ ایڈ-آنز پہلے ہی منتخب ہے",
        "product_add_ons.approve_msg": "ایڈ-آنز کو کامیابی سے منظور کر دیا گیا ہے",
        "product_add_ons.approve_status_msg": "ایڈ-آنز منظوری کی حالت کو کامیابی سے اپ ڈیٹ کیا گیا ہے",

        // ایڈ-آنز فارم
        "product_add_ons_form.add_ons_name_required": "ایڈ-آنز کا نام ضروری ہے",
        "product_add_ons_form.add_ons_price_negative": "ایڈ-آنز کی قیمت مثبت نمبر ہونی چاہئے",

        // ایڈ-آنز درخواست
        "request_aad_ons.request_add_ons": "ایڈ-آنز کی درخواست",
        "request_aad_ons.create_success_msg": "ایڈ-آنز کی درخواست کامیابی سے بنائی گئی ہے",
        "request_aad_ons.update_success_msg": "ایڈ-آنز کی درخواست کامیابی سے تازہ کی گئی ہے",
        "request_aad_ons.create_request_add_ons": "ایڈ-آنز کی درخواست بنائیں",
        "request_aad_ons.update_request_add_ons": "ایڈ-آنز کی درخواست کو تازہ کریں",

        // شاخ
        "branch.branch_list": "شاخ کی فہرست",
        "branch.branch": "شاخ",
        "branch.create_branch": "شاخ بنائیں",
        "branch.create_success_msg": "شاخ کامیابی سے بنائی گئی ہے",
        "branch.branch_name": "شاخ کا نام",
        "branch.update_success_msg": "شاخ کامیابی سے تازہ کی گئی ہے",
        "branch.update_branch": "شاخ کو اپ ڈیٹ کریں",
        "branch.branch_data_not_available": "شاخ کی معلومات دستیاب نہیں ہے",
        "branch.select_branch": "شاخ منتخب کریں",
        "branch.branch_details": "شاخ کی تفصیلات",
        "branch.employee": "ملازم",
        "branch.total_branch": "کل شاخ",

        // شاخ فارم
        "branch_form.branch_name_required": "شاخ کا نام ضروری ہے",
        "branch_form.select_restaurant": "ریستوراں منتخب کریں",
        "branch_form.restaurant_required": "ریستوراں ضروری ہے",
        "branch_form.enabled": "فعال",
        "branch_form.delivery_radius": "ڈلیوری علاقہ ردیوس (میل)",
        "branch_form.delivery_radius_required": "ڈیلیوری علاقہ ردیوس ضروری ہے",
        "branch_form.branch_location": "شاخ کا مقام",
        "branch_form.branch_location_required": "شاخ کا مقام ضروری ہے",
        "branch_form.order_types": "آرڈر کی قسمیں",
        "branch_form.opening_time": "شروع ہونے کا وقت",
        "branch_form.closing_time": "بند ہونے کا وقت",
        "branch_form.deliver_radius_negative": "ڈیلیوری علاقہ ردیوس صفر سے زیادہ ہونا چاہئے",

        // franchise
        "franchise.franchise_list": "فرانچائز لسٹ",
        "franchise.franchise": "فرانچائز",
        "franchise.create_franchise": "فرانچائز بنائیں",
        "franchise.create_success_msg": "فرانچائز کامیابی سے بنایا گیا ہے",
        "franchise.franchise_name": "فرانچائز کا نام",
        "franchise.update_success_msg": "فرانچائز کامیابی سے اپ ڈیٹ ہوگیا ہے",
        "franchise.update_franchise": "فرانچائز اپ ڈیٹ کریں",
        "franchise.franchise_data_not_available": "فرانچائز کی ڈیٹا دستیاب نہیں ہے",
        "franchise.select_franchise": "فرانچائز منتخب کریں",
        "franchise.franchise_details": "فرانچائز کی تفصیلات",
        "franchise.employee": "ملازم",
        "franchise.total_franchise": "کل فرانچائز",

        // franchise form
        "franchise_form.franchise_name_required": "فرانچائز کا نام ضروری ہے",
        "franchise_form.select_restaurant": "ریستوران منتخب کریں",
        "franchise_form.restaurant_required": "ریستوران ضروری ہے",
        "franchise_form.enabled": "فعال",
        "franchise_form.delivery_radius": "ڈلیوری ایریا ریڈیوس (میل)",
        "franchise_form.delivery_radius_required": "ڈیلیوری ایریا ریڈیوس ضروری ہے",
        "franchise_form.franchise_location": "فرانچائز کی جگہ",
        "franchise_form.franchise_location_required": "فرانچائز کی جگہ ضروری ہے",
        "franchise_form.order_types": "آرڈر کی اقسام",
        "franchise_form.opening_time": "کھولنے کا وقت",
        "franchise_form.closing_time": "بند کرنے کا وقت",
        "franchise_form.deliver_radius_negative": "ڈیلیوری ایریا ریڈیوس صفر سے زیادہ ہونا چاہئے",
        "franchise_form.franchise": "فرانچائز",

        // ملازم کو تفویض کریں
        "assign_employee.select_employee": "ملازم منتخب کریں",
        "assign_employee.success_msg": "ملازم کو منتخب شدہ شاخ میں کامیابی سے تفویض کر دیا گیا ہے",
        "assign_employee.employee_required": "ملازم ضروری ہے",
        "assign_employee.branch_required": "شاخ ضروری ہے",

        // آرڈر
        "order.order_list": "آرڈر کی فہرست",
        "order.order_number": "آرڈر نمبر",
        "order.customer_name": "صارف کا نام",
        "order.status": "حالت",
        "order.order_type": "آرڈر کی قسم",
        "order.payment_type": "ادائیگی کی قسم",
        "order.paid": "ادا شدہ",
        "order.create_order": "آرڈر بنائیں",
        "order.update_order": "آرڈر کی تازہ کاری کریں",
        "order.order_details": "آرڈر کی تفصیلات",
        "order.order_ifo": "آرڈر کی معلومات",
        "order.customer_ifo": "صارف کی معلومات",
        "order.invoice": "بل",
        "order.customer_id": "صارف کا شناختی نمبر",
        "order.total_price": "کل قیمت",
        "order.total_product_price": "کل مصنوعات کی قیمت",
        "order.total_add_on_price": "کل ایڈ-آن قیمت",
        "order.total_product": "کل مصنوعات",
        "order.total_add_on": "کل ایڈ-آن",
        "order.promo_discount": "پرومو ڈسکاؤنٹ",
        "order.point_amount": "پوائنٹ کی رقم",
        "order.final_price": "آخری قیمت",
        "order.not_available": "آرڈر کی معلومات دستیاب نہیں ہے",
        "order.total_order": "کل آرڈر",
        "order.order_type_required": "آرڈر کی قسم درکار ہے",
        "order.select_table": "میز منتخب کریں",
        "order.table_required": "میز ضروری ہے",
        "order.discount_type": "ڈسکاؤنٹ کی قسم",
        "order.discount": "ڈسکاؤنٹ",
        "order.comment": "تبصرہ",
        "order.comment_required": "تبصرہ ضروری ہے",
        "order.remarks": "تبصرے",
        "order.guest_numbers": "مہمانوں کی تعداد",
        "order.event_date": "واقعہ کی تاریخ",
        "order.total_payable_amount": "کل قابل ادائیگی رقم",
        "order.invoice_number": "بل نمبر",
        "order.guests": "مہمان",
        "order.generate_date": "تخلیق کی تاریخ",
        "order.generate_time": "تخلیق کا وقت",
        "order.generate_by": "کی طرف سے تخلیق",
        "order.change": "تبدیلی",
        "order.thank_order": "آپ کے آرڈر کے لئے شکریہ",
        "order.visit": "دورہ",
        "order.to_online_order": "آن لائن آرڈر کے لئے",
        "order.phone_no": "فون نمبر",

        // شاخ مصنوعات
        "branch_product.availability_msg": "شاخ مصنوعات کی دستیابیت کامیابی سے اپ ڈیٹ ہو گئی ہے",
        "branch_product.update_msg": "شاخ مصنوعات کو کامیابی سے اپ ڈیٹ کیا گیا ہے",
        "branch_product.related_product_success_msg": "متعلقہ مصنوعات کو کامیابی سے شامل کیا گیا ہے",
        "branch_product.product_has_been": "مصنوعہ کامیابی سے",
        "branch_product.successfully": "تکمیل کیا گیا ہے",
        "branch_product.enabled_small": "فعال",
        "branch_product.disabled_small": "غیر فعال",

        // پرومو
        "promo.total_promo": "کل پرومو",
        "promo.promo_code": "پرومو کوڈ",
        "promo.promo_list": "پرومو کی فہرست",
        "promo.create_promo": "پرومو بنائیں",
        "promo.update_promo": "پرومو کو اپ ڈیٹ کریں",
        "prom.create_success_msg": "پرومو کامیابی سے بنایا گیا ہے",
        "prom.update_success_msg": "پرومو کامیابی سے اپ ڈیٹ کیا گیا ہے",
        "prom.data_not_available": "پرومو کی معلومات دستیاب نہیں ہے",
        "prom.select_promo": "پرومو منتخب کریں",

        // پرومو فارم
        "promo_form.promo_code_required": "پرومو کوڈ ضروری ہے",
        "promo_form.valid_from": "موثر ہونے کی تاریخ",
        "promo_form.valid_to": "موثر ہونے کی تاریخ تک",
        "promo_form.valid_from_required": "موثر ہونے کی تاریخ ضروری ہے",
        "promo_form.valid_to_required": "Valid to درکار ہے",
        "promo_form.price_type": "قیمت کی قسم",
        "promo_form.price_type_required": "قیمت کی قسم درکار ہے",
        "promo_form.flat_amount": "سیدھی رقم",
        "promo_form.flat_amount_required": "سیدھی رقم درکار ہے",
        "promo_form.percent": "فیصد",
        "promo_form.percent_required": "فیصد درکار ہے",
        "promo_form.applicable_percent_amount": "قابل اطلاق فیصد رقم",
        "promo_form.applicable_order_amount": "قابل اطلاق آرڈر کی رقم",
        "promo_form.flat_amount_negative": "سیدھی رقم صفر سے زیادہ ہونی چاہئے",
        "promo_form.percent_negative": "فیصد صفر سے زیادہ ہونا چاہئے",
        "promo_form.percent_max_amount_negative": "فیصد زیادہ رقم مثبت نمبر ہونی چاہئے",
        "promo_form.min_order_amount_negative": "کم سے کم آرڈر کی رقم مثبت نمبر ہونی چاہئے",
        "promo_form.desc": "تفصیل",

        // آرڈر کی قسم
        "order_type.delivery": "ڈلیوری",
        "order_type.takeaway": "لے جاؤ",
        "order_type.dine_in": "میں کھائیں",
        "order_type.catering": "کیٹرنگ",

        // عالمی ترتیب
        "global_config.global_config_list": "عالمی ترتیب کی فہرست",
        "global_config.create_global_config": "عالمی ترتیب بنائیں",
        "global_config.update_global_config": "عالمی ترتیب کو اپ ڈیٹ کریں",
        "global_config.create_msg": "عالمی ترتیب کامیابی سے بنائی گئی ہے",
        "global_config.update_msg": "عالمی ترتیب کامیابی سے اپ ڈیٹ کی گئی ہے",
        "global_config.data_not_available": "عالمی ترتیب کی معلومات دستیاب نہیں ہے",
        "global_config.activate_confirm_title": "عالمی ترتیب کو فعال کریں",
        "global_config.disable_confirm_title": "عالمی ترتیب کو غیر فعال کریں",
        "global_config.activate_confirm_desc": "کیا آپ واقعی عالمی ترتیب کو فعال کرنا چاہتے ہیں؟",
        "global_config.disable_confirm_desc": "کیا آپ واقعی عالمی ترتیب کو غیر فعال کرنا چاہتے ہیں؟",
        "global_config.active_success_msg": "عالمی ترتیب کامیابی سے فعال کی گئی ہے",
        "global_config.disable_success_msg": "عالمی ترتیب کامیابی سے غیر فعال کر دی گئی ہے",

        // عالمی ترتیب فارم
        "global_config_form.order_point": "ہر آرڈر کے پوائنٹ",
        "global_config_form.value_per_point": "پوائنٹ فی اکائی قیمت",
        "global_config_form.applicable_max_point_value": "لاگو مکمل نقطہ قیمت",
        "global_config_form.branch_search_area": "شاخ تلاش علاقہ (میل)",
        "global_config_form.branch_search_area_negative": "شاخ تلاش علاقہ مثبت نمبر ہونا ضروری ہے",
        "global_config_form.branch_search_area_column": "شاخ تلاش علاقہ",
        "global_config_form.value_per_point_negative": "ہر نقطہ کی قیمت مثبت نمبر ہونا ضروری ہے",
        "global_config_form.applicable_max_point_value_negative": "لاگو مکمل نقطہ قیمت مثبت نمبر ہونا ضروری ہے",
        "global_config_form.currency": "کرنسی",
        "global_config_form.currency_required": "کرنسی درکار ہے",
        "global_config_form.select_currency": "کرنسی منتخب کریں",
        "global_config_form.order_cancel_feature": "آرڈر منسوخ کی خصوصیت",
        "global_config_form.cancel_count_per_day": "دنانہ زیادہ تر منسوخ کونٹ",
        "global_config_form.cancel_count_per_day_negative": "دنانہ زیادہ تر منسوخ کونٹ مثبت نمبر ہونا ضروری ہے",

        // بینر
        "banner.banner_list": "بینر کی فہرست",
        "banner.create_banner": "بینر بنائیں",
        "banner.update_banner": "بینر اپ ڈیٹ کریں",
        "banner.total_banner": "کل بینر",
        "banner.banner_type": "بینر کی قسم",
        "banner.banner_title": "بینر کا عنوان",
        "banner.banner_create_success": "بینر کامیابی سے بنایا گیا ہے",
        "banner.banner_update_success": "بینر کامیابی سے اپ ڈیٹ کیا گیا ہے",
        "banner.banner_data_not_available": "بینر کی معلومات دستیاب نہیں ہیں",
        "banner.sequence": "پوزیشن",

        // بینر فارم
        "banner_form.title_required": "بینر کا عنوان ضروری ہے",
        "banner_form.banner_type_required": "بینر کی قسم درکار ہے",
        "banner_form.promo_required": "پرومو درکار ہے",

        // ریستوران ڈیش بورڈ
        "restaurant_dashboard.total_earning": "کل کمائی",
        "restaurant_dashboard.total_earning_not_available": "کل کمائی کی معلومات دستیاب نہیں ہے",
        "restaurant_dashboard.top_branch": "اوپری شاخ",
        "restaurant_dashboard.earning_by_branch": "شاخ کی کمائی",
        "restaurant_dashboard.earning_by_branch_not_available": "شاخ کی کمائی کی معلومات دستیاب نہیں ہے",
        "restaurant_dashboard.total_order": "کل آرڈر",
        "restaurant_dashboard.total_order_not_available": "کل آرڈر کی معلومات دستیاب نہیں ہے",
        "restaurant_dashboard.order_by_branch": "شاخوں کے آرڈر",
        "restaurant_dashboard.order_by_branch_not_available": "شاخوں کے آرڈر کی معلومات دستیاب نہیں ہیں",
        "restaurant_dashboard.top_section_not_available": "کل خلاصہ کی معلومات دستیاب نہیں ہیں",

        // شاخ ڈیش بورڈ
        "branch_dashboard.order_summary": "آرڈر خلاصہ",
        "branch_dashboard.completed_order": "مکمل آرڈر",
        "branch_dashboard.ongoing_order": "چل رہے آرڈر",
        "branch_dashboard.cancel_order": "منسوخ آرڈر",
        "branch_dashboard.completed": "مکمل",
        "branch_dashboard.ongoing": "چل رہا ہے",
        "branch_dashboard.canceled": "منسوخ کردیا گیا",
        "branch_dashboard.order_type_data_not_avl": "آرڈر کی قسم کی معلومات دستیاب نہیں ہیں",
        "branch_dashboard.order_summary_data_not_avl": "آرڈر خلاصے کی معلومات دستیاب نہیں ہیں",
        "branch_dashboard.top_products": "اوپری مصنوعات",
        "branch_dashboard.top_product_not_avl": "اوپری مصنوعات کی معلومات دستیاب نہیں ہیں",
        "branch_dashboard.order": "آرڈر",
        "branch_dashboard.recent_order_reviews": "حال ہی ملازمت آرڈر",
        "branch_dashboard.recent_order_reviews_not_avl": "حال ہی آرڈر کی معلومات دستیاب نہیں ہیں",
        "branch_dashboard.order_id": "آرڈر شناخت",
        "branch_dashboard.reviewed_branch": "جائزہ لینے والی شاخ",
        "branch_dashboard.data_time": "تاریخ و وقت",
        "branch_dashboard.reviewed_by": "جائزہ لینے والا",
        "branch_dashboard.message": "پیغام",
        "branch_dashboard.rating": "ریٹنگ",
        "branch_dashboard.recent_product_reviews": "حال ہی مصنوعات کی تاثیرات",
        "branch_dashboard.recent_product_reviews_not_avl": "حال ہی مصنوعات کی تاثیرات کی معلومات دستیاب نہیں ہیں",
        "branch_dashboard.reviewed_product": "جائزہ لینے والی مصنوعات",
        "branch_dashboard.product_review": "مصنوعات کا جائزہ",
        "branch_dashboard.order_review": "آرڈر جائزہ",

        // تجزیہ
        "analytics.top_branches": "اوپری شاخیں",
        "analytics.top_branch_not_avl": "اوپری شاخیں کی معلومات دستیاب نہیں ہیں",

        // ٹیبل
        "table.table_list": "ٹیبل کی فہرست",
        "table.table_data_not_available": "ٹیبل کی معلومات دستیاب نہیں ہیں",
        "table.create_table": "ٹیبل بنائیں",
        "table.update_table": "ٹیبل اپ ڈیٹ کریں",
        "table.create_success_msg": "ٹیبل کامیابی سے بنایا گیا ہے",
        "table.update_success_msg": "ٹیبل کامیابی سے اپ ڈیٹ کیا گیا ہے",
        "table_form.name_required": "ٹیبل نمبر درکار ہے",
        "table_form.table_name": "ٹیبل کا نام",
        "table_form.description": "تفصیل",
        "table_form.max_person": "زیادہ سے زیادہ افراد",
        "table_form.max_person_btwn": "زیادہ سے زیادہ افراد 1 تا 100 کے درمیان ہونے چاہئے",
        "table_form.max_person_required": "زیادہ سے زیادہ افراد درکار ہیں",

        // کیٹرنگ سیکشن
        "catering_section.catering_section_list": "کیٹرنگ سیکشن کی فہرست",
        "catering_section.create_catering_section": "کیٹرنگ سیکشن بنائیں",
        "catering_section.catering_section_name": "سیکشن کا نام",
        "catering_section.catering_section_name_required": "کیٹرنگ سیکشن کا نام درکار ہے",
        "catering_section.note": "نوٹ",
        "catering_section.create_success_msg": "کیٹرنگ سیکشن کامیابی سے بنایا گیا ہے",
        "catering_section.update_success_msg": "کیٹرنگ سیکشن کامیابی سے اپ ڈیٹ کیا گیا ہے",
        "catering_section.data_not_available": "کیٹرنگ سیکشن کی معلومات دستیاب نہیں ہیں",
        "catering_section.update_catering_section": "کیٹرنگ سیکشن کو اپ ڈیٹ کریں",
        "catering_section.catering_section": "کیٹرنگ سیکشن",

        // کیٹرنگ پروڈکٹ
        "catering.request_catering_product": "کیٹرنگ پروڈکٹ کا درخواست",
        "catering.product_list": "کیٹرنگ پروڈکٹ کی فہرست",
        "catering.create_product": "کیٹرنگ پروڈکٹ بنائیں",
        "catering.update_product": "کیٹرنگ پروڈکٹ کو اپ ڈیٹ کریں",
        "catering.create_product_success_msg": "کیٹرنگ پروڈکٹ کامیابی سے بنایا گیا ہے",
        "catering.update_product_success_msg": "کیٹرنگ پروڈکٹ کامیابی سے اپ ڈیٹ کیا گیا ہے",

        // کیٹرنگ مینو
        "catering.catering_menu_list": "کیٹرنگ مینو کی فہرست",
        "catering.create_catering_menu": "کیٹرنگ مینو بنائیں",
        "catering.update_catering_menu": "کیٹرنگ مینو کو اپ ڈیٹ کریں",
        "catering.catering_menu_name": "کیٹرنگ مینو کا نام",
        "catering.catering_menu_name_required": "کیٹرنگ مینو کا نام درکار ہے",
        "catering.catering_menu_details": "کیٹرنگ مینو کی تفصیلات",
        "catering.branch_menu_assigned": "مینو شاخ کامیابی سے متعین کیا گیا ہے",
        "catering.request_catering_menu": "کیٹرنگ مینو کی درخواست",
        "catering.menu_name": "مینو کا نام",
        "catering.add_at_least_one_item": "براہ کرم کم از کم ایک آئٹم شامل کریں",
        "catering.duplicate": "ڈپلیکیٹ",
        "catering.item_already_added": "یہ آئٹم پہلے ہی شامل ہو چکی ہے",
        "catering.add_alternative": "اضافی شامل کریں",
        "catering.empty_plus": "خالی پلس",
        "catering.item_can_selected": "آئٹم منتخب کیا جا سکتا ہے",
        "catering.please_select_product": "براہ کرم پروڈکٹ منتخب کریں",
        "catering.choice_of_item": "آئٹم کا انتخاب",
        "catering.choice_of_item_required": "آئٹم کا انتخاب ضروری ہے",
        "catering.at_least_choice_of_item": "کم از کم ایک آئٹم کا انتخاب ہونا چاہئے",
        "catering.menu_data_unavailable": "کیٹرنگ مینو کی معلومات دستیاب نہیں ہیں",
        "catering.you_have_to_select": "آپ کو کم از کم منتخب کرنا ہوگا",
        "catering.because_choice": "کیونکہ آپ کا انتخاب ہے",
        "catering.catering_menu_create_msg": "کیٹرنگ مینو کامیابی سے بنایا گیا ہے",
        "catering.catering_menu_update_msg": "کیٹرنگ مینو کامیابی سے اپ ڈیٹ کیا گیا ہے",
        "catering.catering_menu_has_been": "کیٹرنگ مینو بنا دیا گیا ہے",
        "catering.the_catering_menu": "کیٹرنگ مینو",

        // کیٹرنگ آرڈر
        "catering.catering_order_list": "کیٹرنگ آرڈر کی فہرست",
        "catering.create_catering_order": "کیٹرنگ آرڈر بنائیں",
        "catering.update_catering_order": "کیٹرنگ آرڈر اپ ڈیٹ کریں",
        "catering.billed_to": "بل بھیجیں",
        "catering.invoice_no": "انوائس نمبر",
        "catering.no_of_guests": "مہمانوں کی تعداد",
        "catering.including_tax": "ٹیکس شامل",
        "catering.invoice_total": "انوائس کل",
        "catering.event_details": "واقعہ کی تفصیلات",
        "catering.signature": "دستخط",
        "catering.client_signature": "کلائنٹ کا دستخط",
        "catering.thank_you_for_coming": "ہمارے ریستوراں آنے کے لئے آپ کا شکریہ",

        // کیٹرنگ آرڈر فارم
        "catering_order_form.included": "شامل شدہ",
        "catering_order_form.additional": "اضافی",
        "catering_order_form.current_order": "موجودہ آرڈر",
        "catering_order_form.all_catering_menu": "تمام کیٹرنگ مینو",
        "catering_order_form.enter_guest": "مہمان کو درج کریں",
        "catering_order_form.select_catering_menu": "کیٹرنگ مینو منتخب کریں",
        "catering_order_form.menu_section": "مینو کا سیکشن",
        "catering_order_form.select_menu": "مینو منتخب کریں",
        "catering_order_form.confirm": "تصدیق کریں",
        "catering_order_form.alternative_items": "اضافی آئٹم",
        "catering_order_form.choose": "منتخب کریں",
        "catering_order_form.item_here": "آئٹم یہاں",
        "catering_order_form.selected_items": "منتخب شدہ آئٹمز",
        "catering_order_form.selected": "منتخب شدہ",
        "catering_order_form.select_category": "زمرہ منتخب کریں",
        "catering_order_form.search_product_name": "پروڈکٹ کا نام تلاش کریں",
        "catering_order_form.choice_of": "آئٹم کا انتخاب",
        "catering_order_form.percentage": "فی صد",
        "catering_order_form.flat": "سیدھا",
        "catering_order_form.note_optional": "نوٹ (اختیاری)",
        "catering_order_form.select_event_date": "واقعہ کی تاریخ منتخب کریں",
        "catering_order_form.event_location": "واقعہ کی جگہ",
        "catering_order_form.ny_sales_tax": "یارک سیلز ٹیکس",
        "catering_order_form.event_date_required": "واقعہ کی تاریخ درکار ہے، براہ کرم اپنی واقعہ کی تاریخ منتخب کریں",
        "catering_order_form.customer_required": "گاہک درکار ہے، براہ کرم گاہک منتخب کریں",
        "catering_order_form.least_one_menu": "براہ کرم کم از کم ایک مینو شامل کریں",
        "catering_order_form.catering_invoice": "کیٹرنگ انوائس",

        // تمام
        "product.catering_type": "کیٹرنگ",

        // اسٹاک
        "stock.stock_list": "اسٹاک کی فہرست",
        "stock.stock_small": "اسٹاک",
        "stock.create_stock": "بنائیں",
        "stock.update_stock": "اپ ڈیٹ کریں",
        "stock.total_stock": "مجموعی اسٹاک",
        "stock.stock_number": "اسٹاک نمبر",
        "stock.stock_details": "اسٹاک کی تفصیلات",
        "stock.paid_amount": "ادا کردہ رقم",
        "stock.stock_type": "اسٹاک کی قسم",
        "stock.stock_data_unavailable": "اسٹاک کی معلومات دستیاب نہیں ہیں",
        "stock.stock_create_msg": "اسٹاک کامیابی سے بنایا گیا ہے",
        "stock.stock_update_msg": "اسٹاک کامیابی سے اپ ڈیٹ کی گئی ہے",
        "stock.expired_at": "ختم ہوگیا",
        "stock.expired_date": "ختم ہونے کی تاریخ",
        "stock.available_qty": "دستیاب مقدار",

        // اسٹاک کی تفصیلات
        "stock_details.ingredient_info": "اجزاء کی معلومات",
        "stock_details.stock_info": "اسٹاک کی معلومات",
        "stock_details.agent_info": "ایجنٹ کی معلومات",
        "stock_details.balance": "بقایا",
        "stock_details.total_spent": "کھرچ کی جمع",

        // فارم میں اسٹاک میں
        "stock_in_form.one_item_required": "براہ کرم کم از کم ایک آئٹم منتخب کریں",
        "stock_in_form.mfg_date": "تیاری کی تاریخ",
        "stock_in_form.expire_date": "ختم ہونے کی تاریخ",
        "stock_in_form.select_supplier": "سپلائر منتخب کریں",
        "stock_in_form.select_ingredient": "اجزاء منتخب کریں",
        "stock_in_form.ingredient_required": "اجزاء درکار ہے",
        "stock_in_form.unit_price": "یونٹ قیمت",
        "stock_in_form.unit_price_max_zero": "یونٹ قیمت صفر یا اس سے زیادہ ہونی چاہئے",
        "stock_in_form.qty_max_one": "مقدار کم از کم ایک یا اس سے زیادہ ہونی چاہئے",
        "stock_in_form.due_amount": "باقی رقم",
        "stock_in_form.voucher_number": "واوچر نمبر",
        "stock_in_form.attachment": "منسلکہ",
        "stock_in_form.stock_date": "اسٹاک کی تاریخ",
        "stock_in_form.select_agent": "ایجنٹ منتخب کریں",
        "stock_in_form.select_date": "تاریخ منتخب کریں",
        "stock_in_form.threshold": "حد",
        "stock_in_form.threshold_max_one": "حد مثبت عدد ہونی چاہئے",

        // اسٹاک کی خلاصہ
        "stock_summary.stock_summary_list": "اسٹاک کی خلاصہ فہرست",
        "stock_summary.stock_summary_details": "اسٹاک کی خلاصہ تفصیلات",
        "stock_summary.total_stock_item": "کل اسٹاک آئٹم",
        "stock_summary.total_stock_summary": "مجموعی اسٹاک خلاصہ",
        "stock_summary.available": "دستیاب",
        "stock_summary.unavailable": "دستیاب نہیں",
        "stock_summary.select_stock_availability": "اسٹاک کی دستیابی منتخب کریں",
        "stock_summary.stock_summary_download": "اسٹاک خلاصہ ڈاؤن لوڈ کریں",

        // اسٹاک باہر
        "stock_out.stock_out_list": "اسٹاک کی بائیں فہرست",
        "stock_out.total_stock_out": "مجموعی اسٹاک باہر",
        "stock_out.stock_out_details": "اسٹاک کی تفصیلات",
        "stock.stock_out_msg": "اسٹاک کامیابی سے خارج ہوگیا ہے",
        "stock.stock_out_update_msg": "اسٹاک کامیابی سے اپ ڈیٹ کی گئی ہے",
        "stock.out_of_stock": "اسٹاک ختم ہوگیا",
        "stock.stock_out_small": "اسٹاک ختم",

        // اسٹاک باہر فارم
        "stock_out_form.select_stock": "اسٹاک منتخب کریں",
        "stock_out_form.stock_required": "اسٹاک درکار ہے",
        "stock_out_form.qty_required": "مقدار درکار ہے",
        "stock_out_form.qty_between": "مقدار 1 اور کے درمیان ہونی چاہئے",
        "stock_out_form.stock_type_required": "اسٹاک کی قسم درکار ہے",
        "stock_out_form.missing": "گمشدہ",
        "stock_out_form.out": "خارج",
        "stock_out_form.spoilage": "خرابی",
        "stock_out_form.wastage": "ضائع",

        // اسٹاک خلاصہ
        "stock_summary.create_date_range": "تخلیق شدہ تاریخ کا حد",
        "stock_summary.stock_date_range": "اسٹاک کی تاریخ کا حد",
        "stock_summary.expire_date_range": "ختم ہونے کی تاریخ کا حد",

        // شاخ ترتیب
        "branch_config.branch_config_list": "شاخ کی ترتیب فہرست",
        "branch_config.create_branch_config": "شاخ ترتیب بنائیں",
        "branch_config.update_branch_config": "شاخ ترتیب اپ ڈیٹ کریں",
        "branch_config.create_msg": "شاخ ترتیب کامیابی سے بنایا گیا ہے",
        "branch_config.update_msg": "شاخ ترتیب کامیابی سے اپ ڈیٹ کی گئی ہے",
        "branch_config.data_not_available": "شاخ ترتیب کی معلومات دستیاب نہیں ہیں",
        "branch_config.activate_confirm_title": "فعال شاخ ترتیب",
        "branch_config.disable_confirm_title": "شاخ ترتیب غیر فعال کریں",
        "branch_config.activate_confirm_desc": "کیا آپ واقعی شاخ ترتیب کو فعال کرنا چاہتے ہیں؟",
        "branch_config.disable_confirm_desc": "کیا آپ واقعی شاخ ترتیب کو غیر فعال کرنا چاہتے ہیں؟",
        "branch_config.active_success_msg": "شاخ ترتیب کامیابی سے فعال کی گئی ہے",
        "branch_config.disable_success_msg": "شاخ ترتیب کامیابی سے غیر فعال کی گئی ہے",
        "branch_config.mx_cancel_count": "زیادہ سے زیادہ منسوخ کرنے کی تعداد / دن",
        "branch_config.order_cancel_time": "آرڈر منسوخ کرنے کا وقت (منٹ)",
        "branch_config.tax_percentage": "ٹیکس فیصلہ",
        "branch_config.order_cancel_time_required": "آرڈر منسوخ کرنے کا وقت درکار ہے",
        "branch_config.order_cancel_time_least": "آرڈر منسوخ کرنے کا وقت منٹ میں کم از کم 5 ہونا چاہئے",
        "branch_config.tax_percentage_greater": "ٹیکس فیصلہ صفر سے زیادہ ہونا چاہئے",
        "branch_config.notification_sound": "رنگ ٹون آواز",
        "branch_config.notification_sound_repeat": "رنگ ٹون کی آواز دہرانا",
        "branch_config.stock_auto_reduce": "اسٹاک خود بخود کم ہو جاتا ہے",

        // ایجنٹ ادائیگی
        "agent_payment.total_agent_payment": "کل ایجنٹ ادائیگی",
        "agent_payment.agent_payment_list": "ایجنٹ ادائیگی فہرست",
        "agent_payment.create_agent_payment": "ایجنٹ ادائیگی بنائیں",
        "agent_payment.update_agent_payment": "ایجنٹ ادائیگی اپ ڈیٹ کریں",

        // ختم شدہ اسٹاک
        "expire_stock.expired_stock_list": "ختم شدہ اسٹاک کی فہرست",

        // POS بائیں مینو
        "pos_left_menu.all_items": "تمام اشیاء",

        // POS درمیانی مواد
        "pos_middle_content.its_all_msg": "یہ سب ہے، کچھ بھی نہیں",
        "pos_middle_content.out_of_stock": "اسٹاک ختم ہوگیا",
        "pos_middle_content.search_place_holder": "اسم یا شارٹ کٹ کی مدد سے آئٹم تلاش کریں",
        "pos_middle_content.dine_in": "دن میں",

        // POS کارٹ
        "pos_cart.cart": "کارٹ",
        "pos_cart.payment": "ادائیگی",
        "pos_cart.clear_cart": "کارٹ خالی کریں",
        "pos_cart.total_items": "کل اشیاء",
        "pos_cart.sub_total": "ذیلی کل",
        "pos_cart.sale_tax": "این وائی فروخت ٹیکس",
        "pos_cart.hold": "ہولڈ",
        "pos_cart.save_print_kot": "محفوظ کریں اور KOT چھاپیں",
        "pos_cart.save_print_bill": "محفوظ کریں اور بل چھاپیں",
        "pos_cart.item_name": "آئٹم کا نام",
        "pos_cart.delete": "حذف کریں",
        "pos_cart.qty": "مقدار",
        "pos_cart.table_duplicate_add_msg": "ٹیبل پہلے ہی شامل ہو چکی ہے",
        "pos_cart.table_no": "ٹیبل نمبر",
        "pos_cart.order_note": "آرڈر نوٹ",
        "pos_cart.order_instruction_place_holder": "کوئی آرڈر ہدایت مثلاً سوس، مصالحہ شامل کریں وغیرہ۔",
        "pos_cart.select_customer": "مشتری کا انتخاب کریں",
        "pos_cart.new_customer": "نیا مشتری",
        "pos_cart.no_of_guest": "مہمانوں کی تعداد",
        "pos_cart.table_number": "ٹیبل نمبر",
        "pos_cart.paid_amount_small": "ادا شدہ رقم",
        "pos_cart.tables": "ٹیبلز",
        "pos_cart.online": "آن لائن",
        "pos_cart.tips": "ٹپس",
        "pos_cart.table_already_added": "اس ٹیبل کو پہلے ہی شامل کر دیا گیا ہے",
        "pos_cart.select_delivery_address": "ترسیل کی مقام منتخب کریں",
        "pos_cart.address_title": "عنوان",
        "pos_cart.address_title_placeholder": "عنوان مثلاً دفتر",
        "pos_cart.select_branch_location": "آپ کی شاخ کی جگہ منتخب کریں",

        // POS آرڈر لسٹ
        "pos_order_list.priority_updated": "ترجیحات کی تازہ کاری",
        "pos_order_list.successfully": "کامیابی کے ساتھ",
        "pos_order_list.priority_has_been": "ترجیحات کو",
        "pos_order_list.set": "مقرر کیا گیا ہے",
        "pos_order_list.canceled_small": "منسوخ کر دیا گیا",
        "pos_order_list.order_cancel_msg": "آرڈر کامیابی سے منسوخ ہوگیا ہے",
        "pos_order_list.update_status": "حالت کو اپ ڈیٹ کریں",
        "pos_order_list.payment_status": "ادائیگی کی حیثیت",
        "pos_order_list.table_view": "ٹیبل دیکھیں",
        "pos_order_list.hold_list": "ہولڈ لسٹ",
        "pos_order_list.cancel_priority": "ترجیحات منسوخ کریں",
        "pos_order_list.set_priority": "ترجیحات مقرر کریں",
        "pos_order_list.print_kot": "KOT چھاپیں",
        "pos_order_list.print_bill": "بل چھاپیں",
        "pos_order_list.cancel_order": "آرڈر منسوخ کریں",
        "pos_order_list.edit_order": "آرڈر میں ترمیم کریں",
        "pos_order_list.order_edit_confirm_msg": "کیا آپ واقعی اس آرڈر کو ترمیم کرنا چاہتے ہیں",
        "pos_order_list.order_clear_msg": "آپ کا پچھلا آرڈر ختم ہو جائے گا",
        "pos_order_list.order_date_time": "آرڈر کی تاریخ و وقت",
        "pos_order_list.unpaid": "ادا نہیں کیا گیا",
        "pos_order_list.no_guest": "مہمانوں کی تعداد",
        "pos_order_list.order_status": "آرڈر کی حیثیت",
        "pos_order_list.customer_details": "مشتری کی تفصیلات",
        "pos_order_list.delivery_address": "ترسیل کا پتہ",
        "pos_order_list.payment_details": "ادائیگی کی تفصیلات",
        "pos_order_list.total_paid": "کل ادائیگی",
        "pos_order_list.total_due": "کل باقی",
        "pos_order_list.payment_method": "ادائیگی کا طریقہ",
        "pos_order_list.total": "کل",
        "pos_order_list.add_qty": "مقدار شامل کریں",
        "pos_order_list.add_price": "قیمت شامل کریں",
        "pos_order_list.add_on_total": "کل شامل کریں",
        "pos_order_list.item_details": "آئٹم کی تفصیلات",
        "pos_order_list.pint_discount": "پوائنٹ ڈسکاؤنٹ",
        "pos_order_list.search_order_by_id": "آرڈر آئی ڈی کے ذریعہ تلاش کریں",
        "pos_order_list.assign_delivery_partner": "ترسیلی شراکت دار کو تفویض کریں",
        "pos_order_list.assign": "تفویض کریں",
        "pos_order_list.track_order": "آرڈر کی پیگچر",

        // POS ہولڈ لسٹ
        "pos_hold_list.resume_hold_order": "ہولڈ آرڈر دوبارہ شروع کریں",
        "pos_hold_list.confirm_hold_order_resume": "کیا آپ واقعی اس ہولڈ آرڈر کو دوبارہ شروع کرنا چاہتے ہیں",
        "pos_hold_list.remove_hold_order": "ہولڈ آرڈر ہٹائیں",
        "pos_hold_list.confirm_remove_hold_order": "کیا آپ واقعی اس ہولڈ آرڈر کو ہٹانا چاہتے ہیں",

        // random user
        "user_form.please_assign_role": "براہ کرم کوئی کردار تفویض کریں",
        "user_form.assign_role": "کردار تفویض کریں",
        "user_form.search_role": "کردار تلاش کریں",
        "user_form.ok": "ٹھیک ہے",
        "user.change_password": "پاس ورڈ تبدیل کریں",
        "user.assigned_role_list": "تفویض شدہ کردار کی فہرست",
        "user.assigned_roles": "تفویض شدہ کردار",
        "user.branch_manager": "برانچ مینیجر",
        "user.waiter": "ویٹر",
        "user.chef": "شیف",
        "user.franchise_owner": "فرانچائز کا مالک",

        // analytics
        "analytics.order_type": "آرڈر کی قسم",
        "analytics.customer_type": "گاہک کی قسم",
        "analytics.total_number": "کل تعداد",
        "analytics.customer_map": "گاہک کا نقشہ",
        "analytics.no_data": "کوئی ڈیٹا نہیں",
        "analytics.new_customer": "نیا گاہک",
        "analytics.retained_customer": "محفوظ شدہ گاہک",
        "analytics.excel": "ایکسل",
        "analytics.pdf": "پی ڈی ایف",
        "analytics.customer_count": "گاہک کی تعداد",
        "analytics.restaurant_name": "ریستوران کا نام",
        "analytics.customer": "گاہک",
        "analytics.total_customer": "کل گاہک",
        "analytics.order_count": "آرڈر کی تعداد",
        "analytics.sl": "ایس ایل",
        "analytics.start_date": "شروعات کی تاریخ",
        "analytics.end_date": "انتہائی تاریخ",
        "analytics.generated_at": "تیار شدہ",
        "analytics.all_right_reserved": "تمام حقوق محفوظ ہیں",

        // cart
        "cart.save_order": "آرڈر محفوظ کریں",
        "cart.complete_order": "آرڈر مکمل کریں"
    }
}
