import React, {createContext, useState} from 'react';
import {Locales} from "../i18n";
import {LANGUAGE, STATIC_DATA} from "../helpers/Constant";
import LocalStorageUtils from "../helpers/LocalStorageUtils";

export const ThemeContext = createContext("ThemeContext");

const lang = LocalStorageUtils.getItem(LANGUAGE);
let _staticData = LocalStorageUtils.getItem(STATIC_DATA);
_staticData = _staticData ? JSON.parse(_staticData) : null;

const ThemeContextProvider = ({children}) => {

    const [screenWidth, setScreenWidth] = useState(0);
    const [locale, setLocale] = useState(lang ? lang : Locales.english);
    const [staticData, setStaticData] = useState(_staticData);

    const changeLocale = locale => {
        setLocale(Locales[locale]);
        LocalStorageUtils.setItem(LANGUAGE, Locales[locale]);

        window.location.reload();
    }

    return (
        <ThemeContext.Provider
            value={{
                screenWidth,
                setScreenWidth,
                locale,
                changeLocale,
                setStaticData,
                staticData,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
}

export default ThemeContextProvider;
