import React, {createContext, useState} from 'react';
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";
import ReportService from "../services/ReportService";


export const AnalyticsContext = createContext("AnalyticsContext");

const AnalyticsContextProvider = ({children}) => {

    const [analyticsOrderSummaryLoading, setAnalyticsOrderSummaryLoading] = useState(false);
    const [analyticsOrderSummary, setAnalyticsOrderSummary] = useState(null);
    const [orderSummaryDonut, setOrderSummaryDonut] = useState([]);

    const [analyticsOrderTypeLoading, setAnalyticsOrderTypeLoading] = useState(false);
    const [analyticsOrderType, setAnalyticsOrderType] = useState(null);
    const [orderTypeDonut, setOrderTypeDonut] = useState([]);

    const [monthlyYearlyCustomerLoading, setMonthlyYearlyCustomerLoading] = useState(false);
    const [customerHistogram, setCustomerHistogram] = useState([]);
    const [customerDonut, setCustomerDonut] = useState([]);
    const [totalCustomer, setTotalCustomer] = useState(0);

    const [restaurantTopProductLoading, setRestaurantTopProductLoading] = useState(false);
    const [restaurantTopProductList, setRestaurantTopProductList] = useState([]);

    const [restaurantTopBranchLoading, setRestaurantTopBranchLoading] = useState(false);
    const [restaurantTopBranchList, setRestaurantTopBranchList] = useState([]);

    const [restaurantProductReviewLoading, setRestaurantProductReviewLoading] = useState(false);
    const [restaurantProductReviewList, setRestaurantProductReviewList] = useState([]);

    const [restaurantOrderReviewLoading, setRestaurantOrderReviewLoading] = useState(false);
    const [restaurantOrderReviewList, setRestaurantOrderReviewList] = useState([]);

    const orderTypeName = {
        dine_in: "Dine-in",
        delivery: "Delivery",
        takeaway: "Take-away",
        catering: "Catering"
    }

    const getRestaurantOrderSummary = async params => {
        try {

            setAnalyticsOrderSummaryLoading(true);

            const res = await ReportService.getRestaurantOrderSummary(params);

            const orderSummary = {
                completed: res.data.delivered,
                ongoing: res.data.ongoing,
                cancelled: res.data.cancelled,
                total: (res.data.delivered + res.data.cancelled + res.data.ongoing)
            }

            const list = Object.entries(res.data).map(([key, value]) => {
                return {
                    type: key,
                    value
                }
            });

            setOrderSummaryDonut(list);
            setAnalyticsOrderSummary(orderSummary);

            setAnalyticsOrderSummaryLoading(false);

        } catch (error) {

            setAnalyticsOrderSummaryLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getRestaurantOrderType = async params => {
        try {

            setAnalyticsOrderTypeLoading(true);

            const res = await ReportService.getRestaurantOrderType(params);

            const orderType = {
                delivery: res.data.delivery,
                takeaway: res.data.takeaway,
                dineIn: res.data.dine_in,
                catering: res.data.catering,
                total: (res.data.delivery + res.data.takeaway + res.data.dine_in + res.data.catering)
            }

            const list = Object.entries(res.data).map(([key, value]) => {
                return {
                    type: orderTypeName[key],
                    value
                }
            });

            setOrderTypeDonut(list);
            setAnalyticsOrderType(orderType);

            setAnalyticsOrderTypeLoading(false);

        } catch (error) {

            setAnalyticsOrderTypeLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getMonthlyYearlyCustomer = async params => {
        try {

            setMonthlyYearlyCustomerLoading(true);

            const res = await ReportService.getMonthlyYearlyCustomer(params);


            let array = [];
            let totalCustomer = 0;
            let newCustomer = 0;

            Object.entries(res.data).forEach(([key, value]) => {

                totalCustomer = value.total;
                newCustomer += value.count;

                const lon = {
                    year: key,
                    type: "New",
                    value: value.count
                }

                const bor = {
                    year: key,
                    type: "Total",
                    value: value.total
                }

                array.push(lon, bor);

            });

            const customerDonut = [
                {type: "New Customer", value: newCustomer},
                {type: "Retained Customer", value: totalCustomer - newCustomer},
            ]


            setCustomerHistogram(array);
            setCustomerDonut(customerDonut);
            setTotalCustomer(totalCustomer);

            setMonthlyYearlyCustomerLoading(false);

        } catch (error) {

            setMonthlyYearlyCustomerLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getAllRestaurantTopProduct = async params => {
        try {

            setRestaurantTopProductLoading(true);

            const res = await ReportService.getRestaurantTopProducts(params);

            setRestaurantTopProductList(res.data);

            setRestaurantTopProductLoading(false);

        } catch (error) {

            setRestaurantTopProductLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getAllRestaurantTopBranch = async params => {
        try {

            setRestaurantTopBranchLoading(true);

            const res = await ReportService.getRestaurantTopBranches(params);

            setRestaurantTopBranchList(res.data);

            setRestaurantTopBranchLoading(false);

        } catch (error) {

            setRestaurantTopBranchLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getRestaurantProductReviewList = async params => {
        try {

            setRestaurantProductReviewLoading(true);

            const res = await ReportService.getRestaurantAllProductReview(params);

            setRestaurantProductReviewList(res.data);

            setRestaurantProductReviewLoading(false);

        } catch (error) {

            setRestaurantProductReviewLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getRestaurantOrderReviewList = async params => {
        try {

            setRestaurantOrderReviewLoading(true);

            const res = await ReportService.getRestaurantAllOrderReview(params);

            setRestaurantOrderReviewList(res.data);

            setRestaurantOrderReviewLoading(false);

        } catch (error) {

            setRestaurantOrderReviewLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <AnalyticsContext.Provider
            value={{
                analyticsOrderSummaryLoading,
                analyticsOrderSummary,
                orderSummaryDonut,
                getRestaurantOrderSummary,
                getRestaurantOrderType,
                analyticsOrderTypeLoading,
                analyticsOrderType,
                orderTypeDonut,
                monthlyYearlyCustomerLoading,
                getMonthlyYearlyCustomer,
                customerHistogram,
                customerDonut,
                totalCustomer,
                restaurantTopProductLoading,
                restaurantTopProductList,
                getAllRestaurantTopProduct,
                getAllRestaurantTopBranch,
                restaurantTopBranchLoading,
                restaurantTopBranchList,
                restaurantProductReviewLoading,
                restaurantProductReviewList,
                getRestaurantProductReviewList,
                restaurantOrderReviewLoading,
                restaurantOrderReviewList,
                getRestaurantOrderReviewList,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
}

export default AnalyticsContextProvider;
