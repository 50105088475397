import {RESTAURANT_PRIMARY_COLOR, RESTAURANT_SECONDARY_COLOR} from "./RestaurantConstant";

export const ACCESS_TOKEN = 'rms-portal-token';
export const REFRESH_TOKEN = 'rms-portal-refresh-token';
export const PROFILE = 'rms-portal-profile';
export const RESTAURANT = 'rms-restaurant';
export const POS_ORDER_CART = "rms-pos-order-cart";
export const POS_ORDER_CART_INVOICE = "rms-pos-order-cart-invoice";
export const POS_ORDER_INFO = "rms-pos-order-info";
export const POS_ORDER_HOLD_LIST = "rms-pos-order-hold-list"
export const LANGUAGE = 'rms-lang';
export const BRANCH = "BRANCH";
export const GLOBAL_CONFIG = "GLOBAL_CONFIG";
export const BRANCH_CONFIG = "RMS_BRANCH_CONFIG";
export const USER_TYPE = "user-type"
export const ProductTypes = [
    {name: "SINGLE", i18Name: "product.single_type"},
    {name: "COMBO", i18Name: "product.combo_type"},
    // {name: "CATERING", i18Name: "product.catering_type"},
]

export const PRODUCT_TYPES_TRANSLATE = {
    "SINGLE": "product.single_type",
    "COMBO": "product.combo_type",
    "CATERING": "product.catering_type",
}

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_YEAR_FORMAT = "YYYY";
export const DATE_MONTH_FORMAT = "MM";
export const TIME_FORMAT = "hh:mm:ss A";
export const TIME_FORMAT_TWO = "hh:mm A";
export const TIME_FORMAT_THREE = "hh:mm a";
export const TIME_FORMAT_HOUR = "HH";
export const TIME_FORMAT_MIN = "mm";

// language
export const ENGLISH_LANG = "en-us";

export const LANG_LIST = {
    "en-us": "English",
    "bn-bd": "Bengali",
    "ur-pk": "Urdu",
    "hi-in": "Hindi",
    "ps-af": "Pashto",
}

export const LANG_OBJ_TRANSLATE = {
    "English": "nav_header.english",
    "Bengali": "nav_header.bengali",
    "Urdu": "nav_header.urdu",
    "Hindi": "nav_header.hindi",
    "Pashto": "nav_header.pashto",
}

// color
export const PRIMARY_COLOR = RESTAURANT_PRIMARY_COLOR;
export const BLUE_COLOR = "#4796FF";
export const SECONDARY_COLOR = RESTAURANT_SECONDARY_COLOR;
export const GRAY_COLOR = "#B5B5B5";

export const PRODUCT_FOLDER = "PRODUCT";
export const CATERING_MENU_FOLDER = "CATERING";
export const ADD_ONS_FOLDER = "ADD_ON";
export const CATEGORY_FOLDER = "CATEGORY";

export const INGREDIENT_CATEGORY_FOLDER = "INGREDIENT_CATEGORY";
export const INGREDIENT_FOLDER = "INGREDIENT";
export const BANNER_FOLDER = "BANNER";
export const BRANCH_FOLDER = "BRANCH";

export const GOOGLE_MAP_API_KEY = "AIzaSyAEt4fldofQ7rUz8z4FgtzmSRjFPuZMbrY";

export const ORDER_TYPES = {
    "DELIVERY": "order_type.delivery",
    "TAKEAWAY": "order_type.takeaway",
    "DINE_IN": "order_type.dine_in",
}

export const BRANCH_FORM_ORDER_TYPES = {
    "DELIVERY": "order_type.delivery",
    "TAKEAWAY": "order_type.takeaway",
    "DINE_IN": "order_type.dine_in",
    "CATERING": "order_type.catering"
}

export const CATERING = "CATERING";
export const DINE_IN = "DINE_IN";
export const TAKEAWAY = "TAKEAWAY";
export const DELIVERY = "DELIVERY";

export const DELIVERY_ORDER_STATUS_SEQUENCE = {
    ORDERED: "ORDER_CONFIRMED",
    ORDER_CONFIRMED: "PREPARING",
    PREPARING: "READY_FOR_DELIVERY",
    READY_FOR_DELIVERY: "ORDER_PICKED_UP",
    ORDER_PICKED_UP: "ORDER_ON_THE_WAY",
    ORDER_ON_THE_WAY: "DELIVERED",
}

export const DINE_IN_ORDER_STATUS_SEQUENCE = {
    ORDERED: "PREPARING",
    PREPARING: "READY_TO_SERVE",
    READY_TO_SERVE: "ORDER_SERVED",
    ORDER_SERVED: "DELIVERED"
}

export const TAKE_AWAY_ORDER_STATUS_SEQUENCE = {
    ORDERED: "ORDER_CONFIRMED",
    ORDER_CONFIRMED: "PREPARING",
    PREPARING: "READY_FOR_PICKUP",
    READY_FOR_PICKUP: "DELIVERED"
}

export const ORDER_BTN_STATE_NAME = {
    ORDER_CONFIRMED: "ORDER_CONFIRMED",
    ORDER_PICKED_UP: "ORDER_PICKED_UP",
    ORDER_SERVED: "ORDER_SERVED",
    ORDERED: "ORDERED",
    PREPARING: "PREPARING",
    READY_FOR_DELIVERY: "READY_FOR_DELIVERY",
    ORDER_ON_THE_WAY: "ORDER_ON_THE_WAY",
    DELIVERED: "ORDER_COMPLETED",
    READY_TO_SERVE: "READY_TO_SERVE",
    CANCELLED: "CANCELLED",
    READY_FOR_PICKUP: "READY_FOR_PICKUP"
}

export const ORDER_STATUES_SEQUENCE = {
    ORDERED: "ORDERED",
    ORDER_CONFIRMED: "ORDER_CONFIRMED",
    PREPARING: "PREPARING",
    READY_FOR_DELIVERY: "READY_FOR_DELIVERY",
    READY_TO_SERVE: "READY_TO_SERVE",
    ORDER_SERVED: "ORDER_SERVED",
    READY_FOR_PICKUP: "READY_FOR_PICKUP",
    ORDER_PICKED_UP: "ORDER_PICKED_UP",
    ORDER_ON_THE_WAY: "ORDER_ON_THE_WAY",
    DELIVERED: "DELIVERED",
    CANCELLED: "CANCELLED"
};

export const ORDER_UPDATE_PERMISSIONS_DINE_IN = {
    CHEF: "PREPARING, READY_TO_SERVE",
    WAITER: "DELIVERED, ORDER_SERVED",
    BRANCH_MANAGER: "PREPARING, READY_TO_SERVE, DELIVERED, ORDER_SERVED",
    FRANCHISE_OWNER: "PREPARING, READY_TO_SERVE, DELIVERED, ORDER_SERVED",
}

export const ORDER_UPDATE_PERMISSIONS = {
    BRANCH_MANAGER: "ORDER_CONFIRMED, ORDER_PICKED_UP, ORDER_ON_THE_WAY, DELIVERED, READY_FOR_PICKUP, ORDER_SERVED, PREPARING, READY_FOR_DELIVERY",
    FRANCHISE_OWNER: "ORDER_CONFIRMED, ORDER_PICKED_UP, ORDER_ON_THE_WAY, DELIVERED, READY_FOR_PICKUP, ORDER_SERVED, PREPARING, READY_FOR_DELIVERY",
    CHEF: "PREPARING, READY_FOR_DELIVERY",
    WAITER: "ORDER_CONFIRMED, ORDER_PICKED_UP, ORDER_ON_THE_WAY, DELIVERED, READY_FOR_PICKUP, ORDER_SERVED"
}

export const USER_TYPE_CHEF = "CHEF";
export const USER_TYPE_WAITER = "WAITER";
export const USER_TYPE_BRANCH_MANAGER = "BRANCH_MANAGER";
export const USER_TYPE_OWNER = "OWNER";

export const CURRENCIES = {
    USD: "$",
    BDT: "৳",
    PKR: "Rs.",
    INR: "₹",
    AFN: "؋"
}

export const ORDERED = "ORDERED";
export const DELIVERED = "DELIVERED";
export const CANCELLED = "CANCELLED";

export const ORDER_STATUS_COLOR = {
    ORDERED: "#169BD7",
    ORDER_CONFIRMED: "#99CC00",
    PREPARING: "#F50081",
    READY_FOR_DELIVERY: "#FFCC00",
    ORDER_PICKED_UP: "#0080FF",
    ON_THE_WAY: "#BF00FF",
    READY_TO_SERVE: "#FF8000",
    DELIVERED: "#009A00",
    CANCELLED: "#EB001B",
}

export const ONLINE_PAYMENT = "ONLINE_PAYMENT";
export const CASH_ON_DELIVERY = "CASH_ON_DELIVERY";

export const ROLE_LEVEL = {
    ALL: "role_form.all",
    SYSTEM: "role_form.system",
    RESTAURANT: "role_form.restaurant",
    BRANCH: "role_form.branch",
    CUSTOMER: "role_form.customer",
}

export const PAYMENT_TYPES_ICON = {
    "CASH": "/assets/icons/cash_icon.png",
    "ONLINE": "/assets/icons/card_icon.png",
    "MFS": "/assets/icons/mobile_icon.png"
}


export const POS_CONTENT_BGC_COLOR = '#ffd542'
export const GREEN = "#15CA73"
export const RED = "#D9333F"
export const POS_BGC_COLOR = "#00000008"

export const POS_ORDER_FILTER_MAP = {
    dineIn: {orderTypes: "DINE_IN"},
    delivery: {orderTypes: "DELIVERY"},
    catering: {orderTypes: "CATERING"},
    takeaway: {orderTypes: "TAKEAWAY"},
    all: "",
    online: {online: true, orderStatus: "ORDERED"},
    completed: {orderStatus: "DELIVERED"},
    cancelled: {orderStatus: "CANCELLED", ongoing: false},
    unpaid: {paid: false}
}

export const PRECISION_FACTOR = {
    TWO: 100,
    THREE: 1000
}

export const PRECISION_FACTOR_VALUE = {
    TWO: 2,
    THREE: 3
}

export const DOORDASH_ORDER_STATE = {
    initiated: "created",
    created: "confirmed",
    confirmed: "enroute_to_pickup",
    enroute_to_pickup: "arrived_at_pickup",
    arrived_at_pickup: "picked_up",
    picked_up: "enroute_to_drop_off",
    enroute_to_dropoff: "arrived_at_drop_off",
    arrived_at_dropoff: "delivered"
}

export const DAYS_OF_WEEK = {
    ALL: "ALL",
    SATURDAY: "SATURDAY",
    SUNDAY: "SUNDAY",
    MONDAY: "MONDAY",
    TUESDAY: "TUESDAY",
    WEDNESDAY: "WEDNESDAY",
    THURSDAY: "THURSDAY",
    FRIDAY: "FRIDAY",
}

export const DAYS_OF_WEEK_NAME = {
    6: "SATURDAY",
    0: "SUNDAY",
    1: "MONDAY",
    2: "TUESDAY",
    3: "WEDNESDAY",
    4: "THURSDAY",
    5: "FRIDAY",
}

export const QR_CODE_DATA = "qr-code-data";
export const QR_CODE_LAST_ORDER_ID = "qr-code-last-order";
export const QR_CODE_ORDER_CART = "qr-code-order-cart";
export const QR_CODE_ORDER_INVOICE = "qr-code-order-invoice";
export const RESTAURANT_TOKEN = "restaurant-token";
export const STATIC_DATA = "static-data";

/*
* 64: Nav Header Height
* 57: Page Header Height
* 127: Search Card Single Line Header Height / 82: Search Card Single Line Header Height
* 63: Table Header Height
* 40: Table Pagination Height
* 106: Margin + Padding
* */

export const TOTAL_CONTENT_HEIGHT = 64 + 57 + 127 + 63 + 40 + 106;
export const TOTAL_CONTENT_HEIGHT_TWO = 64 + 57 + 82 + 63 + 40 + 106;

export const EIGHTY_MM_PAPER = "EIGHTY_MM";
export const FIFTY_EIGHT_MM_PAPER = "FIFTY_EIGHT_MM";

export const BILLING_TYPE = {
    subscription: "SUBSCRIPTION"
}