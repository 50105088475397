import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import ProductService from "../../services/product/ProductService";

export const ProductContext = createContext("ProductContext");

const ProductContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [productList, setProductList] = useState([]);

    const [requestedProductList, setRequestedProductList] = useState([]);

    const getAllProduct = async params => {
        try {

            setLoading(true);

            const res = await ProductService.getAllProduct(params);
            setProductList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getAllRequestedProduct = async params => {
        try {

            setLoading(true);

            const res = await ProductService.getAllRequestedProduct(params);
            setRequestedProductList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }


    }

    return (
        <ProductContext.Provider
            value={{
                loading,
                totalElements,
                productList,
                requestedProductList,
                getAllProduct,
                getAllRequestedProduct,
            }}
        >
            {children}
        </ProductContext.Provider>
    );
}

export default ProductContextProvider;
