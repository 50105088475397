import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";

export default class CateringMenuService {

    static createCateringMenu = data => privateAPI.post(URL.CREATE_CATERING_MENU_URL, data);
    static getAllCateringMenu = params => privateAPI.get(URL.GET_ALL_CATERING_MENU_URL, {params});
    static getAllRequestedCateringMenu = params => privateAPI.get(URL.GET_ALL_REQUESTED_CATERING_MENU_URL, {params});
    static getCateringMenuById = id => privateAPI.get(`${URL.GET_CATERING_MENU_BY_ID_URL}/${id}`);
    static updateCateringMenu = (data, id) => privateAPI.put(
        `${URL.UPDATE_CATERING_MENU_URL}/${id}`, data
    );

}
