import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import BranchCateringMenuService from "../../services/branch_catering/BranchCateringMenuService";
export const BranchCateringMenuContext = createContext("BranchCateringMenuContext");

const BranchCateringMenuContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [branchCateringMenuList, setBranchCateringMenuList] = useState([]);

    const getAllBranchCateringMenu = async params => {
        try {

            setLoading(true);

            const res = await BranchCateringMenuService.getAllBranchCateringMenu(params);
            setBranchCateringMenuList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchCateringMenuContext.Provider
            value={{
                loading,
                totalElements,
                branchCateringMenuList,
                getAllBranchCateringMenu,
            }}
        >
            {children}
        </BranchCateringMenuContext.Provider>
    );
}

export default BranchCateringMenuContextProvider;
