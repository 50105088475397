import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import ProductService from "../../services/product/ProductService";

export const CateringProductContext = createContext("CateringProductContext");

const CateringProductContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [cateringProductList, setCateringProductList] = useState([]);

    const [requestedLoading, setRequestedLoading] = useState(false);
    const [requestedTotalElements, setRequestedTotalElements] = useState(0);
    const [requestedCateringProductList, setRequestedCateringProductList] = useState([]);

    const getAllCateringProduct = async params => {
        try {

            setLoading(true);

            const res = await ProductService.getAllProduct(params);
            setCateringProductList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllRequestedCateringProduct = async params => {
        try {

            setRequestedLoading(true);

            const res = await ProductService.getAllRequestedProduct(params);
            setRequestedCateringProductList(res.data.content);
            setRequestedTotalElements(res.data.totalElements);

            setRequestedLoading(false);

        } catch (error) {

            setRequestedLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <CateringProductContext.Provider
            value={{
                loading,
                totalElements,
                cateringProductList,
                getAllCateringProduct,
                requestedLoading,
                requestedTotalElements,
                requestedCateringProductList,
                getAllRequestedCateringProduct,
            }}
        >
            {children}
        </CateringProductContext.Provider>
    );
}

export default CateringProductContextProvider;
