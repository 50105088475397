import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import {getBranchId} from "../helpers/Utils";

export default class TableService {

    static createTable = data => privateAPI.post(
        `${URL.CREATE_TABLE}/${getBranchId()}`, data
    );

    static getAllTable = params => privateAPI.get(
        `${URL.GET_ALL_TABLE}/${getBranchId()}`, {params}
    );

    static getAllTableOrderStatisics = params => privateAPI.get(
        `${URL.GET_ALL_TABLE_ORDER_STATESTICS}/${getBranchId()}`, {params}
    );

    static getTableById = tableId => privateAPI.get(
        `${URL.GET_TABLE_BY_ID}/${tableId}/${getBranchId()}`
    );

    static updateTable = (tableId, data) => privateAPI.put(
        `${URL.UPDATE_TABLE}/${tableId}/${getBranchId()}`, data
    );

    static getOrderListByTableId = tableId => privateAPI.get(
        `${URL.GET_ORDER_LIST_BY_TABLE_ID}/${tableId}/${getBranchId()}`
    );

}
