import React, {createContext, useState} from 'react';
import ReportService from "../services/ReportService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";


export const ReportContext = createContext("ReportContext");

const ReportContextProvider = ({children}) => {

    const [ownerReportLoading, setOwnerReportLoading] = useState(false);
    const [ownerReports, setOwnerReports] = useState([]);

    const [restaurantEarningLineChartLoading, setRestaurantEarningLineChartLoading] = useState(false);
    const [restaurantEarningLineChartList, setRestaurantEarningLineChartList] = useState([]);
    const [totalRestaurantEarningLineChart, setTotalRestaurantEarningLineChart] = useState(0);

    const [restaurantEarningHistogramLoading, setRestaurantEarningHistogramLoading] = useState(false);
    const [restaurantEarningHistogramList, setRestaurantEarningHistogramList] = useState([]);
    const [totalEarningHistogram, setTotalEarningHistogram] = useState(0);

    const [restaurantOrderHistogramLoading, setRestaurantOrderHistogramLoading] = useState(false);
    const [restaurantOrderHistogramList, setRestaurantOrderHistogramList] = useState([]);
    const [totalRestaurantOrderHistogram, setTotalRestaurantOrderHistogram] = useState(0);

    const [restaurantOrderLineChartLoading, setRestaurantOrderLineChartLoading] = useState(false);
    const [restaurantOrderLineChartList, setRestaurantOrderLineChartList] = useState([]);
    const [totalRestaurantOrderLineChart, setTotalRestaurantOrderLineChart] = useState(0);

    const getAllOwnerReport = async params => {
        try {

            setOwnerReportLoading(true);

            const res = await ReportService.getAllOwnerReport(params);
            setOwnerReports(res.data);

            setOwnerReportLoading(false);

        } catch (error) {

            setOwnerReportLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getBranchEarningGraphData = async params => {
        try {

            setRestaurantEarningLineChartLoading(true);

            const res = await ReportService.getBranchEarningGraphData(params);

            const map = Object.entries(res.data.data).map(([key, value]) => {
                return {
                    date: key,
                    earning: value
                }
            });

            setRestaurantEarningLineChartList(map);
            setTotalRestaurantEarningLineChart(res.data.total);

            setRestaurantEarningLineChartLoading(false);

        } catch (error) {

            setRestaurantEarningLineChartLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getBranchEarningBarchartData = async params => {
        try {

            setRestaurantEarningHistogramLoading(true);

            const res = await ReportService.getBranchEarningBarchartData(params);
            setRestaurantEarningHistogramList(res.data.data);
            setTotalEarningHistogram(res.data.total);

            setRestaurantEarningHistogramLoading(false);

        } catch (error) {

            setRestaurantEarningHistogramLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getRestaurantOrderBarchart = async params => {
        try {

            setRestaurantOrderHistogramLoading(true);

            const res = await ReportService.getRestaurantOrderBarchart(params);

            setRestaurantOrderHistogramList(res.data.data);
            setTotalRestaurantOrderHistogram(res.data.total);

            setRestaurantOrderHistogramLoading(false);

        } catch (error) {

            setRestaurantOrderHistogramLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getRestaurantOrderLineChart = async params => {
        try {

            setRestaurantOrderLineChartLoading(true);

            const res = await ReportService.getRestaurantOrderLineChart(params);

            const map = Object.entries(res.data.data).map(([key, value]) => {
                return {
                    date: key,
                    order: value
                }
            });

            setRestaurantOrderLineChartList(map);
            setTotalRestaurantOrderLineChart(res.data.total);

            setRestaurantOrderLineChartLoading(false);

        } catch (error) {

            setRestaurantOrderLineChartLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <ReportContext.Provider
            value={{
                ownerReportLoading,
                restaurantEarningLineChartLoading,
                restaurantEarningHistogramLoading,
                ownerReports,
                totalRestaurantEarningLineChart,
                restaurantEarningLineChartList,
                restaurantEarningHistogramList,
                totalEarningHistogram,
                restaurantOrderHistogramLoading,
                restaurantOrderHistogramList,
                restaurantOrderLineChartLoading,
                restaurantOrderLineChartList,
                totalRestaurantOrderHistogram,
                totalRestaurantOrderLineChart,
                getAllOwnerReport,
                getBranchEarningGraphData,
                getBranchEarningBarchartData,
                getRestaurantOrderBarchart,
                getRestaurantOrderLineChart,
            }}
        >
            {children}
        </ReportContext.Provider>
    );
}

export default ReportContextProvider;
