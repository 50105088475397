import React, {createContext, useState} from 'react';


export const CurrentLocationContext = createContext("AuthContext");

const CurrentLocationProvider = ({children}) => {

    const [currentLocation, setCurrentLocation] = useState("undefined");

    return (
        <CurrentLocationContext.Provider
            value={{
                currentLocation,
                setCurrentLocation,
            }}
        >
            {children}
        </CurrentLocationContext.Provider>
    );
}

export default CurrentLocationProvider;
