import * as URL from "../configs/APIUrl";
import publicAPI from "../rest_handlers/publicAPI";
import privateAPI from "../rest_handlers/privateAPI";

export default class BillingService {

    static createBkashPayment = data => publicAPI.post(
        `${URL.CREATE_BKASH_PAYMENT_URL}`, data
    );

    static getAllValidateCoupon = params => publicAPI.get(
        `${URL.GET_VALIDATE_COUPON_URL}`, {params}
    );

    static getAllSystemConfig = params => publicAPI.get(
        `${URL.GET_ALL_SYSTEM_CONFIGS}`, {params}
    );


    static getAllTransactions = params => privateAPI.get(
        `${URL.GET_ALL_TRANSACTIONS_URL}`, {params}
    );

}
