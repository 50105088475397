import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class AgentService {

    static createAgent = data => privateAPI.post(
        `${URL.POST_AGENT}/${getBranchId()}`, data
    );

    static getAllAgent = params => privateAPI.get(
        `${URL.GET_AGENT_LIST}/${getBranchId()}`, {params}
    );

    static getAgentById = categoryId => privateAPI.get(
        `${URL.GET_AGENT_BY_ID}/${categoryId}/${getBranchId()}`
    );

    static updateAgent = (categoryId, data) => privateAPI.put(
        `${URL.UPDATE_AGENT}/${categoryId}/${getBranchId()}`, data
    );

    static getStockTransactionReportById = agentId => privateAPI.get(
        `${URL.STOCK_TRANSACTION_REPORT_BY_ID}/${getBranchId()}/${agentId}`
    );
}
