import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import UserService from "../services/UserService";
import {Toast} from "../components/common/Toast";


export const FranchiseUserContext = createContext("FranchiseUserContext");

const FranchiseUserContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [franchiseUserList, setFranchiseUserList] = useState([]);

    const getAllFranchiseUser = async (params) => {
        try {

            setLoading(true);

            const res = await UserService.getAllUser(params);
            setFranchiseUserList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <FranchiseUserContext.Provider
            value={{
                loading,
                totalElements,
                franchiseUserList,
                getAllFranchiseUser,
            }}
        >
            {children}
        </FranchiseUserContext.Provider>
    );
}

export default FranchiseUserContextProvider;
