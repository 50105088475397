import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class UserService {

    static createUser = (data) => privateAPI.post(
        `${URL.CREATE_USER}`, data
    );

    static getUserProfile = () => privateAPI.get(URL.GET_USER_PROFILE);

    static getAllUser = (params) => privateAPI.get(
        `${URL.GET_ALL_USER}`, {params}
    );

    static getUserById = userId => privateAPI.get(
        `${URL.GET_USER_BY_ID}/${userId}`
    );

    static updateUser = (userId, data) => privateAPI.put(
        `${URL.UPDATE_USER}/${userId}`, data
    );

    static updateProfile = data => privateAPI.put(
        URL.UPDATE_USER_PROFILE, data
    );

    static changeUserPassword = (id, data) => privateAPI.put(`${URL.CHANGE_USER_PASSWORD}/${id}`, data);

}
