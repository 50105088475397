import {en_US} from "./en-US";
import {bn_BD} from "./bn-BD";
import {ur_PK} from "./ur-PK";
import {hi_IN} from "./hi-IN";
import {af_PS} from "./af-PS";

const index = {
    ...en_US,
    ...bn_BD,
    ...ur_PK,
    ...hi_IN,
    ...af_PS,
}

export default index;
