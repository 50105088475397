import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {ACCESS_TOKEN} from '../helpers/Constant';
import {SOCKET_RECONNECT_TIME_SEC, SOCKET_URL} from "./SocketConstants";
import LocalStorageUtils from "../helpers/LocalStorageUtils";

let stompClient = null;
// const accessToken = LocalStorageUtils.getItem(ACCESS_TOKEN);

export const connectWebSocket = (subscriptionLevel, subscribeDestination, onMessageReceived) => {

    const accessToken = LocalStorageUtils.getItem(ACCESS_TOKEN);

    // disconnectWebSocket();
    console.log('Trying to connectWebSocket');

    const socket = new SockJS(SOCKET_URL);
    stompClient = Stomp.over(socket);

    const connectCallback = (e) => {

        console.log('WebSocket connected', e.command);

        // Subscribe to the necessary endpoints
        if (e.command === "CONNECTED") {
            try {
                subscribeToWebSocket(subscriptionLevel, subscribeDestination, onMessageReceived);
            } catch (e) {
                console.error(e.message)
            }
        }
    };

    const errorCallback = (error) => {
        console.error('WebSocket error:', error);
        // Try to reconnect after a delay
        setTimeout(() =>
            connectWebSocket(
                subscriptionLevel,
                subscribeDestination,
                onMessageReceived
            ), SOCKET_RECONNECT_TIME_SEC
        );
    };

    stompClient.connect(
        {Authorization: 'Bearer ' + accessToken},
        connectCallback,
        errorCallback
    );
}

const subscribeToWebSocket = (subscriptionLevel, subscribeDestination, onMessageReceived) => {

    const accessToken = LocalStorageUtils.getItem(ACCESS_TOKEN);

    // Subscribe to WebSocket endpoints
    setTimeout(() => {
        stompClient.subscribe(subscribeDestination, (message) => {
            // Handle received messages
            const body = JSON.parse(JSON.parse(message.body));
            if (onMessageReceived) {
                onMessageReceived(body);
            }
        }, {
            Authorization: 'Bearer ' + accessToken,
            "Subscription-Level": subscriptionLevel
        });
    }, 200)
}

export const disconnectWebSocket = () => {
    try {
        if (stompClient !== null) {
            stompClient.disconnect();
            console.log('WebSocket disconnected');
        }
    } catch (e) {
        console.log(e)
    }
}
