import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import BranchRequestAddOnsService from "../../services/branch_product/BranchRequestAddOnsService";
export const BranchRequestAddOnsContext = createContext("BranchRequestAddOnsContext");

const BranchRequestAddOnsContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [branchRequestAddOnsList, setBranchRequestAddOnsList] = useState([]);
    const [branchAddOnsIds, setBranchAddOnsIds] = useState([]);

    const getAllBranchRequestAddOns = async params => {
        try {

            setLoading(true);

            const res = await BranchRequestAddOnsService.getAllBranchRequestAddOns(params);
            setBranchAddOnsIds(res.data.branchAddOnIds);
            setBranchRequestAddOnsList(res.data.globalAddOns.content);
            setTotalElements(res.data.globalAddOns.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchRequestAddOnsContext.Provider
            value={{
                loading,
                totalElements,
                branchRequestAddOnsList,
                branchAddOnsIds,
                getAllBranchRequestAddOns,
            }}
        >
            {children}
        </BranchRequestAddOnsContext.Provider>
    );
}

export default BranchRequestAddOnsContextProvider;
