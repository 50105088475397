import LoadingSuspense from "../common/LoadingSuspense";
import useRestaurantTokenInitializer from "../../hook/useRestaurantTokenInitializer";

const withRestaurantTokenInitializer = Component => {

    return props => {

        const {loading} = useRestaurantTokenInitializer();

        if (loading) {
            return <LoadingSuspense height="100vh" width="100vw"/>;
        }

        return <Component {...props} />;
    };
};

export default withRestaurantTokenInitializer;