import React, {createContext, useContext, useState} from 'react';
import {ThemeContext} from "./ThemeContextProvider";
import ReactDOMServer from "react-dom/server";
import {I18nProvider} from "../i18n";
import PrintService from "../services/PrintService";


export const PrintContext = createContext("PrintContext");

const PrintContextProvider = ({children}) => {

    const {locale} = useContext(ThemeContext);
    const [isDirectPrint, setIsDirectPrint] = useState(false);

    const createPrint = async params => {
        const content = ReactDOMServer.renderToStaticMarkup(
            <I18nProvider locale={locale}>
                {params}
            </I18nProvider>
        );

        try {
            await PrintService.createPrint(content);

            return true;

        } catch (error) {
            // const message = getErrorMessage(error);
            // Toast("error", "Error", message);

        }
    }


    const printContent = (contentComponent) => {


        const content = ReactDOMServer.renderToStaticMarkup(
            <I18nProvider locale={locale}>
                {contentComponent}
            </I18nProvider>
        );

        // console.log("content ", content)

        fetch('http://localhost:3300/print', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: content
        })
            .then(response => response.json())
            .then(data => {
                console.log('Print Successful:', data);
                setIsDirectPrint(true);
                return true;
            })
            .catch(error => {
                console.error('Print Error:', error);
                setIsDirectPrint(false);

                return false;
            });
    };


    return (
        <PrintContext.Provider
            value={{
                isDirectPrint,
                createPrint,
                printContent
            }}
        >
            {children}
        </PrintContext.Provider>
    );
}

export default PrintContextProvider;
