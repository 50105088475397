import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchCateringMenuService {

    static getAllBranchCateringMenu = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH_CATERING_MENU_URL}/${getBranchId()}`,
        {params}
    );
    static getBranchCateringMenuById = id => privateAPI.get(
        `${URL.GET_BRANCH_CATERING_MENU_BY_ID_URL}/${id}/${getBranchId()}`
    );
    static updateBranchCateringMenu = (data, id) => privateAPI.put(
        `${URL.UPDATE_BRANCH_CATERING_MENU_URL}/${id}/${getBranchId()}`, data
    );

}
