import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class PromoService {

    static createPromo = data => privateAPI.post(
        `${URL.CREATE_PROMO}`, data
    );

    static getAllPromo = params => privateAPI.get(
        `${URL.GET_ALL_PROMO}`, {params}
    );

    static getPromoById = promoId => privateAPI.get(
        `${URL.GET_PROMO_BY_ID}/${promoId}`
    );

    static updatePromo = (promoId, data) => privateAPI.put(
        `${URL.UPDATE_PROMO}/${promoId}`, data
    );

}
