import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import CateringMenuService from "../../services/catering/CateringMenuService";

export const CateringMenuContext = createContext("CateringMenuContext");

const CateringMenuContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [cateringMenuList, setCateringMenuList] = useState([]);

    const [requestedLoading, setRequestedLoading] = useState(false);
    const [requestedTotalElements, setRequestedTotalElements] = useState(0);
    const [requestedCateringMenuList, setRequestedCateringMenuList] = useState([]);

    const getAllCateringMenu = async params => {
        try {

            setLoading(true);

            const res = await CateringMenuService.getAllCateringMenu(params);
            setCateringMenuList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllRequestedCateringMenu = async params => {
        try {

            setRequestedLoading(true);

            const res = await CateringMenuService.getAllRequestedCateringMenu(params);
            setRequestedCateringMenuList(res.data.content);
            setRequestedTotalElements(res.data.totalElements);

            setRequestedLoading(false);

        } catch (error) {

            setRequestedLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <CateringMenuContext.Provider
            value={{
                loading,
                totalElements,
                cateringMenuList,
                getAllCateringMenu,
                requestedLoading,
                requestedTotalElements,
                requestedCateringMenuList,
                getAllRequestedCateringMenu,
            }}
        >
            {children}
        </CateringMenuContext.Provider>
    );
}

export default CateringMenuContextProvider;
