import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";

export default class CateringSectionService {

    static getAllCateringSection = params => privateAPI.get(
        `${URL.GET_ALL_CATERING_SECTION}`, {params}
    );

    static createCateringSection = data => privateAPI.post(
        `${URL.CREATE_CATERING_SECTION}`, data
    );

    static getCateringSectionById = id => privateAPI.get(
        `${URL.GET_CATERING_SECTION_BY_ID}/${id}`
    );

    static updateCateringSection = (id, data) => privateAPI.put(
        `${URL.UPDATE_CATERING_SECTION}/${id}`, data
    );

}
