import React, {createContext, useState} from 'react';
import BranchConfigService from "../services/BranchConfigService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";


export const BranchConfigContext = createContext("BranchConfigContext");

const BranchConfigContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [branchConfigList, setBranchConfigList] = useState([]);

    const getAllBranchConfig = async params => {
        try {

            setLoading(true);

            const res = await BranchConfigService.getAllBranchConfig(params);
            setBranchConfigList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchConfigContext.Provider
            value={{
                loading,
                totalElements,
                branchConfigList,
                getAllBranchConfig,
            }}
        >
            {children}
        </BranchConfigContext.Provider>
    );
}

export default BranchConfigContextProvider;
