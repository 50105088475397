import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class RoleService {

    static createRole = data => privateAPI.post(URL.CREATE_ROLE, data);

    static getAllRole = params => privateAPI.get(URL.GET_ALL_ROLE, {params});

    static getAllPermission = params => privateAPI.get(URL.GET_ALL_PERMISSION, {params});

    static getRoleById = roleId => privateAPI.get(`${URL.GET_ROLE_BY_ID}/${roleId}`);

    static updateRole = (roleId, data) => privateAPI.put(`${URL.UPDATE_ROLE}/${roleId}`, data);

    static getRolesByRestaurant = (roleId, params) => privateAPI.get(`${URL.GET_ROLES_BY_RESTAURANT}/${roleId}`, {params});

}
