import React, {createContext, useState} from 'react';
import GlobalConfigService from "../services/GlobalConfigService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";


export const GlobalConfigContext = createContext("GlobalConfigContext");

const GlobalConfigContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [globalConfigList, setGlobalConfigList] = useState([]);

    const getAllGlobalConfig = async params => {
        try {

            setLoading(true);

            const res = await GlobalConfigService.getAllGlobalConfig(params);
            setGlobalConfigList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <GlobalConfigContext.Provider
            value={{
                loading,
                totalElements,
                globalConfigList,
                getAllGlobalConfig,
            }}
        >
            {children}
        </GlobalConfigContext.Provider>
    );
}

export default GlobalConfigContextProvider;
