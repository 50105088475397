import React, {createContext, useState} from 'react';
import PromoService from "../services/PromoService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";


export const PromoContext = createContext("PromoContext");

const PromoContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [promoList, setPromoList] = useState([]);

    const getAllPromo = async params => {
        try {

            setLoading(true);

            const res = await PromoService.getAllPromo(params);
            setPromoList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <PromoContext.Provider
            value={{
                loading,
                totalElements,
                promoList,
                getAllPromo,
            }}
        >
            {children}
        </PromoContext.Provider>
    );
}

export default PromoContextProvider;
