import React, {createContext, useContext, useState} from 'react';
import {Toast} from "../../components/common/Toast";
import {getDiscountAmount, getErrorMessage, longToDateFormatterTwo, percentValueCalculation} from "../../helpers/Utils";
import BranchOrderService from "../../services/order/BranchOrderService";
import BranchCateringGlobalMenuService from "../../services/branch_catering/BranchCateringGlobalMenuService";
import dayjs from "dayjs";
import {parseInt} from "lodash";
import {AuthContext} from "../AuthContextProvider";

export const CateringOrderContext = createContext("CateringOrderContext");

const CateringOrderContextProvider = ({children}) => {

    const [cateringMenu, setCateringMenu] = useState(null);
    const [cateringMenuSection, setCateringMenuSection] = useState("")

    const [cateringCartDetails, setCateringCartDetails] = useState(null);

    const [cateringCartDetailsList, setCateringCartDetailsList] = useState([]);
    const [cateringCartSelectedItems, setCateringCartSelectedItems] = useState([]);
    const [cateringCartSelectedItemIds, setCateringCartSelectedItemIds] = useState([]);
    const [cateringItemGroups, setCateringItemGroups] = useState([]);
    const [person, setPerson] = useState(10);
    const [multipleGroupChoiceOfItem, setMultipleGroupChoiceOfItem] = useState(null);
    const [createOrderLoading, setCreateOrderLoading] = useState(false);
    const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
    const [cateringCartAdditionalIds, setCateringCartAdditionalIds] = useState([]);
    const _discount = {discountValue: 0, discountType: "FLAT"}
    const [discount, setDiscount] = useState(_discount)
    const [orderInfo, setOrderInfo] = useState(null);
    const [cateringSectionList, setCateringSectionList] = useState([]);

    const [cateringGroupForAll, setCateringGroupForAll] = useState([]);

    const [loading, setLoading] = useState(false);

    const [invoice, setInvoice] = useState({
        subTotal: 0,
        discount: 0,
        tax: 0,
        total: 0
    });

    const _paymentTypes = [
        {id: 1, type: "CASH", isSelected: true, amount: ""},
        {id: 2, type: "ONLINE", isSelected: false, amount: ""},
        {id: 3, type: "MFS", isSelected: false, amount: ""}
    ]

    const [paymentTypes, setPaymentTypes] = useState(_paymentTypes);

    const [branch, setBranch] = useState(null);

    const {branchConfig} = useContext(AuthContext);

    const getCateringMenuById = async id => {

        setCateringMenuSection("");

        try {
            const find = cateringCartDetailsList.find(cateringCartDetails => cateringCartDetails.id === id);

            if (find) {
                Toast("error", "Error", "Already this catering item is added");
                return;
            }

            let obj = {};

            const res = await BranchCateringGlobalMenuService.getBranchCateringMenuById(id);
            setCateringMenu(res.data);

            const _selectedItems = res.data.cateringMenu.cateringItemGroups
                .filter(cateringItemGroup => !cateringItemGroup.multiple)
                .map(cateringItemGroup => cateringItemGroup.cateringItems[0])

            res.data.cateringMenu.cateringItemGroups
                .filter(cateringItemGroup => cateringItemGroup.multiple)
                .forEach(cateringItemGroup => {
                        obj[cateringItemGroup.id] = cateringItemGroup.choiceOfItem
                    }
                )

            const cateringItemGroups = getCateringSectionsByCateringMenuId(res.data.cateringMenu.cateringItemGroups);

            getCateringGroupsByGroupId(cateringItemGroups[0].id, res.data.cateringMenu.cateringItemGroups);

            const _selectedItemIds = _selectedItems.map(item => item.id);

            const _cateringDetails = {
                id: res.data.id,
                quantity: person,
                price: res.data.price,
                cateringCartMenu: {
                    branchCateringMenu: {
                        id: res.data.id,
                        cateringMenu: {
                            i18CateringMenuDetails: res.data.cateringMenu.i18CateringMenuDetails,
                        }
                    },
                    selectedItems: _selectedItems
                }
            }

            setCateringCartDetails(_cateringDetails);

            setCateringCartSelectedItems(_selectedItems);
            setMultipleGroupChoiceOfItem(obj);
            setCateringCartSelectedItemIds(_selectedItemIds);

        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }
    }

    const getCateringMenuForAll = async id => {
        try {

            const res = await BranchCateringGlobalMenuService.getBranchCateringMenuById(id);
            setCateringMenu(res.data);

            setCateringGroupForAll(res.data.cateringMenu.cateringItemGroups)

        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }
    }

    const getCateringMenuEditById = async (cartDetailsId, menuId) => {

        try {

            let obj = {};

            const findCateringCartDetails = cateringCartDetailsList
                .find(cateringCartDetails => cateringCartDetails.id === cartDetailsId);

            const res = await BranchCateringGlobalMenuService.getBranchCateringMenuById(menuId);
            setCateringMenu(res.data);

            const _selectedItems = findCateringCartDetails.cateringCartMenu.selectedItems;

            res.data.cateringMenu.cateringItemGroups
                .filter(cateringItemGroup => cateringItemGroup.multiple)
                .forEach(cateringItemGroup => {
                        obj[cateringItemGroup.id] = cateringItemGroup.choiceOfItem
                    }
                )

            const cateringItemGroups = getCateringSectionsByCateringMenuId(res.data.cateringMenu.cateringItemGroups);

            getCateringGroupsByGroupId(cateringItemGroups[0].id, res.data.cateringMenu.cateringItemGroups);

            const _selectedItemIds = _selectedItems.map(item => item.id);

            setCateringCartDetails(findCateringCartDetails);

            setPerson(findCateringCartDetails.quantity);

            setCateringCartSelectedItems(_selectedItems);
            setMultipleGroupChoiceOfItem(obj);
            setCateringCartSelectedItemIds(_selectedItemIds);

        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }
    }

    const submitCateringCartDetails = () => {

        let _subTotal = 0;

        const findIndex = cateringCartDetailsList.findIndex(c => c.id === cateringCartDetails.id);

        if (findIndex > -1) {

            cateringCartDetailsList[findIndex] = {...cateringCartDetails, quantity: person}

            cateringCartDetailsList
                .forEach(cateringCartDetails => _subTotal += cateringCartDetails.price * cateringCartDetails.quantity)

            setCateringCartDetailsList([...cateringCartDetailsList]);

        } else {

            const _cateringCartDetailsList = [...cateringCartDetailsList, {...cateringCartDetails, quantity: person}]

            setCateringCartDetailsList(_cateringCartDetailsList)
            _cateringCartDetailsList
                .forEach(cateringCartDetails => _subTotal += cateringCartDetails.price * cateringCartDetails.quantity)
        }

        getInvoiceCalculation(_subTotal);

        removeSubmitCateringDetailsDependencies();
    }

    const removeSubmitCateringDetailsDependencies = () => {
        setCateringCartSelectedItemIds([]);
        setCateringCartSelectedItems([]);
        setMultipleGroupChoiceOfItem(null);
        setCateringItemGroups([]);
        setCateringMenu(null);
        setCateringCartDetails(null);
        setCateringSectionList([]);
        setCateringMenuSection("");
        setPerson(10);
    }

    const getCateringSectionsByCateringMenuId = (data) => {

        const cateringSectionSet = new Set();

        const cateringSectionList = data.filter(item => {
            if (!cateringSectionSet.has(item.cateringSection.id)) {
                cateringSectionSet.add(item.cateringSection.id);
                return true;
            }
            return false;
        }).map(item => item.cateringSection);

        setCateringSectionList(cateringSectionList)

        return cateringSectionList;
    }

    const getCateringMenuBySection = sectionId => {

        setCateringMenuSection(parseInt(sectionId));

        const cateringItemGroups = cateringMenu.cateringMenu.cateringItemGroups;
        const filteredCateringItemGroups = cateringItemGroups.filter(cateringItemGroup => cateringItemGroup.cateringSection.id === parseInt(sectionId));

        setCateringItemGroups(filteredCateringItemGroups);
    }

    const getCateringGroupsByGroupId = (groupId, cateringItemGroups) => {

        const filteredCateringItemGroups = cateringItemGroups
            .filter(cateringItemGroup => cateringItemGroup.cateringSection.id === groupId);

        setCateringItemGroups(filteredCateringItemGroups);
    }

    const handleCateringProductCardSelect = (cateringItem) => {


        const find = cateringCartSelectedItems.find(caterCartSelectedItem => caterCartSelectedItem.id === cateringItem.id);

        let count = 1;

        cateringCartSelectedItems.forEach(cateringCartSelectedItem => {
            if (cateringCartSelectedItem.cateringItemGroupId === cateringItem.cateringItemGroupId) {
                count += 1;
            }
        })

        const choiceOfItem = multipleGroupChoiceOfItem[cateringItem.cateringItemGroupId];

        if (count > choiceOfItem && !find) {
            return Toast("error", "Duplicate", `You can not select more than ${choiceOfItem} item`);
        }


        let _cateringCartSelectedItems = [...cateringCartSelectedItems];

        if (find) {
            _cateringCartSelectedItems = cateringCartSelectedItems.filter(cateringSelectedItem => cateringSelectedItem.id !== cateringItem.id);
        } else {
            _cateringCartSelectedItems = [..._cateringCartSelectedItems, cateringItem]
        }

        const _cateringCartDetails = {
            ...cateringCartDetails,
            cateringCartMenu: {
                ...cateringCartDetails.cateringCartMenu,
                selectedItems: _cateringCartSelectedItems
            }
        }

        setCateringCartDetails(_cateringCartDetails);

        const _selectedItemIds = _cateringCartSelectedItems.map(item => item.id)

        setCateringCartSelectedItems([..._cateringCartSelectedItems]);

        setCateringCartSelectedItemIds([..._selectedItemIds]);

    }

    const handleAdditionalCateringProductCardSelect = (cateringItem) => {

        let _subTotal = 0;

        const findCateringDetails = cateringCartDetailsList
            .find(cateringDetails => cateringDetails?.branchProduct?.id === cateringItem.id)

        if (findCateringDetails) {

            const filteredCateringDetailsList = cateringCartDetailsList
                .filter(cateringCartDetails => {
                    if (cateringCartDetails.branchProduct) {
                        return cateringCartDetails.branchProduct.id !== cateringItem.id
                    }
                    return cateringCartDetails
                });

            setCateringCartAdditionalIds(cateringCartAdditionalIds.filter(cateringCartAdditionalId => cateringCartAdditionalId !== cateringItem.id))

            filteredCateringDetailsList.forEach(cateringDetails => _subTotal += (cateringDetails.price * cateringDetails.quantity))

            setCateringCartDetailsList(filteredCateringDetailsList)
        } else {

            const _cateringCartDetails = {
                id: cateringItem.id,
                quantity: person,
                price: cateringItem.price,
                branchProduct: cateringItem
            }

            const _cateringCartDetailsList = [...cateringCartDetailsList, _cateringCartDetails]

            setCateringCartAdditionalIds([...cateringCartAdditionalIds, cateringItem.id]);

            _cateringCartDetailsList.forEach(cateringDetails => _subTotal += (cateringDetails.price * cateringDetails.quantity));

            setCateringCartDetailsList(_cateringCartDetailsList);
        }

        getInvoiceCalculation(_subTotal);

    }

    const deleteCateringCart = cateringItem => {

        let _subTotal = 0;

        const filteredCateringCartDetailsList = cateringCartDetailsList
            .filter(cateringCartDetails => cateringCartDetails.id !== cateringItem.id)

        if (cateringItem.branchProduct) {

            const filteredCateringCartAdditionalIds = cateringCartAdditionalIds
                .filter(cateringCartAdditionalId => cateringCartAdditionalId !== cateringItem.id);

            setCateringCartAdditionalIds(filteredCateringCartAdditionalIds);
        } else {
            setCateringCartSelectedItems([]);
            setCateringCartSelectedItemIds([]);
        }

        filteredCateringCartDetailsList.forEach(cartDetails => _subTotal += (cartDetails.quantity * cartDetails.price));

        setCateringCartDetailsList(filteredCateringCartDetailsList);
        getInvoiceCalculation(_subTotal);
    }

    const createCateringOrder = async data => {
        try {

            setCreateOrderLoading(true);

            const res = await BranchOrderService.createBranchOrder(data);
            Toast("success", "Created", "Catering order has been placed successfully");

            removeAllCart();

            setCreateOrderLoading(false);

            return res.data;

        } catch (error) {

            setCreateOrderLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null;

        }
    }

    const updateCateringOrder = async (id, data) => {
        try {
            setUpdateOrderLoading(true);

            await BranchOrderService.updateBranchOrder(id, data);
            Toast("success", "Created", "Catering order has been updated successfully");

            removeAllCart();

            setUpdateOrderLoading(false);

            return true;

        } catch (error) {
            setUpdateOrderLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const changeCateringItemIncrement = (id, action) => {

        let _subTotal = 0;

        const _cateringCartDetailsList = cateringCartDetailsList.map(cateringDetails => {

            if (cateringDetails.id === id) {
                return {
                    ...cateringDetails,
                    quantity: action === "PLUS" ? parseInt(cateringDetails.quantity) + 1 : (cateringDetails.quantity > 1) ? parseInt(cateringDetails.quantity) - 1 : cateringDetails.quantity
                }
            }
            return cateringDetails;
        });

        _cateringCartDetailsList.forEach(cateringDetails => _subTotal += (cateringDetails.quantity * cateringDetails.price))

        setCateringCartDetailsList([..._cateringCartDetailsList]);

        getInvoiceCalculation(_subTotal);

    }

    const changeCateringItemQuantity = (id, quantity) => {

        let _subTotal = 0;

        if (quantity > 0) {
            const _cateringCartDetailsList = cateringCartDetailsList.map(cateringDetails => {

                if (cateringDetails.id === id) {
                    return {
                        ...cateringDetails,
                        quantity
                    }
                }
                return cateringDetails;
            });

            _cateringCartDetailsList.forEach(cateringDetails => _subTotal += (cateringDetails.quantity * cateringDetails.price))

            setCateringCartDetailsList([..._cateringCartDetailsList]);
            getInvoiceCalculation(_subTotal);

        }
    }

    const changeCateringOrderDiscount = (discountValue, discountType) => {

        setDiscount(null);

        const _discount = {discountValue, discountType};

        const _discountValue = getDiscountAmount(invoice.subTotal, discountValue, discountType)

        const taxPercentage = branchConfig?.taxPercentage ? branchConfig.taxPercentage : 0;

        const taxValue = percentValueCalculation((invoice.subTotal - _discountValue), taxPercentage);

        const total = (invoice.subTotal - _discountValue) + taxValue

        setInvoice({...invoice, tax: taxValue, total, discount: _discountValue})

        setDiscount(_discount);

    }

    const getInvoiceCalculation = (subTotal) => {

        const _discount = getDiscountAmount(subTotal, discount?.discountValue, discount?.discountType);
        const taxPercentage = branchConfig?.taxPercentage ? branchConfig.taxPercentage : 0;
        const taxValue = percentValueCalculation(subTotal, taxPercentage);
        const total = (subTotal - _discount) + taxValue;

        const _invoice = {
            subTotal,
            total,
            tax: taxValue,
            discount: _discount,
        }

        setInvoice(_invoice);

    }

    const handleOrderInfoChange = (data) => {
        const _orderInfo = {...orderInfo, ...data}
        setOrderInfo(_orderInfo)
    }

    const getBranchCateringOrderById = async id => {
        try {

            setLoading(true);

            const res = await BranchOrderService.getBranchOrderById(id);
            setCateringCartDetailsList(res.data.cateringCartDetailsList);

            const _paymentTypes = paymentTypes.map(paymentType => {

                const find = res.data.payments.find(resPayment => {
                    return resPayment.type === paymentType.type
                });

                if (find) {
                    return {
                        ...paymentType,
                        isSelected: true,
                        amount: find.amount
                    }
                } else {
                    return paymentType
                }
            })

            const _orderInfo = {
                eventDate: res.data.eventDate ? dayjs(longToDateFormatterTwo(res.data.eventDate)) : "",
                specialNote: res.data?.specialNote,
                fullName: res.data?.fullName,
                mobileNumber: res.data?.mobileNumber,
                countryCode: res.data?.countryCode,
                eventLocation: res.data?.eventLocation,
                guestCount: res.data?.guestCount,
                paid: res.data?.paid,
                invoiceNumber: res.data?.invoiceNumber,
                tips: res.data?.tips
            }

            const resDiscount = res.data.invoice?.discount?.discountValue ? res.data.invoice.discount : _discount;

            const _invoice = {
                subTotal: res.data.invoice.totalPrice,
                total: res.data.invoice.finalPrice,
                tax: res.data.invoice.taxAmount,
                discount: res.data.invoice.discountAmount,
            }

            setBranch(res.data.branch);
            setInvoice(_invoice);
            setPaymentTypes(_paymentTypes);
            setOrderInfo(_orderInfo);
            setDiscount(resDiscount);

            setLoading(false);

        } catch (error) {
            setLoading(false);

        }
    }

    const removeAllCart = () => {
        setCateringCartAdditionalIds([]);
        setCateringCartSelectedItems([]);
        setCateringCartDetailsList([]);
        setCateringCartDetails(null);
        setCateringItemGroups([]);
        setMultipleGroupChoiceOfItem(null);
        setInvoice({
            subTotal: 0,
            discount: 0,
            tax: 0,
            total: 0
        });
        setPaymentTypes(_paymentTypes);
        setOrderInfo(null);
        setPerson(10);
    }

    return (
        <CateringOrderContext.Provider
            value={{
                cateringMenu,
                cateringCartDetailsList,
                cateringCartSelectedItems,
                setCateringCartSelectedItems,
                cateringItemGroups,
                setCateringItemGroups,
                multipleGroupChoiceOfItem,
                setMultipleGroupChoiceOfItem,
                cateringCartSelectedItemIds,
                person,
                setPerson,
                paymentTypes,
                setPaymentTypes,
                createOrderLoading,
                cateringCartAdditionalIds,
                discount,
                invoice,
                orderInfo,
                cateringSectionList,
                loading,
                updateOrderLoading,
                cateringGroupForAll,
                setCateringCartDetailsList,
                setInvoice,
                cateringMenuSection,
                branch,
                handleAdditionalCateringProductCardSelect,
                deleteCateringCart,
                handleCateringProductCardSelect,
                getCateringMenuById,
                getCateringMenuBySection,
                createCateringOrder,
                changeCateringItemIncrement,
                changeCateringItemQuantity,
                changeCateringOrderDiscount,
                handleOrderInfoChange,
                getBranchCateringOrderById,
                submitCateringCartDetails,
                getCateringMenuEditById,
                removeAllCart,
                getCateringMenuForAll,
                updateCateringOrder,
            }}
        >
            {children}
        </CateringOrderContext.Provider>
    );
}

export default CateringOrderContextProvider;
