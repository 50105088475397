import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchAddOnsService {
    static assignBranchAddOns = data => privateAPI.post(
        `${URL.CREATE_BRANCH_ADD_ONS}/${getBranchId()}`, data
    );

    static getAllBranchAddOns = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH_ADD_ONS}/${getBranchId()}`, {params}
    );

    static getBranchAddOnsById = id => privateAPI.get(
        `${URL.GET_BRANCH_ADD_ONS_BY_ID}/${id}/${getBranchId()}`
    );

    static updateBranchAddOns = (id, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH_ADD_ONS}/${id}/${getBranchId()}`, data
    );

}
