import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import UserService from "../services/UserService";
import {Toast} from "../components/common/Toast";

export const UserContext = createContext("UserContext");

const UserContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [userList, setUserList] = useState([]);

    const getAllUser = async (params) => {
        try {

            setLoading(true);

            const res = await UserService.getAllUser(params);
            setUserList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <UserContext.Provider
            value={{
                loading,
                totalElements,
                userList,
                getAllUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;
