import AnalyticsContextProvider from "../contexts/AnalyticsContextProvider";
import AuthContextProvider from "../contexts/AuthContextProvider";
import BannerContextProvider from "../contexts/BannerContextProvider";
import BranchConfigContextProvider from "../contexts/BranchConfigContextProvider";
import BranchContextProvider from "../contexts/BranchContextProvider";
import BranchQrCodeOrderContextProvider from "../contexts/BranchQrCodeOrderContextProvider";
import BranchReportContextProvider from "../contexts/BranchReportContextProvider";
import BranchSupplierContextProvider from "../contexts/BranchSupplierContextProvider";
import CurrentLocationContextProvider from "../contexts/CurrentLocationContextProvider";
import CustomerContextProvider from "../contexts/CustomerContextProvider";
import FranchiseUserContextProvider from "../contexts/FranchiseUserContextProvider";
import GlobalConfigContextProvider from "../contexts/GlobalConfigContextProvider";
import KitchenDisplayContextProvider from "../contexts/KitchenDisplayContext";
import PromoContextProvider from "../contexts/PromoContextProvider";
import ReportContextProvider from "../contexts/ReportContextProvider";
import RoleContextProvider from "../contexts/RoleContextProvider";
import SupplierContextProvider from "../contexts/SupplierContextProvider";
import TableContextProvider from "../contexts/TableContextProvider";
import ThemeContextProvider from "../contexts/ThemeContextProvider";
import UserContextProvider from "../contexts/UserContextProvider";
import AgentContextProvider from "../contexts/agent/AgentContextProvider";
import AgentPaymentContextProvider from "../contexts/agent/AgentPaymentContextProvider";
import BranchCateringGlobalMenuContextProvider
    from "../contexts/branc_catering/BranchCateringGlobalMenuContextProvider";
import BranchCateringMenuContextProvider from "../contexts/branc_catering/BranchCateringMenuContextProvider";
import BranchGlobalIngredientContextProvider
    from "../contexts/branch_ingredient_management/BranchGlobalIngredientContextProvider";
import IngredientBranchContextProvider from "../contexts/branch_ingredient_management/IngredientBranchContextProvider";
import BranchAddOnsContextProvider from "../contexts/branch_product/BranchAddOnsContextProvider";
import BranchProductContextProvider from "../contexts/branch_product/BranchProductContextProvider";
import BranchRequestAddOnsContextProvider from "../contexts/branch_product/BranchRequestAddOnsContextProvider";
import BranchRequestProductContextProvider from "../contexts/branch_product/BranchRequestProductContextProvider";
import CateringMenuContextProvider from "../contexts/catering/CateringMenuContextProvider";
import CateringOrderContextProvider from "../contexts/catering/CateringOrderContextProvider";
import CateringProductContextProvider from "../contexts/catering/CateringProductContextProvider";
import CateringSectionContextProvider from "../contexts/catering/CateringSectionContextProvider";
import IngredientCategoryContextProvider from "../contexts/ingredient_management/IngredientCategoryContextProvider";
import IngredientContextProvider from "../contexts/ingredient_management/IngredientContextProvider";
import RequestIngredientContextProvider from "../contexts/ingredient_management/RequestIngredientContextProvider";
import BranchOrderContextProvider from "../contexts/order/BranchOrderContextProvider";
import OrderContextProvider from "../contexts/order/OrderContextProvider";
import PosOrderContextProvider from "../contexts/order/PosOrderContextProvider";
import AddOnsContextProvider from "../contexts/product/AddOnsContextProvider";
import CategoryContextProvider from "../contexts/product/CategoryContextProvider";
import ProductContextProvider from "../contexts/product/ProductContextProvider";
import StockTransactionContextProvider from "../contexts/stock/StockTransactionContextProvider";
import ReadyProductStockTransactionContextProvider
    from "../contexts/ready_product_stock/ReadyProductStockTransactionContextProvider";
import PosOrderContextProviderNew from "../contexts/order/PosOrderContextProviderNew";
import PrintContextProvider from "../contexts/PrintContextProvider";
import BillingContextProvider from "../contexts/BillingContextProvider";


export const allProvider = [
    BillingContextProvider,
    PrintContextProvider,
    PosOrderContextProviderNew,
    ReadyProductStockTransactionContextProvider,
    ThemeContextProvider,
    UserContextProvider,
    FranchiseUserContextProvider,
    BranchContextProvider,
    CategoryContextProvider,
    AddOnsContextProvider,
    ProductContextProvider,
    RoleContextProvider,
    BranchProductContextProvider,
    BranchRequestProductContextProvider,
    PromoContextProvider,
    BranchRequestAddOnsContextProvider,
    BranchAddOnsContextProvider,
    GlobalConfigContextProvider,
    BranchConfigContextProvider,
    OrderContextProvider,
    BranchOrderContextProvider,
    CustomerContextProvider,
    BannerContextProvider,
    ReportContextProvider,
    AnalyticsContextProvider,
    BranchReportContextProvider,
    TableContextProvider,
    CateringSectionContextProvider,
    CateringProductContextProvider,
    CateringMenuContextProvider,
    CateringOrderContextProvider,
    BranchCateringMenuContextProvider,
    BranchCateringGlobalMenuContextProvider,
    KitchenDisplayContextProvider,
    PosOrderContextProvider,
    IngredientCategoryContextProvider,
    IngredientContextProvider,
    BranchGlobalIngredientContextProvider,
    RequestIngredientContextProvider,
    IngredientBranchContextProvider,
    SupplierContextProvider,
    BranchSupplierContextProvider,
    AgentContextProvider,
    StockTransactionContextProvider,
    AgentPaymentContextProvider,
    CurrentLocationContextProvider,
    AuthContextProvider,
    BranchQrCodeOrderContextProvider,
];