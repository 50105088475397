import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import IngredientService from "../../services/ingredient/IngredientService";
import BranchIngredientService from "../../services/branch_ingredient/IngredientBranchService";
export const IngredientBranchContext = createContext("IngredientBranchContext");

const IngredientBranchContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [branchIngredientTotalElements, setBranchIngredientTotalElements] = useState(0);
    const [ingredientBranchList, setIngredientBranchList] = useState([]);

    const [units, setUnits] = useState([])
    const [unitsTotalElements, setUnitsTotalElements] = useState(0)

    const getAllIngredientForBranch = async params => {
        try {

            setLoading(true);

            const res = await BranchIngredientService.getAllIngredientForBranch(params);
            setIngredientBranchList(res.data.content);
            setBranchIngredientTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }
    const getAllUnits = async params => {
        try {

            setLoading(true);

            const res = await IngredientService.getAllUnits(params);
            setUnits(res.data.content);
            setUnitsTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <IngredientBranchContext.Provider
            value={{
                loading,
                branchIngredientTotalElements,
                ingredientBranchList,
                getAllIngredientForBranch,
                getAllUnits,
                units,
                unitsTotalElements
            }}
        >
            {children}
        </IngredientBranchContext.Provider>
    );
}

export default IngredientBranchContextProvider;
