import {ConfigProvider} from "antd";
import React, {lazy, Suspense, useContext, useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoadingSuspense from './components/common/LoadingSuspense';
import PrivateRoute from './components/common/PrivateRoute';
import {AuthContext} from './contexts/AuthContextProvider';
import {KitchenDisplayContext} from "./contexts/KitchenDisplayContext";
import {ThemeContext} from "./contexts/ThemeContextProvider";
import {I18nProvider} from "./i18n";
import Interceptors from "./rest_handlers/Interceptors";
import {
    BILLING_INVOICE_PUBLIC_PATH,
    CREATE_POS_ORDER_NEW_PATH,
    CREATE_POS_ORDER_PATH,
    KITCHEN_DISPLAY_PATH,
    LOGIN_PATH,
    OUR_MENU_PATH,
    OWNER_LOGIN_PATH,
    PAGE_403_PATH,
    PAGE_404_PATH,
    PAGE_500_PATH,
    PAYMENT_CANCELED_PATH,
    PAYMENT_FAILED_PATH,
    PAYMENT_SUCCESS_PATH,
    POS_HOLD_LIST_VIEW_PATH,
    POS_ORDER_LIST_VIEW_NEW_PATH,
    POS_ORDER_LIST_VIEW_NEW_PATH_TWO,
    POS_ORDER_LIST_VIEW_PATH,
    PUBLIC_BANNERS_PATH,
    UPDATE_POS_ORDER__NEW_PATH,
    UPDATE_POS_ORDER_PATH
} from "./routes/Slug";
import {lightComponentsToken, lightToken} from "./them_token/light";
import withRestaurantTokenInitializer from "./components/hoc/withRestaurantTokenInitializer";

const DefaultLayout = lazy(() => import("./components/layout/DefaultLayout"));
const Login = lazy(() => import("./pages/login/Login"));
const OwnerLogin = lazy(() => import("./pages/login/OwnerLogin"));
const Page403 = lazy(() => import("./pages/error_pages/Page403"));
const Page404 = lazy(() => import("./pages/error_pages/Page404"));
const Page500 = lazy(() => import("./pages/error_pages/Page500"));

const KitchenDisplayView = lazy(() => import("./pages/kitchen_display/KitchenDisplayView"));

const CreatePosOrder = lazy(() => import("./pages/pos/CreatePosOrder"));
const UpdatePosOrder = lazy(() => import("./pages/pos/UpdatePosOrder"));
const PosOrderListView = lazy(() => import("./pages/pos/PosOrderListView"));
const PosHoldListView = lazy(() => import("./pages/pos/PosHoldListView"));
const OurMenu = lazy(() => import("./pages/our_menu/OurMenu"));

const BannerLayout = lazy(() => import("./pages/public_banner/BannerLayout"));
const Banners = lazy(() => import("./pages/public_banner/Banners"));
const BannerDetails = lazy(() => import("./pages/public_banner/BannerDetails"));

// POS New
const PosOrderListViewNew = lazy(() => import("./pages/pos_new/PosOrderListViewNew"));
const CreatePosOrderNew = lazy(() => import("./pages/pos_new/CreatePosOrderNew"));
const UpdatePosOrderNew = lazy(() => import("./pages/pos_new/UpdatePosOrderNew"));
const PosOrderListViewNew2 = lazy(() => import("./pages/pos_new/PosOrderListViewNew2"));

//  billing
const BillingInvoiceListPublicView = lazy(() => import("./pages/billing_invoice/BillingInvoicePublic"));

// Payment Status
const PaymentSuccess = lazy(() => import("./pages/payment_status/PaymentSuccess"));
const PaymentFailed = lazy(() => import("./pages/payment_status/PaymentFailed"));
const PaymentCancelled = lazy(() => import("./pages/payment_status/PaymentCancelled"));

const App = () => {

    const {isLogin, selectedBranch} = useContext(AuthContext);
    const {setScreenWidth, locale} = useContext(ThemeContext);
    const {setCurrentItemCount} = useContext(KitchenDisplayContext);

    const authContext = useContext(AuthContext);

    useEffect(() => {

        setScreenWidth(window.innerWidth);
        setCurrentItemCount(window.innerWidth > 1600 ? 4 : 3);

        window.addEventListener('resize', updateDimensions);

        if (authContext.isLogin) {
            authContext.getUserProfile();
        }

        return () => {
            window.removeEventListener('resize', updateDimensions);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateDimensions = () => {
        setScreenWidth(window.innerWidth);
        setCurrentItemCount(window.innerWidth > 1600 ? 4 : 3);
    }

    return (
        <I18nProvider locale={locale}>
            <ConfigProvider
                direction={(locale === "ur-pk" || locale === "ps-af") ? "rtl" : "ltr"}
                theme={{
                    token: {...lightToken},
                    components: lightComponentsToken
                }}
            >
                <div className="app-wrapper">
                    <Suspense fallback={<LoadingSuspense/>}>
                        <BrowserRouter>
                            <Routes>
                                <Route path={LOGIN_PATH} element={<Login/>}/>
                                <Route path={OWNER_LOGIN_PATH} element={<OwnerLogin/>}/>

                                {/*Payment Status*/}
                                <Route path={PAYMENT_SUCCESS_PATH} element={<PaymentSuccess/>}/>
                                <Route path={PAYMENT_FAILED_PATH} element={<PaymentFailed/>}/>
                                <Route path={PAYMENT_CANCELED_PATH} element={<PaymentCancelled/>}/>

                                <Route path={BILLING_INVOICE_PUBLIC_PATH} element={<BillingInvoiceListPublicView/>}/>
                                <Route path={OUR_MENU_PATH} element={<OurMenu/>}/>
                                <Route path={`${PUBLIC_BANNERS_PATH}/`} element={<BannerLayout/>}>
                                    <Route index element={<Banners/>}/>
                                    <Route path={":id"} element={<BannerDetails/>}/>
                                </Route>
                                <Route element={<PrivateRoute isLogin={isLogin} selectedBranch={selectedBranch}/>}>
                                    <Route path={KITCHEN_DISPLAY_PATH} element={<KitchenDisplayView/>}/>
                                    <Route path={CREATE_POS_ORDER_PATH} element={<CreatePosOrder/>}/>
                                    <Route path={CREATE_POS_ORDER_NEW_PATH} element={<CreatePosOrderNew/>}/>
                                    <Route path={POS_ORDER_LIST_VIEW_NEW_PATH} element={<PosOrderListViewNew/>}/>
                                    <Route path={POS_ORDER_LIST_VIEW_NEW_PATH_TWO} element={<PosOrderListViewNew2/>}/>
                                    <Route path={`${UPDATE_POS_ORDER_PATH}/:id`} element={<UpdatePosOrder/>}/>
                                    <Route path={`${UPDATE_POS_ORDER__NEW_PATH}/:id`} element={<UpdatePosOrderNew/>}/>
                                    <Route path={POS_ORDER_LIST_VIEW_PATH} element={<PosOrderListView/>}/>
                                    <Route path={POS_HOLD_LIST_VIEW_PATH} element={<PosHoldListView/>}/>
                                    <Route path={PAGE_404_PATH} element={<Page404/>}/>
                                    <Route path={PAGE_403_PATH} element={<Page403/>}/>
                                    <Route path={PAGE_500_PATH} element={<Page500/>}/>
                                    <Route path="*" element={<DefaultLayout/>}/>
                                </Route>
                            </Routes>
                            <Interceptors/>
                        </BrowserRouter>
                    </Suspense>
                </div>
            </ConfigProvider>
        </I18nProvider>
    );

}

export default withRestaurantTokenInitializer(App);

