import {Locales} from "../Locales";

export const hi_IN = {
    [Locales.hindi]: {
        // common
        "common.error": "त्रुटि",
        "common.created": "बनाया गया",
        "common.added": "जोड़ा गया",
        "common.updated": "अपडेट किया गया",
        "common.submit": "प्रस्तुत",
        "common.email": "ईमेल",
        "common.password": "पासवर्ड",
        "common.all": "सभी",
        "common.action": "क्रिया",
        "common.reset": "रीसेट",
        "common.search_list": "खोज सूची",
        "common.select_language": "भाषा चुनें",
        "common.language_required": "कृपया एक भाषा का चयन करें",
        "common.image": "छवि",
        "common.address": "पता",
        "common.city": "शहर",
        "common.country": "देश",
        "common.zipcode": "पिन कोड",
        "common.assigned": "सौंपा गया",
        "common.yes": "हाँ",
        "common.no": "नहीं",
        "common.approved": "मंजूर",
        "common.approve": "मंजूरी",
        "common.approve_small": "मंजूर",
        "common.disable": "अक्षम",
        "common.disable_small": "अक्षम",
        "common.password_changed": "पासवर्ड बदल दिया गया",
        "common.confirm_msg": "क्या आप निश्चित हैं कि आप",
        "common.the_order": "आदेश",
        "common.the_product": "उत्पाद",
        "common.the_promo": "प्रोमो",
        "common.the_banner": "बैनर",
        "common.the_add_ons": "अतिरिक्त सुविधाएं",
        "common.success_msg_has": "हो गई है",
        "common.success": "सफलतापूर्वक",
        "common.activated": "सक्रिय",
        "common.mile": "मील",
        "common.create": "बनाएं",
        "common.update": "अपडेट करें",
        "common.optional": "वैकल्पिक",
        "common.add": "जोड़ें",
        "common.created_at": "को बनाया गया",
        "common.created_date": "तिथि बनाई गई",
        "common.cancelled": "रद्द",
        "common.download": "डाउनलोड",
        "common.date": "तिथि",
        "common.items": "आइटम",
        "common.assign_to_branch": "अपनी शाखा को सौंपें",
        "common.edit": "संपादित करें",
        "common.details": "विवरण",

        // nav header
        "nav_header.profile": "प्रोफाइल",
        "nav_header.logout": "लॉग आउट",
        "nav_header.english": "अंग्रेज़ी",
        "nav_header.bengali": "बंगाली",
        "nav_header.urdu": "उर्दू",
        "nav_header.hindi": "हिंदी",
        "nav_header.pashto": "पश्तो",

        // aside left
        "aside_left.dashboard": "डैशबोर्ड",
        "aside_left.user_management": "उपयोगकर्ता प्रबंधन",
        "aside_left.user_list": "उपयोगकर्ता सूची",
        "aside_left.product_management": "उत्पाद प्रबंधन",
        "aside_left.product": "उत्पाद",
        "aside_left.product_category": "श्रेणी",
        "aside_left.product_add_ons": "अतिरिक्त सुविधाएँ",
        "aside_left.branch": "शाखा",
        "aside_left.branch_list": "शाखा सूची",
        "aside_left.orders": "आदेश",
        "aside_left.roles": "भूमिकाएँ",
        "aside_left.assign_employee": "कर्मचारी को सौंपें",
        "aside_left.requested_product": "अनुरोधित उत्पाद",
        "aside_left.requested_add_ons_list": "अनुरोधित अतिरिक्त सुविधाएँ",
        "aside_left.branch_product": "शाखा उत्पाद",
        "aside_left.branch_add_ons": "शाखा अतिरिक्त सुविधाएँ",
        "aside_left.promo": "प्रोमो",
        "aside_left.global": "वैश्विक",
        "aside_left.employee_list": "कर्मचारी सूची",
        "aside_left.customer_list": "ग्राहक सूची",
        "aside_left.global_config": "वैश्विक कॉन्फ़िगरेशन",
        "aside_left.customer": "ग्राहक",
        "aside_left.banner": "बैनर",
        "aside_left.analytics": "विश्लेषण",
        "aside_left.catering": "केटरिंग",
        "aside_left.catering_products": "केटरिंग उत्पाद",
        "aside_left.catering_sections": "केटरिंग खंड",
        "aside_left.catering_menu": "केटरिंग मेनू",
        "aside_left.catering_order": "केटरिंग आदेश",
        "aside_left.kitchen_display": "रसोई प्रदर्शन",
        "aside_left.pos": "पीओएस",
        "aside_left.ingredient_management": "सामग्री प्रबंधन",
        "aside_left.ingredient_category": "सामग्री श्रेणी",
        "aside_left.ingredient": "सामग्री",
        "aside_left.requested_ingredient": "अनुरोधित सामग्री",
        "aside_left.supplier": "आपूर्तिकर्ता",
        "aside_left.stock_management": "स्टॉक प्रबंधन",
        "aside_left.stock": "स्टॉक",
        "aside_left.stock_in": "स्टॉक इन",
        "aside_left.stock_out": "स्टॉक आउट",
        "aside_left.expense": "व्यय",
        "aside_left.agent": "एजेंट",
        "aside_left.agent_management": "एजेंट प्रबंधन",
        "aside_left.agent_payment": "एजेंट भुगतान",
        "aside_left.branch_config": "शाखा कॉन्फ़िगरेशन",
        "aside_left.stock_summary": "स्टॉक सारांश",
        "aside_left.expired_stock": "समाप्त स्टॉक",
        "aside_left.table": "टेबल",
        "aside_left.requested_products": "अनुरोधित उत्पाद",
        "aside_left.catering_product": "केटरिंग उत्पाद",
        "aside_left.order": "आदेश",
        "aside_left.requested_menu": "अनुरोधित मेनू",
        "aside_left.franchises": "फ्रैंचाइज़",
        "aside_left.order_management": "आदेश प्रबंधन",
        "aside_left.order_reports": "आदेश रिपोर्ट्स",
        "aside_left.order_item_reports": "आदेश आइटम रिपोर्ट्स",
        "aside_left.order_history": "आदेश इतिहास",

        // owner login
        "login.email_required_msg": "ईमेल आवश्यक है",
        "login.valid_email_msg": "कृपया एक मान्य ईमेल दर्ज करें",
        "login.password_required_msg": "पासवर्ड आवश्यक है",
        "login.sign_in": "साइन इन करें",
        "login.sign_in_msg": "साइन इन करने के लिए अपना ईमेल और पासवर्ड दर्ज करें!",

        // user
        "user.total_employee": "कुल कर्मचारी",
        "user.create_user": "उपयोगकर्ता बनाएं",
        "user.update_user": "उपयोगकर्ता अपडेट करें",
        "user.full_name": "पूरा नाम",
        "user.create_success_msg": "उपयोगकर्ता सफलतापूर्वक बनाया गया है",
        "user.update_success_msg": "उपयोगकर्ता को सफलतापूर्वक अपडेट किया गया है",
        "user.user_data_not_available": "उपयोगकर्ता डेटा उपलब्ध नहीं है",
        "user.profile_picture": "प्रोफ़ाइल चित्र",
        "user.phone_number": "फ़ोन नंबर",
        "user.date_of_birth": "जन्म तिथि",
        "user.gender": "लिंग",
        "user.active": "सक्रिय",
        "user.disabled": "अक्षम",
        "user.banned": "निषेधित",
        "user.verified": "सत्यापित",
        "user.email_verified": "ईमेल सत्यापित",
        "user.phone_verified": "फ़ोन सत्यापित",
        "user.profile": "प्रोफ़ाइल",
        "user.profile_update_msg": "प्रोफ़ाइल को सफलतापूर्वक अपडेट किया गया है",
        "user.changed_password_msg": "पासवर्ड सफलतापूर्वक बदल दिया गया है",
        "user.create_employee": "कर्मचारी बनाएं",
        "user.update_employee": "कर्मचारी अपडेट करें",
        "user.select_role": "कृपया भूमिका का चयन करें",

        // user form
        "user_form.full_name_required": "पूरा नाम आवश्यक है",
        "user_form.email_required": "ईमेल आवश्यक है",
        "user_form.male": "पुरुष",
        "user_form.female": "महिला",
        "user_form.others": "अन्य",
        "user_form.role_required": "भूमिका आवश्यक है",
        "user_form.phone_number_required": "फ़ोन नंबर आवश्यक है",
        "user_form.invalid_email": "अवैध ईमेल",
        "user_form.hotline": "हॉटलाइन",
        "user_form.hotline_required": "हॉटलाइन आवश्यक है",

        // customer
        "customer.total_customer": "कुल ग्राहक",
        "customer.customer_list": "ग्राहक सूची",
        "customer.customer_name": "ग्राहक का नाम",
        "customer.customer_create_msg": "ग्राहक को सफलतापूर्वक बनाया गया है",
        "customer.invalid_phone": "अवैध फ़ोन नंबर",
        "customer.customer_name_required": "ग्राहक का नाम आवश्यक है",
        "customer.cancel": "रद्द करें",
        "customer.save": "सहेजें",
        "customer.customer_phone": "ग्राहक का फ़ोन",

        // role
        "role.role_list": "भूमिका सूची",
        "role.roles": "भूमिकाएँ",
        "role.create_role": "भूमिका बनाएं",
        "role.update_role": "भूमिका अपडेट करें",
        "role.create_success_msg": "भूमिका को सफलतापूर्वक बनाया गया है",
        "role.update_success_msg": "भूमिका को सफलतापूर्वक अपडेट किया गया है",
        "role.role_data_not_available": "भूमिका डेटा उपलब्ध नहीं है",
        "role.alias": "पर्याय",
        "role.role_level": "भूमिका स्तर",
        "role.select_role": "भूमिका का चयन करें",

        // role form
        "role_form.predefine_role_msg": "यह एक पूर्वनिर्धारित भूमिका है, इसलिए आप पर्याय को संपादित नहीं कर सकते",
        "role_form.alias_required_msg": "पर्याय आवश्यक है",
        "role_form.all": "सभी",
        "role_form.system": "सिस्टम",
        "role_form.restaurant": "रेस्तरां",
        "role_form.branch": "शाखा",
        "role_form.customer": "ग्राहक",
        "role_form.role_level_required": "भूमिका स्तर आवश्यक है",

        // product
        "product.product_list": "उत्पाद सूची",
        "product.previous_product_list": "पिछले आदेश उत्पाद सूची",
        "product.branch_product_list": "शाखा उत्पाद सूची",
        "product.create_product": "उत्पाद बनाएं",
        "product.name": "उत्पाद नाम",
        "product.create_success_msg": "उत्पाद सफलतापूर्वक बनाया गया है",
        "product.update_success_msg": "उत्पाद सफलतापूर्वक अपडेट किया गया है",
        "product.update_product": "उत्पाद अपडेट करें",
        "product.product_not_available": "उत्पाद डेटा उपलब्ध नहीं है",
        "product.type": "उत्पाद प्रकार",
        "product.select_type": "उत्पाद प्रकार चुनें",
        "product.category": "उत्पाद श्रेणी",
        "product.price": "मूल्य",
        "product.description": "विवरण",
        "product.single_type": "एकल",
        "product.combo_type": "कॉम्बो",
        "product.select_product": "उत्पाद चुनें",
        "product.enable": "सक्रिय",
        "product.quantity": "मात्रा",
        "product.discount_percent": "छूट प्रतिशत",
        "product.discount_percent_negative": "छूट प्रतिशत एक सकारात्मक संख्या होनी चाहिए",
        "product.approved_msg": "उत्पाद को सफलतापूर्वक मंजूर किया गया है",
        "product.approved_status_msg": "उत्पाद",
        "product.sold_unit": "बिकी इकाई",
        "product.combo_items": "कॉम्बो आइटम्स",
        "product.popular": "लोकप्रिय",
        "product.change_popular_status": "लोकप्रिय स्थिति बदलें",
        "product.popular_status_sure": "क्या आप वाकई लोकप्रिय स्थिति बदलना चाहते हैं?",
        "product.related_products": "संबंधित उत्पाद",
        "product.shortcut_key": "शॉर्टकट कुंजी",
        "product.product_small": "उत्पाद",

        // requested product
        "requested_product.requested_product_list": "अनुरोधित उत्पाद सूची",

        // branch request product
        "branch_request_product.assign_success_msg": "उत्पाद को सफलतापूर्वक कॉन्फ़िगर किया गया है",
        "branch_request_product.request_product": "उत्पाद का अनुरोध",
        "branch_request_product.create_product": "उत्पाद अनुरोध बनाएं",
        "branch_request_product.update_product": "उत्पाद अनुरोध अपडेट करें",
        "branch_request_product.request_success_msg": "उत्पाद अनुरोध सफलतापूर्वक बनाया गया है",
        "branch_request_product.request_update_success_msg": "उत्पाद अनुरोध सफलतापूर्वक अपडेट किया गया है",

        // product form
        "product_form.name_required": "उत्पाद नाम आवश्यक है",
        "product_form.type_required": "उत्पाद प्रकार आवश्यक है",
        "product_form.category_required": "उत्पाद श्रेणी आवश्यक है",
        "product_form.price_required": "उत्पाद मूल्य आवश्यक है",
        "product_form.duplicate_product": "आपने पहले से ही इस उत्पाद को जोड़ दिया है",
        "product_form.negative_price": "मूल्य शून्य से अधिक होना चाहिए",
        "product_form.product_sale_threshold": "अधिकतम बिक्री मात्रा",
        "product_form.product_sale_threshold_negative": "माक्सिमम बिक्री मात्रा सकारात्मक होनी चाहिए",

        // product category
        "product_category.total_category": "कुल श्रेणी",
        "product_category.category_list": "श्रेणी सूची",
        "product_category.create_category": "श्रेणी बनाएं",
        "product_category.update_category": "श्रेणी अपडेट करें",
        "product_category.create_success_msg": "श्रेणी को सफलतापूर्वक बनाया गया है",
        "product_category.update_success_msg": "श्रेणी को सफलतापूर्वक अपडेट किया गया है",
        "product_category.category_data_not_available": "श्रेणी डेटा उपलब्ध नहीं है",
        "product_category.category_name": "श्रेणी का नाम",
        "product_category.available_from": "उपलब्ध दिन से",
        "product_category.available_to": "उपलब्ध दिन तक",
        "product_category.available_days": "उपलब्ध दिन",
        "product_category.available_week_days": "उपलब्ध सप्ताह के दिन",


        // global ingredient category
        "ingredient_category.ingredient_category_list": "इंग्रेडियेंट श्रेणी सूची",
        "ingredient_category.create_ingredient_category": "इंग्रेडियेंट श्रेणी बनाएं",
        "ingredient_category.total_ingredient_category": "कुल इंग्रेडियेंट श्रेणी",
        "ingredient_category.ingredient_category_name": "इंग्रेडियेंट श्रेणी का नाम",
        "ingredient_category.description": "विवरण",

        // ingredient
        "ingredient.ingredient_list": "इंग्रेडियेंट सूची",
        "ingredient.create_ingredient": "इंग्रेडियेंट बनाएं",
        "ingredient.total_ingredient": "कुल शाखा इंग्रेडियेंट",
        "ingredient.ingredient_name": "इंग्रेडियेंट का नाम",
        "ingredient.description": "विवरण",
        "ingredient.category_name": "श्रेणी का नाम",
        "ingredient.unit": "इकाई",
        "ingredient.quantity": "मात्रा",
        "ingredient.default_price": "डिफ़ॉल्ट मूल्य",
        "ingredient.default_price_positive": "डिफ़ॉल्ट मूल्य सकारात्मक होना चाहिए",
        "ingredient.required_ingredient_name": "इंग्रेडियेंट का नाम आवश्यक है",
        "ingredient.required_unit": "इकाई आवश्यक है",
        "ingredient.update_ingredient": "इंग्रेडियेंट अपडेट करें",
        "ingredient.approved_msg": "इंग्रेडियेंट को सफलतापूर्वक मंजूर किया गया है",
        "ingredient.unApproved_msg": "इंग्रेडियेंट को सफलतापूर्वक अनमंजूर किया गया है",
        "ingredient.search_by_name": "इंग्रेडियेंट के नाम से खोजें",
        "ingredient.select_unit": "इकाई का चयन करें",
        "ingredient.price_positive": "मूल्य सकारात्मक होना चाहिए",

        // requested ingredient
        "requested_ingredient.requested_ingredient_list": "अनुरोधित इंग्रेडियेंट सूची",
        "requested_ingredient.total_requested_ingredient": "कुल अनुरोधित इंग्रेडियेंट",
        "requested_ingredient.requested_ingredient_name": "अनुरोधित इंग्रेडियेंट का नाम",
        "requested_ingredient.update_requested_ingredient": "अनुरोधित इंग्रेडियेंट अपडेट करें",
        "requested_ingredient.create_success_msg": "इंग्रेडियेंट सफलतापूर्वक बनाया गया है",

        // branch global_ingredient category
        "branch_ingredient.create_branch_ingredient": "शाखा इंग्रेडियेंट बनाएं",
        "branch_ingredient.branch_ingredient_list": "शाखा इंग्रेडियेंट सूची",
        "branch_ingredient.total_branch_ingredient": "कुल शाखा इंग्रेडियेंट",
        "branch_ingredient.branch_ingredient_name": "शाखा इंग्रेडियेंट का नाम",
        "branch_ingredient.request_branch_ingredient": "शाखा इंग्रेडियेंट का अनुरोध",
        "branch_ingredient.create_request_branch_ingredient": "शाखा इंग्रेडियेंट का अनुरोध बनाएं",
        "branch_ingredient.update_branch_ingredient": "शाखा इंग्रेडियेंट को अपडेट करें",
        "branch_ingredient.update_ingredient": "इंग्रेडियेंट को अपडेट करें",
        "branch_ingredient.quantity": "मात्रा",
        "branch_ingredient.assign_supplier": "आपूर्तिकर्ता का निर्धारण करें",
        "branch_ingredient.update_msg": "शाखा इंग्रेडियेंट को सफलतापूर्वक अपडेट किया गया है",
        "branch_request_ingredient.assign_success_msg": "शाखा इंग्रेडियेंट को सफलतापूर्वक निर्धारित किया गया है",

        //supplier
        "supplier.supplier_list": "लालटूपार यादादास्त",
        "supplier.create_supplier": "आपूर्तिकर्ता बनाएं",
        "supplier.update_supplier": "आपूर्तिकर्ता को अपडेट करें",
        "supplier.total_supplier": "कुल आपूर्तिकर्ता",
        "supplier.supplier_name": "आपूर्तिकर्ता का नाम",
        "supplier.description": "विवरण",
        "supplier.update_successfully": "आपूर्तिकर्ता सफलतापूर्वक अपडेट किया गया है",
        "supplier.supplier_name_required": "आपूर्तिकर्ता का नाम आवश्यक है",
        "supplier.supplier_data_unavailable": "आपूर्तिकर्ता डेटा नहीं मिला",
        "supplier.supplier_assigned_msg": "आपूर्तिकर्ता को सफलतापूर्वक निर्धारित किया गया है",

        //agent
        "agent.agent_list": "एजेंट सूची",
        "agent.create_agent": "एजेंट बनाएं",
        "agent.update_agent": "एजेंट को अपडेट करें",
        "agent.add_agent": "एजेंट जोड़ें",
        "agent.total_agent": "कुल एजेंट",
        "agent.agent_name": "एजेंट का नाम",
        "agent.name": "नाम",
        "agent.email": "ईमेल",
        "agent.mobile_number": "मोबाइल नंबर",
        "agent.description": "विवरण",
        "agent.address": "पता",
        "agent.agent_create_msg": "एजेंट को सफलतापूर्वक बनाया गया है",
        "agent.advance": "अग्रिम",
        "agent.payments": "भुगतान",

        // agent form
        "agent_form.name_required": "एजेंट का नाम आवश्यक है",
        "agent_form.mobile_num_required": "मोबाइल नंबर आवश्यक है",
        "agent_form.address_required": "पता आवश्यक है",
        "agent.agent_update_msg": "एजेंट को सफलतापूर्वक अपडेट किया गया है",

        // agent payment
        "agent_payment.cancel_msg": "एजेंट भुगतान को सफलतापूर्वक रद्द किया गया है",
        "agent_payment.payment_category": "भुगतान श्रेणी",
        "agent_payment.receive_amount": "राशि प्राप्त करें",
        "agent_payment.cancel_payment": "एजेंट भुगतान को रद्द करें",
        "agent_payment.cancel_confirm_msg": "क्या आप वाकई एजेंट भुगतान को रद्द करना चाहते हैं",

        // agent payment form
        "agent_payment_form.amount": "राशि",
        "agent_payment_form.amount_required": "राशि आवश्यक है",
        "agent_payment_form.amount_positive": "राशि 0 से अधिक होनी चाहिए",
        "agent_payment_form.payment_category_required": "भुगतान श्रेणी आवश्यक है",
        "agent_payment_form.received": "प्राप्त हुआ",
        "agent_payment_form.payment_type_required": "भुगतान प्रकार आवश्यक है",
        "agent_payment_form.cash": "नकद",
        "agent_payment_form.mfs": "मोबाइल वित्त सेवा",
        "agent_payment_form.payment_create_msg": "एजेंट भुगतान को सफलतापूर्वक बनाया गया है",
        "agent_payment_form.payment_update_msg": "एजेंट भुगतान को सफलतापूर्वक अपडेट किया गया है",
        "agent_payment_form.payment_unavailable": "भुगतान डेटा उपलब्ध नहीं है",

        //branch supplier
        "supplier.assign_ingredient": "सामग्री का निर्धारण करें",
        "supplier.assign": "निर्धारित करें",
        "supplier.ingredient_required": "सामग्री आवश्यक है",
        "supplier.supplier_created_successfully": "आपूर्तिकर्ता सफलतापूर्वक बनाया गया है",

        // product category form
        "product_category_form.required_category_name": "श्रेणी का नाम आवश्यक है",

        // add-ons
        "product_add_ons.total_add_on": "कुल अद्यतन",
        "product_add_ons.add_ons_list": "अद्यतन सूची",
        "product_add_ons.update_add_ons": "अद्यतन अद्यतन",
        "product_add_ons.branch_add_ons_list": "शाखा अद्यतन सूची",
        "product_add_ons.create_add_ons": "अद्यतन बनाएं",
        "product_add_ons.create_success_msg": "अद्यतन सफलतापूर्वक बनाया गया है",
        "product_add_ons.update_success_msg": "अद्यतन सफलतापूर्वक किया गया है",
        "product_add_ons.add_ons_not_available": "अद्यतन डेटा उपलब्ध नहीं है",
        "product_add_ons.add_ons_name": "अद्यतन नाम",
        "product_add_ons.select_add_ons": "अद्यतन चुनें",
        "product_add_ons.duplicate_add_ons": "यह अद्यतन पहले से ही चुना गया है",
        "product_add_ons.approve_msg": "अद्यतन को सफलतापूर्वक मंजूर कर दिया गया है",
        "product_add_ons.approve_status_msg": "अद्यतन की मंजूरी स्थिति को सफलतापूर्वक अपडेट किया गया है",

        // add-ons form
        "product_add_ons_form.add_ons_name_required": "अद्यतन का नाम आवश्यक है",
        "product_add_ons_form.add_ons_price_negative": "अद्यतन की कीमत एक सकारात्मक संख्या होनी चाहिए",

        // request add-ons
        "request_aad_ons.request_add_ons": "अद्यतन का अनुरोध",
        "request_aad_ons.create_success_msg": "अद्यतन का अनुरोध सफलतापूर्वक बनाया गया है",
        "request_aad_ons.update_success_msg": "अद्यतन का अनुरोध सफलतापूर्वक अपडेट किया गया है",
        "request_aad_ons.create_request_add_ons": "अद्यतन का अनुरोध बनाएं",
        "request_aad_ons.update_request_add_ons": "अद्यतन का अनुरोध अपडेट करें",

        // branch
        "branch.branch_list": "शाखा सूची",
        "branch.branch": "शाखा",
        "branch.create_branch": "शाखा बनाएं",
        "branch.create_success_msg": "शाखा सफलतापूर्वक बनाई गई है",
        "branch.branch_name": "शाखा का नाम",
        "branch.update_success_msg": "शाखा सफलतापूर्वक अपडेट की गई है",
        "branch.update_branch": "शाखा अद्यतन करें",
        "branch.branch_data_not_available": "शाखा डेटा उपलब्ध नहीं है",
        "branch.select_branch": "शाखा चुनें",
        "branch.branch_details": "शाखा विवरण",
        "branch.employee": "कर्मचारी",
        "branch.total_branch": "कुल शाखा",

        // branch form
        "branch_form.branch_name_required": "शाखा का नाम आवश्यक है",
        "branch_form.select_restaurant": "रेस्तरां का चयन करें",
        "branch_form.restaurant_required": "रेस्तरां आवश्यक है",
        "branch_form.enabled": "सक्रिय",
        "branch_form.delivery_radius": "डिलीवरी क्षेत्र अस्थायी अंतर (मील)",
        "branch_form.delivery_radius_required": "डिलीवरी क्षेत्र अस्थायी है",
        "branch_form.branch_location": "शाखा स्थान",
        "branch_form.branch_location_required": "शाखा स्थान आवश्यक है",
        "branch_form.order_types": "आदेश के प्रकार",
        "branch_form.opening_time": "खुलने का समय",
        "branch_form.closing_time": "बंद होने का समय",
        "branch_form.deliver_radius_negative": "डिलीवरी क्षेत्र अस्थायी होना चाहिए",

        // franchise form
        "franchise.franchise_list": "फ्रैंचाइज़ सूची",
        "franchise.franchise": "फ्रैंचाइज़",
        "franchise.create_franchise": "फ्रैंचाइज़ बनाएं",
        "franchise.create_success_msg": "फ्रैंचाइज़ सफलतापूर्वक बनाई गई है",
        "franchise.franchise_name": "फ्रैंचाइज़ का नाम",
        "franchise.update_success_msg": "फ्रैंचाइज़ को सफलतापूर्वक अपडेट किया गया है",
        "franchise.update_franchise": "फ्रैंचाइज़ अपडेट करें",
        "franchise.franchise_data_not_available": "फ्रैंचाइज़ डेटा उपलब्ध नहीं है",
        "franchise.select_franchise": "फ्रैंचाइज़ का चयन करें",
        "franchise.franchise_details": "फ्रैंचाइज़ विवरण",
        "franchise.employee": "कर्मचारी",
        "franchise.total_franchise": "कुल फ्रैंचाइज़",

        // franchise form
        "franchise_form.franchise_name_required": "फ्रैंचाइज़ का नाम आवश्यक है",
        "franchise_form.select_restaurant": "रेस्तरां का चयन करें",
        "franchise_form.restaurant_required": "रेस्तरां आवश्यक है",
        "franchise_form.enabled": "सक्रिय",
        "franchise_form.delivery_radius": "वितरण क्षेत्र त्रिज्या (मील)",
        "franchise_form.delivery_radius_required": "वितरण क्षेत्र त्रिज्या आवश्यक है",
        "franchise_form.franchise_location": "फ्रैंचाइज़ स्थान",
        "franchise_form.franchise_location_required": "फ्रैंचाइज़ स्थान आवश्यक है",
        "franchise_form.order_types": "आदेश प्रकार",
        "franchise_form.opening_time": "खुलने का समय",
        "franchise_form.closing_time": "बंद करने का समय",
        "franchise_form.deliver_radius_negative": "वितरण क्षेत्र त्रिज्या शून्य से अधिक होना चाहिए",
        "franchise_form.franchise": "फ्रैंचाइज़",

        // assign employee
        "assign_employee.select_employee": "कर्मचारी चुनें",
        "assign_employee.success_msg": "कर्मचारी को चयनित शाखा में सफलतापूर्वक नियुक्त किया गया है",
        "assign_employee.employee_required": "कर्मचारी की आवश्यकता है",
        "assign_employee.branch_required": "शाखा की आवश्यकता है",

        // order
        "order.order_list": "आदेश सूची",
        "order.order_number": "आदेश संख्या",
        "order.customer_name": "ग्राहक का नाम",
        "order.status": "स्थिति",
        "order.order_type": "आदेश प्रकार",
        "order.payment_type": "भुगतान प्रकार",
        "order.paid": "भुगतान हो चुका है",
        "order.create_order": "आदेश बनाएं",
        "order.update_order": "आदेश अपडेट करें",
        "order.order_details": "आदेश विवरण",
        "order.order_ifo": "आदेश जानकारी",
        "order.customer_ifo": "ग्राहक जानकारी",
        "order.invoice": "चालान",
        "order.customer_id": "ग्राहक आईडी",
        "order.total_price": "कुल मूल्य",
        "order.total_product_price": "कुल उत्पाद मूल्य",
        "order.total_add_on_price": "कुल अद्यतन मूल्य",
        "order.total_product": "कुल उत्पाद",
        "order.total_add_on": "कुल अद्यतन",
        "order.promo_discount": "प्रोमो डिस्काउंट",
        "order.point_amount": "बिंदु मात्रा",
        "order.final_price": "अंतिम मूल्य",
        "order.not_available": "आदेश डेटा उपलब्ध नहीं है",
        "order.total_order": "कुल आदेश",
        "order.order_type_required": "आदेश प्रकार आवश्यक है",
        "order.select_table": "टेबल का चयन करें",
        "order.table_required": "टेबल की आवश्यकता है",
        "order.discount_type": "छूट प्रकार",
        "order.discount": "छूट",
        "order.comment": "टिप्पणी",
        "order.comment_required": "टिप्पणी आवश्यक है",
        "order.remarks": "टिप्पणियाँ",
        "order.guest_numbers": "अतिथि संख्या",
        "order.event_date": "घटना की तारीख",
        "order.total_payable_amount": "कुल भुगतान की जाने वाली राशि",
        "order.invoice_number": "चालान संख्या",
        "order.guests": "अतिथि",
        "order.generate_date": "तैयार तिथि",
        "order.generate_time": "तैयार समय",
        "order.generate_by": "तैयारकर्ता",
        "order.change": "परिवर्तन",
        "order.thank_order": "आपके आदेश के लिए धन्यवाद",
        "order.visit": "दौरा",
        "order.to_online_order": "ऑनलाइन आदेश के लिए",
        "order.phone_no": "फ़ोन नंबर",

        // branch product
        "branch_product.availability_msg": "शाखा उत्पाद की उपलब्धता को सफलतापूर्वक अपडेट किया गया है",
        "branch_product.update_msg": "शाखा उत्पाद को सफलतापूर्वक अपडेट किया गया है",
        "branch_product.related_product_success_msg": "संबंधित उत्पाद सफलतापूर्वक जोड़ा गया है",
        "branch_product.product_has_been": "उत्पाद को",
        "branch_product.successfully": "सफलतापूर्वक",
        "branch_product.enabled_small": "सक्रिय किया गया",
        "branch_product.disabled_small": "निष्क्रिय किया गया",

        // promo
        "promo.total_promo": "कुल प्रोमो",
        "promo.promo_code": "प्रोमो कोड",
        "promo.promo_list": "प्रोमो सूची",
        "promo.create_promo": "प्रोमो बनाएं",
        "promo.update_promo": "प्रोमो अपडेट करें",
        "prom.create_success_msg": "प्रोमो को सफलतापूर्वक बनाया गया है",
        "prom.update_success_msg": "प्रोमो को सफलतापूर्वक अपडेट किया गया है",
        "prom.data_not_available": "प्रोमो डेटा उपलब्ध नहीं है",
        "prom.select_promo": "प्रोमो का चयन करें",

        // promo form
        "promo_form.promo_code_required": "प्रोमो कोड की आवश्यकता है",
        "promo_form.valid_from": "प्रभावी दिनांक",
        "promo_form.valid_to": "प्रभावी से",
        "promo_form.valid_from_required": "मान्य दिनांक की आवश्यकता है",
        "promo_form.valid_to_required": "मान्य तक की आवश्यकता है",
        "promo_form.price_type": "मूल्य प्रकार",
        "promo_form.price_type_required": "मूल्य प्रकार आवश्यक है",
        "promo_form.flat_amount": "सीधी राशि",
        "promo_form.flat_amount_required": "सीधी राशि की आवश्यकता है",
        "promo_form.percent": "प्रतिशत",
        "promo_form.percent_required": "प्रतिशत की आवश्यकता है",
        "promo_form.applicable_percent_amount": "लागू प्रतिशत राशि",
        "promo_form.applicable_order_amount": "लागू आदेश राशि",
        "promo_form.flat_amount_negative": "सीधी राशि शून्य से अधिक होनी चाहिए",
        "promo_form.percent_negative": "प्रतिशत शून्य से अधिक होना चाहिए",
        "promo_form.percent_max_amount_negative": "प्रतिशत अधिकतम राशि एक सकारात्मक संख्या होनी चाहिए",
        "promo_form.min_order_amount_negative": "न्यूनतम आदेश राशि एक सकारात्मक संख्या होनी चाहिए",
        "promo_form.desc": "विवरण",

        // order type
        "order_type.delivery": "होम डिलीवरी",
        "order_type.takeaway": "उठा लेना",
        "order_type.dine_in": "डाइन इन",
        "order_type.catering": "केटरिंग",

        // global config
        "global_config.global_config_list": "वैश्विक समाकृति सूची",
        "global_config.create_global_config": "वैश्विक समाकृति बनाएं",
        "global_config.update_global_config": "वैश्विक समाकृति अपडेट करें",
        "global_config.create_msg": "वैश्विक समाकृति को सफलतापूर्वक बनाया गया है",
        "global_config.update_msg": "वैश्विक समाकृति को सफलतापूर्वक अपडेट किया गया है",
        "global_config.data_not_available": "वैश्विक समाकृति डेटा उपलब्ध नहीं है",
        "global_config.activate_confirm_title": "सक्रिय करें वैश्विक समाकृति",
        "global_config.disable_confirm_title": "वैश्विक समाकृति को निष्क्रिय करें",
        "global_config.activate_confirm_desc": "क्या आप वैश्विक समाकृति को सक्रिय करना चाहते हैं?",
        "global_config.disable_confirm_desc": "क्या आप वैश्विक समाकृति को निष्क्रिय करना चाहते हैं?",
        "global_config.active_success_msg": "वैश्विक समाकृति को सफलतापूर्वक सक्रिय किया गया है",
        "global_config.disable_success_msg": "वैश्विक समाकृति को सफलतापूर्वक निष्क्रिय किया गया है",

        // global_config_form
        "global_config_form.order_point": "प्रति आदेश अंक",
        "global_config_form.value_per_point": "प्रति अंक मूल्य",
        "global_config_form.applicable_max_point_value": "लागू अधिकतम अंक मूल्य",
        "global_config_form.branch_search_area": "शाखा खोज क्षेत्र (मील)",
        "global_config_form.branch_search_area_negative": "शाखा खोज क्षेत्र को सकारात्मक संख्या होनी चाहिए",
        "global_config_form.branch_search_area_column": "शाखा खोज क्षेत्र",
        "global_config_form.value_per_point_negative": "प्रति अंक मूल्य को सकारात्मक संख्या होनी चाहिए",
        "global_config_form.applicable_max_point_value_negative": "लागू अधिकतम अंक मूल्य को सकारात्मक संख्या होनी चाहिए",
        "global_config_form.currency": "मुद्रा",
        "global_config_form.currency_required": "मुद्रा आवश्यक है",
        "global_config_form.select_currency": "मुद्रा का चयन करें",
        "global_config_form.order_cancel_feature": "आदेश रद्द सुविधा",
        "global_config_form.cancel_count_per_day": "प्रतिदिन अधिकतम रद्द करने की गणना",
        "global_config_form.cancel_count_per_day_negative": "प्रतिदिन अधिकतम रद्द करने की गणना को सकारात्मक संख्या होनी चाहिए",

        // banner
        "banner.banner_list": "बैनर सूची",
        "banner.create_banner": "बैनर बनाएं",
        "banner.update_banner": "बैनर अपडेट करें",
        "banner.total_banner": "कुल बैनर",
        "banner.banner_type": "बैनर प्रकार",
        "banner.banner_title": "बैनर शीर्षक",
        "banner.banner_create_success": "बैनर को सफलतापूर्वक बनाया गया है",
        "banner.banner_update_success": "बैनर को सफलतापूर्वक अपडेट किया गया है",
        "banner.banner_data_not_available": "बैनर डेटा उपलब्ध नहीं है",
        "banner.sequence": "स्थिति",

        // banner form
        "banner_form.title_required": "बैनर शीर्षक आवश्यक है",
        "banner_form.banner_type_required": "बैनर प्रकार आवश्यक है",
        "banner_form.promo_required": "प्रोमो आवश्यक है",

        // restaurant dashboard
        "restaurant_dashboard.total_earning": "कुल कमाई",
        "restaurant_dashboard.total_earning_not_available": "कुल कमाई डेटा उपलब्ध नहीं है",
        "restaurant_dashboard.top_branch": "शीर्ष शाखा",
        "restaurant_dashboard.earning_by_branch": "शाखा द्वारा कमाई",
        "restaurant_dashboard.earning_by_branch_not_available": "शाखा द्वारा कमाई डेटा उपलब्ध नहीं है",
        "restaurant_dashboard.total_order": "कुल आदेश",
        "restaurant_dashboard.total_order_not_available": "कुल आदेश डेटा उपलब्ध नहीं है",
        "restaurant_dashboard.order_by_branch": "शाखा द्वारा आदेश",
        "restaurant_dashboard.order_by_branch_not_available": "शाखा द्वारा आदेश डेटा उपलब्ध नहीं है",
        "restaurant_dashboard.top_section_not_available": "कुल सारांश डेटा उपलब्ध नहीं है",

        // branch dashboard
        "branch_dashboard.order_summary": "आदेश सारांश",
        "branch_dashboard.completed_order": "पूर्ण आदेश",
        "branch_dashboard.ongoing_order": "जारी आदेश",
        "branch_dashboard.cancel_order": "रद्द आदेश",
        "branch_dashboard.completed": "पूर्ण",
        "branch_dashboard.ongoing": "जारी है",
        "branch_dashboard.canceled": "रद्द",
        "branch_dashboard.order_type_data_not_avl": "आदेश प्रकार डेटा उपलब्ध नहीं है",
        "branch_dashboard.order_summary_data_not_avl": "आदेश सारांश डेटा उपलब्ध नहीं है",
        "branch_dashboard.top_products": "शीर्ष उत्पाद",
        "branch_dashboard.top_product_not_avl": "शीर्ष उत्पाद डेटा उपलब्ध नहीं है",
        "branch_dashboard.order": "आदेश",
        "branch_dashboard.recent_order_reviews": "हाल के आदेश समीक्षाएँ",
        "branch_dashboard.recent_order_reviews_not_avl": "हाल की आदेश समीक्षा डेटा उपलब्ध नहीं है",
        "branch_dashboard.order_id": "आदेश आईडी",
        "branch_dashboard.reviewed_branch": "समीक्षित शाखा",
        "branch_dashboard.data_time": "तिथि और समय",
        "branch_dashboard.reviewed_by": "समीक्षित करने वाला",
        "branch_dashboard.message": "संदेश",
        "branch_dashboard.rating": "रेटिंग",
        "branch_dashboard.recent_product_reviews": "हाल की उत्पाद समीक्षाएँ",
        "branch_dashboard.recent_product_reviews_not_avl": "हाल की उत्पाद समीक्षा डेटा उपलब्ध नहीं है",
        "branch_dashboard.reviewed_product": "समीक्षित उत्पाद",
        "branch_dashboard.product_review": "उत्पाद समीक्षा",
        "branch_dashboard.order_review": "आदेश समीक्षा",

        // analytics
        "analytics.top_branches": "शीर्ष शाखाएँ",
        "analytics.top_branch_not_avl": "शीर्ष शाखा डेटा उपलब्ध नहीं है",

        // table
        "table.table_list": "टेबल सूची",
        "table.table_data_not_available": "टेबल डेटा उपलब्ध नहीं है",
        "table.create_table": "टेबल बनाएं",
        "table.update_table": "टेबल अपडेट करें",
        "table.create_success_msg": "टेबल को सफलतापूर्वक बनाया गया है",
        "table.update_success_msg": "टेबल को सफलतापूर्वक अपडेट किया गया है",
        "table_form.name_required": "टेबल संख्या आवश्यक है",
        "table_form.table_name": "टेबल नाम",
        "table_form.description": "विवरण",
        "table_form.max_person": "अधिकतम व्यक्ति",
        "table_form.max_person_btwn": "अधिकतम व्यक्ति 1 से 100 के बीच होना चाहिए",
        "table_form.max_person_required": "अधिकतम व्यक्ति आवश्यक है",

        // catering_section
        "catering_section.catering_section_list": "केटरिंग खंड सूची",
        "catering_section.create_catering_section": "केटरिंग खंड बनाएं",
        "catering_section.catering_section_name": "खंड का नाम",
        "catering_section.catering_section_name_required": "केटरिंग खंड का नाम आवश्यक है",
        "catering_section.note": "ध्यान दें",
        "catering_section.create_success_msg": "केटरिंग खंड को सफलतापूर्वक बनाया गया है",
        "catering_section.update_success_msg": "केटरिंग खंड को सफलतापूर्वक अपडेट किया गया है",
        "catering_section.data_not_available": "केटरिंग खंड डेटा उपलब्ध नहीं है",
        "catering_section.update_catering_section": "केटरिंग खंड अपडेट करें",
        "catering_section.catering_section": "केटरिंग खंड",

        // catering product
        "catering.request_catering_product": "केटरिंग उत्पाद का अनुरोध करें",
        "catering.product_list": "केटरिंग उत्पाद सूची",
        "catering.create_product": "केटरिंग उत्पाद बनाएं",
        "catering.update_product": "उत्पाद केटरिंग को अपडेट करें",
        "catering.create_product_success_msg": "केटरिंग उत्पाद को सफलतापूर्वक बनाया गया है",
        "catering.update_product_success_msg": "केटरिंग उत्पाद को सफलतापूर्वक अपडेट किया गया है",

        // catering menu
        "catering.catering_menu_list": "केटरिंग मेनू सूची",
        "catering.create_catering_menu": "केटरिंग मेनू बनाएं",
        "catering.update_catering_menu": "केटरिंग मेनू अपडेट करें",
        "catering.catering_menu_name": "केटरिंग मेनू नाम",
        "catering.catering_menu_name_required": "केटरिंग मेनू का नाम आवश्यक है",
        "catering.catering_menu_details": "केटरिंग मेनू विवरण",
        "catering.branch_menu_assigned": "शाखा मेनू को सफलतापूर्वक असाइन किया गया है",
        "catering.request_catering_menu": "केटरिंग मेनू का अनुरोध करें",
        "catering.menu_name": "मेनू नाम",
        "catering.add_at_least_one_item": "कृपया कम से कम एक आइटम जोड़ें",
        "catering.duplicate": "प्रतिलिपि",
        "catering.item_already_added": "यह आइटम पहले ही जोड़ दिया गया है",
        "catering.add_alternative": "वैकल्पिक जोड़ें",
        "catering.empty_plus": "खाली प्लस",
        "catering.item_can_selected": "आइटम को चुना जा सकता है",
        "catering.please_select_product": "कृपया उत्पाद का चयन करें",
        "catering.choice_of_item": "आइटम की चुनौती",
        "catering.choice_of_item_required": "आइटम की चुनौती आवश्यक है",
        "catering.at_least_choice_of_item": "कम से कम 1 आइटम का चयन करना चाहिए",
        "catering.menu_data_unavailable": "केटरिंग मेनू डेटा उपलब्ध नहीं है",
        "catering.you_have_to_select": "आपको कम से कम चयन करना होगा",
        "catering.because_choice": "क्योंकि आपकी आइटम की चुनौती है",
        "catering.catering_menu_create_msg": "केटरिंग मेनू को सफलतापूर्वक बनाया गया है",
        "catering.catering_menu_update_msg": "केटरिंग मेनू को सफलतापूर्वक अपडेट किया गया है",
        "catering.catering_menu_has_been": "केटरिंग मेनू को",
        "catering.the_catering_menu": "केटरिंग मेनू",

        // catering order
        "catering.catering_order_list": "केटरिंग आदेश सूची",
        "catering.create_catering_order": "केटरिंग आदेश बनाएं",
        "catering.update_catering_order": "केटरिंग आदेश अपडेट करें",
        "catering.billed_to": "बिल किया",
        "catering.invoice_no": "चालान संख्या",
        "catering.no_of_guests": "मेहमानों की संख्या",
        "catering.including_tax": "टैक्स सहित",
        "catering.invoice_total": "चालान कुल",
        "catering.event_details": "घटना विवरण",
        "catering.signature": "हस्ताक्षर",
        "catering.client_signature": "ग्राहक हस्ताक्षर",
        "catering.thank_you_for_coming": "हमारे रेस्तरां में आने के लिए धन्यवाद",

        // catering order form
        "catering_order_form.included": "शामिल",
        "catering_order_form.additional": "अतिरिक्त",
        "catering_order_form.current_order": "वर्तमान आदेश",
        "catering_order_form.all_catering_menu": "सभी केटरिंग मेनू",
        "catering_order_form.enter_guest": "मेहमान दर्ज करें",
        "catering_order_form.select_catering_menu": "केटरिंग मेनू चुनें",
        "catering_order_form.menu_section": "मेनू खंड",
        "catering_order_form.select_menu": "मेनू चुनें",
        "catering_order_form.confirm": "पुष्टि करें",
        "catering_order_form.alternative_items": "वैकल्पिक आइटम",
        "catering_order_form.choose": "चुनें",
        "catering_order_form.item_here": "आइटम यहाँ",
        "catering_order_form.selected_items": "चयनित आइटम",
        "catering_order_form.selected": "चयनित",
        "catering_order_form.select_category": "श्रेणी चुनें",
        "catering_order_form.search_product_name": "उत्पाद नाम खोजें",
        "catering_order_form.choice_of": "विकल्प का",
        "catering_order_form.percentage": "प्रतिशत",
        "catering_order_form.flat": "फ्लैट",
        "catering_order_form.note_optional": "नोट (वैकल्पिक)",
        "catering_order_form.select_event_date": "घटना दिनांक चुनें",
        "catering_order_form.event_location": "घटना स्थान",
        "catering_order_form.ny_sales_tax": "बिक्री कर",
        "catering_order_form.event_date_required": "घटना दिनांक आवश्यक है, कृपया अपनी घटना दिनांक का चयन करें",
        "catering_order_form.customer_required": "ग्राहक आवश्यक है, कृपया ग्राहक का चयन करें",
        "catering_order_form.least_one_menu": "कृपया कम से कम एक मेनू जोड़ें",
        "catering_order_form.catering_invoice": "केटरिंग चालान",

        // all
        "product.catering_type": "केटरिंग",

        // ready_product_stock
        "stock.stock_list": "स्टॉक सूची",
        "stock.stock_small": "स्टॉक",
        "stock.create_stock": "बनाएं",
        "stock.update_stock": "अपडेट करें",
        "stock.total_stock": "कुल स्टॉक",
        "stock.stock_number": "स्टॉक नंबर",
        "stock.stock_details": "स्टॉक विवरण",
        "stock.paid_amount": "भुगतान राशि",
        "stock.stock_type": "स्टॉक प्रकार",
        "stock.stock_data_unavailable": "स्टॉक डेटा उपलब्ध नहीं है",
        "stock.stock_create_msg": "स्टॉक सफलतापूर्वक बनाया गया है",
        "stock.stock_update_msg": "स्टॉक सफलतापूर्वक अपडेट किया गया है",
        "stock.expired_at": "समय समाप्त",
        "stock.expired_date": "समय समाप्ति दिनांक",
        "stock.available_qty": "उपलब्ध मात्रा",

        // ready_product_stock details
        "stock_details.ingredient_info": "सामग्री की जानकारी",
        "stock_details.stock_info": "स्टॉक की जानकारी",
        "stock_details.agent_info": "एजेंट की जानकारी",
        "stock_details.balance": "शेष",
        "stock_details.total_spent": "कुल खर्च",

        // ready_product_stock in form
        "stock_in_form.one_item_required": "कृपया कम से कम एक आइटम चुनें",
        "stock_in_form.mfg_date": "निर्माण तिथि",
        "stock_in_form.expire_date": "समय समाप्ति दिनांक",
        "stock_in_form.select_supplier": "प्रदायक का चयन करें",
        "stock_in_form.select_ingredient": "सामग्री का चयन करें",
        "stock_in_form.ingredient_required": "सामग्री की आवश्यकता है",
        "stock_in_form.unit_price": "यूनिट मूल्य",
        "stock_in_form.unit_price_max_zero": "यूनिट मूल्य शून्य या उससे अधिक होना चाहिए",
        "stock_in_form.qty_max_one": "मात्रा एक या उससे अधिक होनी चाहिए",
        "stock_in_form.due_amount": "बकाया राशि",
        "stock_in_form.voucher_number": "वाउचर नंबर",
        "stock_in_form.attachment": "अनुलग्नक",
        "stock_in_form.stock_date": "स्टॉक तिथि",
        "stock_in_form.select_agent": "एजेंट का चयन करें",
        "stock_in_form.select_date": "तिथि का चयन करें",
        "stock_in_form.threshold": "सीमांत",
        "stock_in_form.threshold_max_one": "सीमांत एक सकारात्मक संख्या होनी चाहिए",

        // ready_product_stock summary
        "stock_summary.stock_summary_list": "स्टॉक सारांश सूची",
        "stock_summary.stock_summary_details": "स्टॉक सारांश विवरण",
        "stock_summary.total_stock_item": "कुल स्टॉक आइटम",
        "stock_summary.total_stock_summary": "कुल स्टॉक सारांश",
        "stock_summary.available": "उपलब्ध",
        "stock_summary.unavailable": "अनुपलब्ध",
        "stock_summary.select_stock_availability": "स्टॉक उपलब्धता का चयन करें",
        "stock_summary.stock_summary_download": "स्टॉक सारांश डाउनलोड",

        // ready_product_stock out
        "stock_out.stock_out_list": "स्टॉक आउट सूची",
        "stock_out.total_stock_out": "कुल स्टॉक आउट",
        "stock_out.stock_out_details": "स्टॉक आउट विवरण",
        "stock.stock_out_msg": "स्टॉक सफलतापूर्वक बाहर निकाला गया है",
        "stock.stock_out_update_msg": "स्टॉक को सफलतापूर्वक अपडेट किया गया है",
        "stock.out_of_stock": "स्टॉक समाप्त",
        "stock.stock_out_small": "स्टॉक आउट",

        // ready_product_stock out form
        "stock_out_form.select_stock": "स्टॉक का चयन करें",
        "stock_out_form.stock_required": "स्टॉक आवश्यक है",
        "stock_out_form.qty_required": "मात्रा आवश्यक है",
        "stock_out_form.qty_between": "मात्रा 1 और के बीच होनी चाहिए",
        "stock_out_form.stock_type_required": "स्टॉक प्रकार आवश्यक है",
        "stock_out_form.missing": "गायब",
        "stock_out_form.out": "बाहर",
        "stock_out_form.spoilage": "अपशिष्ट",
        "stock_out_form.wastage": "अपव्यय",

        // ready_product_stock summary (repeated)
        "stock_summary.create_date_range": "निर्मित तिथि सीमा",
        "stock_summary.stock_date_range": "स्टॉक तिथि सीमा",
        "stock_summary.expire_date_range": "समय समाप्ति दिनांक सीमा",

        // branch config
        "branch_config.branch_config_list": "शाखा कॉन्फ़िग सूची",
        "branch_config.create_branch_config": "शाखा कॉन्फ़िग बनाएं",
        "branch_config.update_branch_config": "शाखा कॉन्फ़िग अपडेट करें",
        "branch_config.create_msg": "शाखा कॉन्फ़िग सफलतापूर्वक बनाई गई है",
        "branch_config.update_msg": "शाखा कॉन्फ़िग सफलतापूर्वक अपडेट की गई है",
        "branch_config.data_not_available": "शाखा कॉन्फ़िग डेटा उपलब्ध नहीं है",
        "branch_config.activate_confirm_title": "सक्रिय शाखा कॉन्फ़िग",
        "branch_config.disable_confirm_title": "शाखा कॉन्फ़िग निष्क्रिय करें",
        "branch_config.activate_confirm_desc": "क्या आप वाकई शाखा कॉन्फ़िग को सक्रिय करना चाहते हैं?",
        "branch_config.disable_confirm_desc": "क्या आप वाकई शाखा कॉन्फ़िग को निष्क्रिय करना चाहते हैं?",
        "branch_config.active_success_msg": "शाखा कॉन्फ़िग सफलतापूर्वक सक्रिय किया गया है",
        "branch_config.disable_success_msg": "शाखा कॉन्फ़िग सफलतापूर्वक निष्क्रिय किया गया है",
        "branch_config.mx_cancel_count": "अधिकतम रद्दीकरण गणना / दिन",
        "branch_config.order_cancel_time": "आदेश रद्दीकरण समय (मिनट)",
        "branch_config.tax_percentage": "कर प्रतिशत",
        "branch_config.order_cancel_time_required": "आदेश रद्दीकरण का समय आवश्यक है",
        "branch_config.order_cancel_time_least": "मिनट में आदेश रद्दीकरण समय कम से कम 5 होना चाहिए",
        "branch_config.tax_percentage_greater": "कर प्रतिशत 0 से अधिक होना चाहिए",
        "branch_config.notification_sound": "रिंगटोन ध्वनि",
        "branch_config.notification_sound_repeat": "रिंगटोन साउंड दोहराना",
        "branch_config.stock_auto_reduce": "स्टॉक ऑटो हटाओ ",

        // agent payment
        "agent_payment.total_agent_payment": "कुल एजेंट भुगतान",
        "agent_payment.agent_payment_list": "एजेंट भुगतान सूची",
        "agent_payment.create_agent_payment": "एजेंट भुगतान बनाएं",
        "agent_payment.update_agent_payment": "एजेंट भुगतान अपडेट करें",

        // expired ready_product_stock
        "expire_stock.expired_stock_list": "समाप्त स्टॉक सूची",

        // pos left menu
        "pos_left_menu.all_items": "सभी आइटम",

        // pos middle content
        "pos_middle_content.its_all_msg": "यह सब है, और कुछ नहीं",
        "pos_middle_content.out_of_stock": "स्टॉक समाप्त",
        "pos_middle_content.search_place_holder": "नाम या शॉर्टकट द्वारा आइटम खोजें",
        "pos_middle_content.dine_in": "डाइन-इन",

        // pos cart
        "pos_cart.cart": "कार्ट",
        "pos_cart.payment": "भुगतान",
        "pos_cart.clear_cart": "कार्ट साफ़ करें",
        "pos_cart.total_items": "कुल आइटम",
        "pos_cart.sub_total": "कुल",
        "pos_cart.sale_tax": "बिक्री कर",
        "pos_cart.hold": "होल्ड",
        "pos_cart.save_print_kot": "सहेजें और प्रिंट करें KOT",
        "pos_cart.save_print_bill": "सहेजें और बिल प्रिंट करें",
        "pos_cart.item_name": "आइटम का नाम",
        "pos_cart.delete": "हटाएं",
        "pos_cart.qty": "मात्रा",
        "pos_cart.table_duplicate_add_msg": "तालिका पहले से ही जोड़ दिया गया है",
        "pos_cart.table_no": "तालिका संख्या",
        "pos_cart.order_note": "आदेश नोट",
        "pos_cart.order_instruction_place_holder": "कोई आदेश निर्देश जैसे सॉस, मसाले इत्यादि।",
        "pos_cart.select_customer": "ग्राहक का चयन करें",
        "pos_cart.new_customer": "नया ग्राहक",
        "pos_cart.no_of_guest": "मेहमानों की संख्या",
        "pos_cart.table_number": "टेबल संख्या",
        "pos_cart.paid_amount_small": "भुगतान की गई राशि",
        "pos_cart.tables": "टेबल्स",
        "pos_cart.online": "ऑनलाइन",
        "pos_cart.tips": "टिप्स",
        "pos_cart.table_already_added": "यह टेबल पहले से ही जोड़ दी गई है",
        "pos_cart.select_delivery_address": "डिलीवरी का पता चुनें",
        "pos_cart.address_title": "पता शीर्षक",
        "pos_cart.address_title_placeholder": "पता शीर्षक जैसे कार्यालय",
        "pos_cart.select_branch_location": "अपनी शाखा स्थान का चयन करें",

        // pos order list
        "pos_order_list.priority_updated": "प्राथमिकता अपडेट की गई",
        "pos_order_list.successfully": "सफलतापूर्वक",
        "pos_order_list.priority_has_been": "प्राथमिकता को",
        "pos_order_list.set": "सेट",
        "pos_order_list.canceled_small": "रद्द",
        "pos_order_list.order_cancel_msg": "आदेश को सफलतापूर्वक रद्द कर दिया गया है",
        "pos_order_list.update_status": "स्थिति अपडेट करें",
        "pos_order_list.payment_status": "भुगतान स्थिति",
        "pos_order_list.table_view": "टेबल दृश्य",
        "pos_order_list.hold_list": "होल्ड सूची",
        "pos_order_list.cancel_priority": "प्राथमिकता रद्द करें",
        "pos_order_list.set_priority": "प्राथमिकता सेट करें",
        "pos_order_list.print_kot": "KOT प्रिंट करें",
        "pos_order_list.print_bill": "बिल प्रिंट करें",
        "pos_order_list.cancel_order": "आदेश रद्द करें",
        "pos_order_list.edit_order": "आदेश संपादित करें",
        "pos_order_list.order_edit_confirm_msg": "क्या आप वाकई इस आदेश को संपादित करना चाहते हैं",
        "pos_order_list.order_clear_msg": "आपका पिछला आदेश हटा दिया जाएगा",
        "pos_order_list.order_date_time": "आदेश तारीख समय",
        "pos_order_list.unpaid": "अवैतनिक",
        "pos_order_list.no_guest": "मेहमानों की संख्या",
        "pos_order_list.order_status": "आदेश स्थिति",
        "pos_order_list.customer_details": "ग्राहक विवरण",
        "pos_order_list.delivery_address": "डिलीवरी का पता",
        "pos_order_list.payment_details": "भुगतान विवरण",
        "pos_order_list.total_paid": "कुल भुगतान किया गया",
        "pos_order_list.total_due": "कुल बकाया",
        "pos_order_list.payment_method": "भुगतान का तरीका",
        "pos_order_list.total": "कुल",
        "pos_order_list.add_qty": "मात्रा जोड़ें",
        "pos_order_list.add_price": "मूल्य जोड़ें",
        "pos_order_list.add_on_total": "कुल जोड़ों",
        "pos_order_list.item_details": "आइटम विवरण",
        "pos_order_list.point_discount": "बिंदु छूट",
        "pos_order_list.search_order_by_id": "आदेश आईडी से आदेश खोजें",
        "pos_order_list.assign_delivery_partner": "डिलीवरी पार्टनर को सौंपें",
        "pos_order_list.assign": "सौंपें",
        "pos_order_list.track_order": "आदेश का ट्रैक करें",

        // pos hold list
        "pos_hold_list.resume_hold_order": "होल्ड ऑर्डर फिर से शुरू करें",
        "pos_hold_list.confirm_hold_order_resume": "क्या आप वाकई इस होल्ड आर्डर को फिर से शुरू करना चाहते हैं",
        "pos_hold_list.remove_hold_order": "होल्ड आर्डर हटाएं",
        "pos_hold_list.confirm_remove_hold_order": "क्या आप वाकई इस होल्ड आर्डर को हटाना चाहते हैं",

        // random user
        "user_form.please_assign_role": "कृपया एक भूमिका का निर्धारण करें",
        "user_form.assign_role": "भूमिका निर्धारित करें",
        "user_form.search_role": "भूमिका खोजें",
        "user_form.ok": "ठीक है",
        "user.change_password": "पासवर्ड बदलें",
        "user.assigned_role_list": "निर्धारित भूमिका सूची",
        "user.assigned_roles": "निर्धारित भूमिकाएँ",
        "user.branch_manager": "शाखा प्रबंधक",
        "user.waiter": "वेटर",
        "user.chef": "रसोइया",
        "user.franchise_owner": "फ्रैंचाइज़ी मालिक",

        // analytics
        "analytics.order_type": "आदेश प्रकार",
        "analytics.customer_type": "ग्राहक प्रकार",
        "analytics.total_number": "कुल संख्या",
        "analytics.customer_map": "ग्राहक मानचित्र",
        "analytics.no_data": "कोई डेटा नहीं",
        "analytics.new_customer": "नए ग्राहक",
        "analytics.retained_customer": "रिटेन्शन ग्राहक",
        "analytics.excel": "एक्सेल",
        "analytics.pdf": "पीडीएफ",
        "analytics.customer_count": "ग्राहक गणना",
        "analytics.restaurant_name": "रेस्तरां का नाम",
        "analytics.customer": "ग्राहक",
        "analytics.total_customer": "कुल ग्राहक",
        "analytics.order_count": "आदेश गणना",
        "analytics.sl": "एसएल।",
        "analytics.start_date": "प्रारंभ तिथि",
        "analytics.end_date": "अंतिम तिथि",
        "analytics.generated_at": "जनरेट किया गया",
        "analytics.all_right_reserved": "सभी अधिकार सुरक्षित",

        // cart
        "cart.save_order": "आदेश सहेजें",
        "cart.complete_order": "आदेश पूरा करें"
    }
}
