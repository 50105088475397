import {
    INPUT_HOVER_COLOR,
    PRIMARY_COLOR_FADE_2,
    RESTAURANT_PRIMARY_COLOR,
    RESTAURANT_PRIMARY_COLOR_FADE, TEXT_BASE_COLOR, WHITE,
} from "../helpers/RestaurantConstant";

export const lightToken = {
    colorPrimary: RESTAURANT_PRIMARY_COLOR,
    fontFamily: "'Barlow Condensed', 'Inter', 'Almarai', 'Noto Sans Bengali', sans-serif",
    colorInfo: RESTAURANT_PRIMARY_COLOR,
    colorInfoBg: RESTAURANT_PRIMARY_COLOR_FADE,
    colorInfoBorder: RESTAURANT_PRIMARY_COLOR,
    descriptionBg: WHITE, // Description background
    customBorderColor: PRIMARY_COLOR_FADE_2, // Use this variable in jsx
    customBGC: WHITE,
    colorTextBase: TEXT_BASE_COLOR,
}

export const lightComponentsToken = {
    Input: {
        colorPrimaryHover: INPUT_HOVER_COLOR
    },
    InputNumber: {
        colorPrimaryHover: INPUT_HOVER_COLOR
    },
    Select: {
        colorPrimaryHover: INPUT_HOVER_COLOR
    },
    DatePicker: {
        colorPrimaryHover: INPUT_HOVER_COLOR
    },
    Table: {
        colorFillAlter: WHITE,
    },
}