import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";

export default class AddOnsService {

    static createAddOns = data => privateAPI.post(
        `${URL.CREATE_PRODUCT_ADD_ONS}`, data
    );

    static getAllAddOns = params => privateAPI.get(
        `${URL.GET_ALL_PRODUCT_ADD_ONS}`, {params}
    );

    static getAddOnsById = adOnsId => privateAPI.get(
        `${URL.GET_PRODUCT_ADD_ONS_BY_ID}/${adOnsId}`
    );

    static updateAddOns = (adOnsId, data) => privateAPI.put(
        `${URL.UPDATE_PRODUCT_ADD_ONS}/${adOnsId}`, data
    );

    // requested add-ons
    static getAllRequestedAddOns = params => privateAPI.get(
        `${URL.GET_ALL_REQUESTED_ADD_ONS}`, {params}
    );

}
