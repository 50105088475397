import {createContext, useState} from "react";
import KitchenDisplayService from "../services/KitchenDisplayService";

export const KitchenDisplayContext = createContext("KitchenDisplayContext");

const KitchenDisplayContextProvider = ({children}) => {

    const [currentItemCount, setCurrentItemCount] = useState(4);
    const [prevOrderNoSet, setPrevOrderNoSet] = useState(new Set());

    const [summaryItems, setSummaryItems] = useState([]);
    const [orderItems, setOrderItems] = useState([]);

    const [loading, setLoading] = useState(false);

    const getKitchenDisplayData = async () => {

        try {

            setLoading(true);
            let res = await KitchenDisplayService.getKitchenDisplayData();

            bindTheDataWithState(res, currentItemCount);

            setLoading(false);
        } catch (e) {
            setLoading(false);
            /* TODO:
            *   Need to add a toast here.
            * */
        }

    }

    const getKitchenDisplayDataAndUpdate = async () => {

        try {

            setLoading(true);
            let res = await KitchenDisplayService.getKitchenDisplayData();

            bindTheDataWithState(res, currentItemCount);

            setLoading(false);

        } catch (e) {
            setLoading(false);
            /* TODO:
            *   Need to add a toast here.
            * */
        }

    }

    const bindTheDataWithState = (res) => {

        /* TODO:
        *   Need to add some condition to avoid equal value set
        *   If the order is added then the items/quantity maybe increase or decrease.
        * */
        setSummaryItems(res.data.productSummaries);

        const orders = res.data.orders;
        const orderNoSet = new Set();
        orders.forEach(order => {
            orderNoSet.add(order.orderNo);
        });

        if (!eqSet(orderNoSet, prevOrderNoSet)) {

            setOrderItems(res.data.orders);
            setPrevOrderNoSet(orderNoSet);

        }

    }

    const eqSet = (set1, set2) => {
        return set1.size === set2.size && [...set1].every((x) => set2.has(x));
    }


    return <KitchenDisplayContext.Provider
        value={{
            currentItemCount,
            summaryItems,
            orderItems,
            loading,
            setCurrentItemCount,
            getKitchenDisplayData,
            getKitchenDisplayDataAndUpdate
        }}
    >
        {children}
    </KitchenDisplayContext.Provider>

}

export default KitchenDisplayContextProvider;