import {BRANCH} from "./Constant";

const setItemForBranch = (key, value) => {
    const branch = JSON.parse(localStorage.getItem(BRANCH));
    localStorage.setItem(`${branch.id}${key}`, value);
}

const getItemForBranch = (key) => {
    const branch = JSON.parse(localStorage.getItem(BRANCH));
    if (branch) {
        return localStorage.getItem(`${branch.id}${key}`);
    }
    return null;
}

const removeItemForBranch = (key) => {
    const branch = JSON.parse(localStorage.getItem(BRANCH));
    localStorage.removeItem(`${branch.id}${key}`);
}

const setItem = (key, value) => {
    localStorage.setItem(key, value);
}

const getItem = (key) => {
    return localStorage.getItem(key);
}

const removeItem = (key) => {
    localStorage.removeItem(key);
}

const removeItems = (...keys) => {
    if (keys) {
        keys.forEach(key => {
            localStorage.removeItem(key);
        });
    }
}

const clear = () => {
    localStorage.clear();
}

const LocalStorageUtils = {
    setItem,
    getItem,
    setItemForBranch,
    getItemForBranch,
    removeItemForBranch,
    removeItem,
    removeItems,
    clear,
}

export default LocalStorageUtils;