import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import {getBranchId} from "../helpers/Utils";

export default class ReportService {

    static getAllOwnerReport = params => privateAPI.get(
        `${URL.GET_ALL_OWNER_REPORT_URL}`, {params}
    );

    static getBranchEarningGraphData = params => privateAPI.get(
        `${URL.GET_RESTAURANT_EARNING_GRAPH_URL}`, {params}
    );

    static getBranchEarningBarchartData = params => privateAPI.get(
        `${URL.GET_RESTAURANT_EARNING_BARCHART_URL}`, {params}
    );

    static getRestaurantOrderBarchart = params => privateAPI.get(
        `${URL.GET_RESTAURANT_ORDER_BARCHART_URL}`, {params}
    );

    static getRestaurantOrderLineChart = params => privateAPI.get(
        `${URL.GET_RESTAURANT_ORDER_LINE_CHART_URL}`, {params}
    );

    static getRestaurantTopProducts = params => privateAPI.get(
        `${URL.GET_ALL_RESTAURANT_TOP_PRODUCT_URL}`, {params}
    );

    static getRestaurantOrderSummary = params => privateAPI.get(
        `${URL.GET_ANALYTICS_ORDER_SUMMARY_URL}`, {params}
    );

    static getRestaurantOrderType = params => privateAPI.get(
        `${URL.GET_ANALYTICS_ORDER_TYPE_URL}`, {params}
    );

    static getMonthlyYearlyCustomer = params => privateAPI.get(
        `${URL.GET_MONTHLY_YEARLY_CUSTOMER_URL}`, {params}
    );

    static getRestaurantTopBranches = params => privateAPI.get(
        `${URL.GET_ALL_RESTAURANT_TOP_BRANCH_URL}`, {params}
    );

    static getRestaurantAllProductReview = params => privateAPI.get(
        `${URL.GET_RESTAURANT_PRODUCT_REVIEW_URL}`, {params}
    );

    static getRestaurantAllOrderReview = params => privateAPI.get(
        `${URL.GET_RESTAURANT_ORDER_REVIEW_URL}`, {params}
    );

    static getRestaurantOrderSaleRecords = params => privateAPI.get(
        `${URL.GET_RESTAURANT_ORDER_SALE_REPORT_URL}`, {params}
    );

    static getRestaurantOrderItemSaleReports = params => privateAPI.get(
        `${URL.GET_RESTAURANT_ORDER_ITEM_SALE_REPORT_URL}`, {params}
    );

    static getEarningsReportByPayment = params => privateAPI.get(
        `${URL.GET_EARNING_BY_PAYMENT_REPORT_URL}/${getBranchId()}`, {params}
    );
}
