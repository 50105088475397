import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchGlobalIngredientService {

    static createIngredientRequest = data => privateAPI.post(
        `${URL.BRANCH_INGREDIENTS_CREATE_REQUEST}/${getBranchId()}`, data
    );

    static getAllBranchIngredient = params => privateAPI.get(
        `${URL.BRANCH_GET_ALL_INGREDIENT}/${getBranchId()}`, {params}
    );

    static assignBranchIngredient = data => privateAPI.post(
        `${URL.BRANCH_INGREDIENT_ASSIGN}/${getBranchId()}`, data
    );

    static getAllIngredientCategoryForBranch = params => privateAPI.get(
        `${URL.BRANCH_INGREDIENT_CATEGORY}/${getBranchId()}`, {params}
    );

    static getBranchIngredientById = categoryId => privateAPI.get(
        `${URL.GET_BRANCH_INGREDIENT_ID}/${categoryId}/${getBranchId()}`
    );
    static assignBranchSupplier = (ingredientId, data) => privateAPI.post(
        `${URL.POST_SUPPLIERS_BY_INGREDIENT_ID}/${ingredientId}/${getBranchId()}`, data
    );

    static updateIngredientBranch = (categoryId, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH_INGREDIENT_ID}/${categoryId}/${getBranchId()}`, data
    );

    static getAllUnits = params => privateAPI.get(
        `${URL.BRANCH_GET_ALL_UNITS}/${getBranchId()}`, {params}
    );

}
