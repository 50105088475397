import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class SupplierService {

    static createSupplier = data => privateAPI.post(
        `${URL.POST_SUPPLIER}`, data
    );

    static getAllSuppliers = params => privateAPI.get(
        `${URL.GET_SUPPLIER_LIST}`, {params}
    );

    static getSupplierById = categoryId => privateAPI.get(
        `${URL.GET_SUPPLIER_BY_ID}/${categoryId}`
    );

    static updateSupplier = (categoryId, data) => privateAPI.put(
        `${URL.UPDATE_SUPPLIER}/${categoryId}`, data
    );
}
