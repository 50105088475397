import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import {getBranchId} from "../helpers/Utils";

export default class CustomerService {

    static getAllCustomer = params => privateAPI.get(
        `${URL.GET_ALL_CUSTOMER_URL}`, {params}
    );

    static getAllCustomerForBranch = params => privateAPI.get(
        `${URL.GET_ALL_CUSTOMER_URL}`, {params}
    );

    static createCustomer = data => privateAPI.post(URL.CREATE_CUSTOMER_URL, data);

    static getAllAddressByCustomer = customerId => privateAPI.get(
        `${URL.GET_ALL_ADDRESS_BY_CUSTOMER}/${customerId}/${getBranchId()}`
    );

}
