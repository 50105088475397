import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import AddOnsService from "../../services/product/AddOnsService";

export const AddOnsContext = createContext("AddOnsContext");

const AddOnsContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [addOnsList, setAddOnsList] = useState([]);
    const [requestedAddOnsList, setRequestedAddOnsList] = useState([]);

    const getAllAddOns = async (restaurantId, params) => {
        try {

            setLoading(true);

            const res = await AddOnsService.getAllAddOns(restaurantId, params);
            setAddOnsList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllRequestedAddOns = async params => {
        try {

            setLoading(true);

            const res = await AddOnsService.getAllRequestedAddOns(params);
            setRequestedAddOnsList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <AddOnsContext.Provider
            value={{
                loading,
                totalElements,
                addOnsList,
                requestedAddOnsList,
                getAllAddOns,
                getAllRequestedAddOns,
            }}
        >
            {children}
        </AddOnsContext.Provider>
    );
}

export default AddOnsContextProvider;
