import axios from "axios";
import {apiUserAuth} from "../helpers/Utils";
import {RESTAURANT_TOKEN} from "../helpers/Constant";
import LocalStorageUtils from "../helpers/LocalStorageUtils";

const instance = axios.create({
    baseURL: "",
    headers: {
        "Registration-Device": "PORTAL",
    }
});

instance.interceptors.request.use(
    (config) => {

        const token = LocalStorageUtils.getItem(RESTAURANT_TOKEN);
        if (token) {
            config.headers["Restaurant-Token"] = token;
        }

        if (!config.headers.Authorization) {
            config.headers = {...config.headers, ...apiUserAuth()};
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    }, // If valid response
    async (err) => {
        return Promise.reject(err);
    }
);

export default instance;
