export const RESTAURANT_BRAND_DOMAIN = "zaagsys.com";
export const RESTAURANT_COPYRIGHT = "zaagsys.com";
export const RESTAURANT_SECONDARY_COLOR = "#787878";

// Theme color

export const RESTAURANT_PRIMARY_COLOR = "#03B29A";
export const RESTAURANT_PRIMARY_COLOR_FADE = "#eef9f7";
export const PRIMARY_COLOR_FADE_2 = "#FCC57C29";
export const INPUT_HOVER_COLOR = "#000000";
export const WHITE = "#FFFFFF";
export const TEXT_BASE_COLOR = "#3A3A3A";
