import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";

export default class IngredientService {

    static createIngredient = data => privateAPI.post(
        `${URL.CREATE_INGREDIENT}`, data
    );

    static getAllIngredient = params => privateAPI.get(
        `${URL.GET_ALL_INGREDIENT}`, {params}
    );

    static getIngredientById = categoryId => privateAPI.get(
        `${URL.GET_ALL_INGREDIENT_ID}/${categoryId}`
    );

    static updateIngredient = (categoryId, data) => privateAPI.put(
        `${URL.UPDATE_INGREDIENT}/${categoryId}`, data
    );

    static getAllUnits = params => privateAPI.get(
        `${URL.GET_ALL_UNIT}`, {params}
    );

}
