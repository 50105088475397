import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import publicAPI from "../rest_handlers/publicAPI";
import {getBranchId} from "../helpers/Utils";

export default class BranchConfigService {

    static createBranchConfig = data => privateAPI.post(
        `${URL.CREATE_BRANCH_CONFIG_URL}/${getBranchId()}`, data
    );

    static getAllBranchConfig = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH_CONFIG_URL}/${getBranchId()}`, {params}
    );

    static getBranchConfigById = id => privateAPI.get(
        `${URL.GET_BRANCH_CONFIG_BY_ID_URL}/${id}/${getBranchId()}`
    );

    static getActiveBranchConfig = () => privateAPI.get(
        `${URL.GET_ACTIVE_BRANCH_CONFIG_URL}/${getBranchId()}`
    );

    static getPublicBranchConfig = () => publicAPI.get(
        `${URL.GET_PUBLIC_ACTIVE_BRANCH_CONFIG_URL}/${getBranchId()}`
    );

    static getBranchConfigByQrCode = branchId => publicAPI.get(
        `${URL.GET_PUBLIC_ACTIVE_BRANCH_CONFIG_URL}/${branchId}`
    );

    static updateBranchConfig = (id, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH_CONFIG_URL}/${id}/${getBranchId()}`, data
    );

}
