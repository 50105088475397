import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchRequestProductService {

    static createBranchRequestProduct = data => privateAPI.post(
        `${URL.CREATE_BRANCH_REQUEST_PRODUCT}/${getBranchId()}`, data
    );

    static getAllBranchRequestProduct = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH_REQUEST_PRODUCT}/${getBranchId()}`, {
            params,
            paramsSerializer: {
                indexes: null // by default: false
            }
        }
    );

    static getBranchRequestProductById = id => privateAPI.get(
        `${URL.GET_BRANCH_REQUEST_PRODUCT_BY_ID}/${id}/${getBranchId()}`
    );

    static updateBranchRequestProduct = (id, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH_REQUEST_PRODUCT}/${id}/${getBranchId()}`, data
    );

}
