import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import RoleService from "../services/RoleService";
import {Toast} from "../components/common/Toast";

export const RoleContext = createContext("RoleContext");

const RoleContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [roleList, setRoleList] = useState([]);

    const [restaurantRoleLoading, setRestaurantRoleLoading] = useState(false);
    const [restaurantRoles, setRestaurantRoles] = useState([]);

    const getAllRole = async params => {
        try {

            setLoading(true);

            const res = await RoleService.getAllRole(params);
            setRoleList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getRolesByRestaurant = async (restaurantId, params) => {
        // console.log("----id----", restaurantId)
        try {

            setRestaurantRoleLoading(true);

            const res = await RoleService.getRolesByRestaurant(restaurantId, params);
            setRestaurantRoles(res.data);

            setRestaurantRoleLoading(false);

        } catch (error) {

            setRestaurantRoleLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <RoleContext.Provider
            value={{
                loading,
                totalElements,
                roleList,
                getAllRole,
                getRolesByRestaurant,
                restaurantRoleLoading,
                restaurantRoles,
            }}
        >
            {children}
        </RoleContext.Provider>
    );
}

export default RoleContextProvider;
