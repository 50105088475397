import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import BranchRequestProductService from "../../services/branch_product/BranchRequestProductService";

export const BranchRequestProductContext = createContext("BranchRequestProductContext");

const BranchRequestProductContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [branchRequestProductList, setBranchRequestProductList] = useState([]);
    const [branchProductIds, setBranchProductIds] = useState([]);
    const getAllBranchRequestProduct = async params => {
        try {

            setLoading(true);

            const res = await BranchRequestProductService.getAllBranchRequestProduct(params);
            setBranchProductIds(res.data.branchProductIds);
            setBranchRequestProductList(res.data.globalProducts.content);
            setTotalElements(res.data.globalProducts.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchRequestProductContext.Provider
            value={{
                loading,
                totalElements,
                branchRequestProductList,
                branchProductIds,
                getAllBranchRequestProduct,
            }}
        >
            {children}
        </BranchRequestProductContext.Provider>
    );
}

export default BranchRequestProductContextProvider;
