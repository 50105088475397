import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import BranchGlobalIngredientService from "../../services/branch_ingredient/BranchGlobalIngredientService";

export const BranchGlobalIngredientContext = createContext("BranchGlobalIngredientContext");

const BranchGlobalIngredientContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);

    const [globalIngredientTotalElements, setGlobalIngredientTotalElements] = useState(0);
    const [globalIngredientList, setGlobalIngredientList] = useState([]);
    const [globalIngredientIds, setGlobalIngredientIds] = useState([]);

    const [branchIngredientCategory, setBranchIngredientCategory] = useState([]);
    const [branchIngredientCategoryTotalEle, setBranchIngredientCategoryTotalEle] = useState([]);

    const [units, setUnits] = useState([]);
    const [unitsTotalElements, setUnitsTotalElements] = useState(0);


    const getAllGlobalIngredient = async params => {
        try {

            setLoading(true);

            const res = await BranchGlobalIngredientService.getAllBranchIngredient(params);
            setGlobalIngredientIds(res.data.branchIngredientIds);
            setGlobalIngredientList(res.data.globalIngredients.content);
            setGlobalIngredientTotalElements(res.data.globalIngredients.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllIngredientCategoryBranch = async params => {
        try {

            setLoading(true);

            const res = await BranchGlobalIngredientService.getAllIngredientCategoryForBranch(params);
            setBranchIngredientCategory(res.data.content);
            setBranchIngredientCategoryTotalEle(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllUnits = async params => {
        try {

            setLoading(true);

            const res = await BranchGlobalIngredientService.getAllUnits(params);
            setUnits(res.data.content);
            setUnitsTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchGlobalIngredientContext.Provider
            value={{
                loading,
                globalIngredientTotalElements,
                globalIngredientList,
                branchIngredientCategory,
                branchIngredientCategoryTotalEle,
                globalIngredientIds,
                getAllGlobalIngredient,
                getAllIngredientCategoryBranch,
                unitsTotalElements,
                units,
                getAllUnits
            }}
        >
            {children}
        </BranchGlobalIngredientContext.Provider>
    );
}

export default BranchGlobalIngredientContextProvider;
