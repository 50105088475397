import {notification} from "antd";
import Translator from "../../i18n/translator";

import "./toast.scss"

export const Toast = (type, title, desc) => {

    // Close all previous notifications
    notification.destroy();

    if (typeof title !== 'string') {
        desc = Translator("toast.string_error");
    }

    if (typeof desc !== 'string') {
        desc = Translator("toast.desc_string_error");
    }

    notification[type]({
        // message: title,
        description: desc,
        duration: 3,
        placement: "top",
        closeIcon: null,
        style: {
            padding: "10px 15px",
            alignItems: "center",
            justifyContent: "center"
        },
    });
};
