import axios from "axios";
import {LANGUAGE, RESTAURANT_TOKEN} from "../helpers/Constant";
import LocalStorageUtils from "../helpers/LocalStorageUtils";

const lng = LocalStorageUtils.getItem(LANGUAGE);

const instance = axios.create({
    baseURL: "",
    headers: {
        "Content-Type": "application/json",
        "Accept-Language": lng,
        "Registration-Device": "PORTAL",
    }
});

instance.interceptors.request.use(
    (config) => {
        const token = LocalStorageUtils.getItem(RESTAURANT_TOKEN);
        if (token) {
            config.headers["Restaurant-Token"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
