import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import BranchService from "../services/BranchService";
import {Toast} from "../components/common/Toast";


export const BranchContext = createContext("BranchContext");

const BranchContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [branchList, setBranchList] = useState([]);

    const getAllBranch = async params => {
        try {

            setLoading(true);

            const res = await BranchService.getAllBranch(params);
            setBranchList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <BranchContext.Provider
            value={{
                loading,
                totalElements,
                branchList,
                getAllBranch,
            }}
        >
            {children}
        </BranchContext.Provider>
    );
}

export default BranchContextProvider;
