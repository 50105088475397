import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import RequestIngredientService from "../../services/ingredient/RequestIngredientService";

export const RequestIngredientContext = createContext("RequestIngredientContext");

const RequestIngredientContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [requestIngredientTotalElements, setRequestIngredientTotalElements] = useState(0);
    const [requestIngredientList, setRequestIngredientList] = useState([]);
    // const [units, setUnits] = useState([])
    // const [unitsTotalElements, setUnitsTotalElements] = useState(0)

    const getAllRequestIngredient = async params => {
        try {

            setLoading(true);

            const res = await RequestIngredientService.getAllRequestIngredient(params);
            setRequestIngredientList(res.data.content);
            setRequestIngredientTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }
    // const getAllUnits = async params => {
    //     try {
    //
    //         console.log("clicked")
    //
    //         setLoading(true);
    //
    //         const res = await IngredientService.getAllUnits(params);
    //         setUnits(res.data.content);
    //         setUnitsTotalElements(res.data.totalElements);
    //
    //         setLoading(false);
    //
    //     } catch (error) {
    //
    //         setLoading(false);
    //
    //         const message = getErrorMessage(error);
    //         Toast("error", "Error", message);
    //         // Toast("error", "Error", message);
    //
    //     }
    // }

    return (
        <RequestIngredientContext.Provider
            value={{
                loading,
                requestIngredientTotalElements,
                requestIngredientList,
                getAllRequestIngredient,
                // getAllUnits,
                // units,
                // unitsTotalElements
            }}
        >
            {children}
        </RequestIngredientContext.Provider>
    );
}

export default RequestIngredientContextProvider;
