import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import {getBranchId} from "../helpers/Utils";

export default class KitchenDisplayService {
    static getKitchenDisplayData = () => privateAPI.get(
        `${URL.GET_ALL_KITCHEN_DISPLAY}/${getBranchId()}`
    );

}
