import React, {createContext, useContext, useState} from 'react';
import {
    DELIVERY,
    POS_ORDER_CART,
    POS_ORDER_CART_INVOICE,
    POS_ORDER_HOLD_LIST,
    POS_ORDER_INFO
} from "../../helpers/Constant";
import {
    getDiscountAmount,
    getErrorMessage,
    getPercentDiscountAmount,
    percentValueCalculation
} from "../../helpers/Utils";
import BranchOrderService from "../../services/order/BranchOrderService";
import {Toast} from "../../components/common/Toast";
import {AuthContext} from "../AuthContextProvider";
import CustomerService from "../../services/CustomerService";
import LocalStorageUtils from "../../helpers/LocalStorageUtils";
import Big from "big.js";

export const PosOrderContext = createContext("PosOrderContext");

const PosOrderContextProvider = ({children}) => {

    const {branchConfig} = useContext(AuthContext);

    const [posOrderMap, setPosOrderMap] = useState(null);
    const [posOrderSelectedItemId, setPosOrderSelectedItemId] = useState("");
    const initPosCartInvoice = {
        totalProductPrice: 0,
        totalAddOnPrice: 0,
        discount: {discountValue: 0, discountType: "FLAT"},
        subTotal: 0,
        totalPrice: 0,
        finalPrice: 0,
        tax: 0,
        serviceChargeAmount: 0,
        sdChargeAmount: 0,
        vatAmount: 0,
        readOnlyDiscount: 0,
        customerPaid: 0,
        returnAmount: 0,
    }
    const [posCartInvoice, setPosCartInvoice] = useState(initPosCartInvoice);

    const _paymentTypes = [
        {id: 1, type: "CASH", isSelected: true, amount: ""},
        {id: 2, type: "ONLINE", isSelected: false, amount: ""},
        {id: 3, type: "MFS", isSelected: false, amount: ""}
    ]
    const [posOrderInfo, setPosOrderInfo] = useState(
        {
            orderType: "DINE_IN",
            paymentTypes: _paymentTypes,
            tableList: [],
            addressList: [],
        }
    );
    const [createOrderLoading, setCreateOrderLoading] = useState(false);
    const [posOrderHoldList, setPosOrderHoldList] = useState([]);

    const [posOrderProductMultiplyCount, setPosOrderProductMultiplyCount] = useState(0);

    const addPosOrder = (branchProduct, id) => {

        const _productId = `${branchProduct.id}-${new Date().getMilliseconds()}`

        const _posOrderMap = {
            ...posOrderMap,
            [_productId]: {
                id: _productId,
                branchProduct,
                quantity: posOrderProductMultiplyCount > 0 ? posOrderProductMultiplyCount : 1,
                cartAddOnDetailsList: []
            }
        }

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap))
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const removeItem = (id, editId) => {

        delete posOrderMap[id];

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(posOrderMap));
        setPosOrderMap({...posOrderMap});

        if (Object.keys(posOrderMap)?.length < 1) {
            clearPosOrderLocalStorage();
            return;
        }

        getPosCartInvoiceCalculation(posOrderMap, editId);

    }

    const incrementHandle = (id, operation, editId, inputValue) => {

        let _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[id];

        if (operation === -1 && posOrderDetails.quantity === 1) {
            return;
        }

        _posOrderMap[id] = {
            ...posOrderDetails,
            quantity: inputValue ? inputValue : posOrderDetails.quantity + operation
            // quantity: posOrderDetails.quantity + operation
        }

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, editId);

    }

    const addPosCartAddOn = (cartDetailsId, branchAddOn) => {


        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[cartDetailsId];

        _posOrderMap[cartDetailsId] = {
            ...posOrderDetails,
            cartAddOnDetailsList: [
                ...posOrderDetails.cartAddOnDetailsList,
                {
                    branchAddOn,
                    quantity: 1
                }
            ]
        }

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap);

    }

    const incrementAddOn = (cartDetailsId, branchAddOnId, operation, id) => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[cartDetailsId];

        _posOrderMap[cartDetailsId] = {
            ...posOrderDetails,
            cartAddOnDetailsList: posOrderDetails.cartAddOnDetailsList.map(cartAddOnDetails => {

                if (operation === -1 && cartAddOnDetails.quantity === 1) {
                    return cartAddOnDetails;
                }

                if (cartAddOnDetails.branchAddOn.id === branchAddOnId) {
                    return {
                        ...cartAddOnDetails,
                        quantity: cartAddOnDetails.quantity + operation
                    }
                }
                return cartAddOnDetails;
            })
        }

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const removeAddOn = (cartDetailsId, branchAddOnId, id) => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[cartDetailsId];

        _posOrderMap[cartDetailsId] = {
            ...posOrderDetails,
            cartAddOnDetailsList: posOrderDetails.cartAddOnDetailsList
                .filter(cartAddOnDetails => cartAddOnDetails.branchAddOn.id !== branchAddOnId)
        }

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const handleDialPadChange = (quantity, id) => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[posOrderSelectedItemId];

        const _quantity = `${_posOrderMap[posOrderSelectedItemId].quantity}${quantity}`

        _posOrderMap[posOrderSelectedItemId] = {
            ...posOrderDetails,
            quantity: parseInt(_quantity)
        }

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const handleDeleteDialPad = id => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[posOrderSelectedItemId];

        let quantityParsedToString = posOrderDetails.quantity.toString();

        if (quantityParsedToString.length > 1) {

            const _quantity = quantityParsedToString.slice(0, quantityParsedToString.length - 1);

            _posOrderMap[posOrderSelectedItemId] = {
                ...posOrderDetails,
                quantity: parseInt(_quantity)
            }

            LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap));
            setPosOrderMap(_posOrderMap);

            getPosCartInvoiceCalculation(_posOrderMap, id);

        }

    }

    const clearDialPad = id => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[posOrderSelectedItemId];

        _posOrderMap[posOrderSelectedItemId] = {
            ...posOrderDetails,
            quantity: 1
        }

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const changePosCartDiscount = (discountValue, discountType) => {

        const _discountValue = getDiscountAmount(posCartInvoice.subTotal, discountValue, discountType)

        const serviceCharge = branchConfig?.serviceChargeEnabled ? branchConfig?.serviceCharge : 0;
        const serviceChargeAmount = percentValueCalculation(posCartInvoice.subTotal, serviceCharge);

        const sdCharge = branchConfig?.sdChargeEnabled ? branchConfig?.sdCharge : 0;
        const sdChargeAmount = percentValueCalculation(posCartInvoice.subTotal, sdCharge);

        const _subTotalBig = new Big(posCartInvoice.subTotal);
        const serviceChargeAmountBig = new Big(serviceChargeAmount);
        const sdChargeAmountBig = new Big(sdChargeAmount);
        const readOnlyDiscountBig = new Big(_discountValue);

        let _totalPrice = _subTotalBig.plus(serviceChargeAmountBig).plus(sdChargeAmountBig).minus(readOnlyDiscountBig)
        // const _totalPrice = (posCartInvoice.subTotal + sdChargeAmount + serviceChargeAmount) - _discountValue;

        const taxPercentage = branchConfig?.taxPercentage > 0 ? branchConfig?.taxPercentage : 0;
        const taxValue = percentValueCalculation(posCartInvoice.subTotal, taxPercentage);

        const vat = branchConfig?.vatEnabled ? branchConfig?.vat : 0;

        const vatAmount = percentValueCalculation(posCartInvoice.subTotal, vat);

        const taxValueBig = new Big(taxValue);
        const vatAmountBig = new Big(vatAmount);

        // const finalPrice = _totalPrice + taxValue + vatAmount;
        const finalPrice = _totalPrice.plus(taxValueBig).plus(vatAmountBig);


        const _posCartInvoice = {
            ...posCartInvoice,
            discount: {discountValue, discountType},
            totalPrice: _totalPrice,
            finalPrice,
            tax: taxValue,
            serviceChargeAmount,
            sdChargeAmount,
            vatAmount,
            readOnlyDiscount: _discountValue,
            returnAmount: posCartInvoice.customerPaid - finalPrice,
        }

        setPosCartInvoice(_posCartInvoice);
        LocalStorageUtils.setItemForBranch(POS_ORDER_CART_INVOICE, JSON.stringify(_posCartInvoice));

    }

    const getPosCartInvoiceCalculation = (posOrderMap, id) => {

        let _totalAddOnPrice = 0;
        let _totalProductPrice = 0;
        let _totalVatFreeProductPrice = 0;
        let _subTotal = 0;

        for (let key in posOrderMap) {

            // console.log("order map - ", posOrderMap[key].branchProduct?.vatFree)

            // vatFree
            _totalProductPrice += getPercentDiscountAmount(posOrderMap[key].branchProduct.price, posOrderMap[key].branchProduct.discountPercent) * posOrderMap[key].quantity;

            if (posOrderMap[key].branchProduct?.vatFree) {
                _totalVatFreeProductPrice += getPercentDiscountAmount(posOrderMap[key].branchProduct.price, posOrderMap[key].branchProduct.discountPercent) * posOrderMap[key].quantity;
            }

            for (let i = 0; i < posOrderMap[key].cartAddOnDetailsList.length; i++) {
                _totalAddOnPrice += posOrderMap[key].cartAddOnDetailsList[i].quantity * posOrderMap[key].cartAddOnDetailsList[i].branchAddOn.price
            }

        }


        _subTotal = _totalAddOnPrice + _totalProductPrice;

        // let _discount = {...posCartInvoice.discount};
        // let _readOnlyDiscount;

        // if (id) {
        //
        //     if (_totalPrice > posCartInvoice.originalCartInvoice.totalPrice) {
        //
        //         _readOnlyDiscount = _totalPrice - posCartInvoice.originalCartInvoice.subTotal;
        //
        //         _discount = {
        //             discountType: "FLAT",
        //             discountValue: precisionFactor(_readOnlyDiscount)
        //         }
        //
        //     } else {
        //
        //         _readOnlyDiscount = posCartInvoice.originalCartInvoice.discountAmount;
        //
        //         _discount = {
        //             discountType: "FLAT",
        //             discountValue: _readOnlyDiscount
        //         }
        //
        //     }
        //
        // }

        // _readOnlyDiscount = getDiscountAmount(_totalPrice, _discount.discountValue, _discount.discountType);

        const serviceCharge = branchConfig?.serviceChargeEnabled ? branchConfig.serviceCharge : 0;
        const serviceChargeAmount = percentValueCalculation(_subTotal, serviceCharge);

        const sdCharge = branchConfig?.sdChargeEnabled ? branchConfig?.sdCharge : 0;
        const sdChargeAmount = percentValueCalculation(_subTotal, sdCharge);

        const _subTotalBig = new Big(_subTotal);
        const serviceChargeAmountBig = new Big(serviceChargeAmount);
        const sdChargeAmountBig = new Big(sdChargeAmount);
        const readOnlyDiscountBig = new Big(posCartInvoice.readOnlyDiscount);

        // let _totalPrice = (_subTotal + serviceChargeAmount + sdChargeAmount) - posCartInvoice.readOnlyDiscount;
        let _totalPrice = _subTotalBig.plus(serviceChargeAmountBig).plus(sdChargeAmountBig).minus(readOnlyDiscountBig);


        let taxPercentage = branchConfig.taxPercentage > 0 ? branchConfig?.taxPercentage : 0;
        let taxValue = percentValueCalculation(_subTotal, taxPercentage);

        const vat = branchConfig?.vatEnabled ? branchConfig?.vat : 0;
        const vatAmount = percentValueCalculation(_subTotal - _totalVatFreeProductPrice, vat);

        const taxValueBig = new Big(taxValue);
        const vatAmountBig = new Big(vatAmount);

        // const finalPrice = _totalPrice + taxValue + vatAmount;
        const finalPrice = _totalPrice.plus(taxValueBig).plus(vatAmountBig);

        const _posCartInvoice = {
            ...posCartInvoice,
            // discount: _discount,
            totalPrice: _totalPrice,
            totalProductPrice: _totalProductPrice,
            totalAddOnPrice: _totalAddOnPrice,
            subTotal: _subTotal,
            finalPrice,
            vatAmount,
            serviceChargeAmount,
            sdChargeAmount,
            tax: taxValue,
            returnAmount: posCartInvoice.customerPaid - finalPrice,
        }

        setPosCartInvoice(_posCartInvoice);
        LocalStorageUtils.setItemForBranch(POS_ORDER_CART_INVOICE, JSON.stringify(_posCartInvoice));

    }

    const handlePosOrderInfoChange = data => {

        return new Promise((resolve, reject) => {
            const _posOrderInfo = {...posOrderInfo, ...data};
            setPosOrderInfo(_posOrderInfo);
            LocalStorageUtils.setItemForBranch(POS_ORDER_INFO, JSON.stringify(_posOrderInfo));
            resolve(); // Resolve the promise once the operation is done
        });

    }

    const clearPosOrderInfoCustomer = () => {

        const _posOrderInfo = {...posOrderInfo};

        delete _posOrderInfo?.fullName;
        delete _posOrderInfo?.countryCode;
        delete _posOrderInfo?.mobileNumber;
        delete _posOrderInfo?.email;
        delete _posOrderInfo?.addressList;
        delete _posOrderInfo?.address;

        setPosOrderInfo(_posOrderInfo);
        LocalStorageUtils.setItemForBranch(POS_ORDER_INFO, JSON.stringify(_posOrderInfo));

    }

    const clearOrderInfoAddress = () => {

        const _posOrderInfo = {...posOrderInfo};

        delete _posOrderInfo?.addressList;
        delete _posOrderInfo?.address;

        setPosOrderInfo(_posOrderInfo);
        LocalStorageUtils.setItemForBranch(POS_ORDER_INFO, JSON.stringify(_posOrderInfo));

    }

    const createBranchOrder = async (autoComplete = false) => {
        try {

            setCreateOrderLoading(true);

            const cartDetailsList = convertCartDetailsMap();

            const _paymentTypes = posOrderInfo.paymentTypes.filter(payment => payment.isSelected && payment.amount >= 0);

            let deliveryAddress = undefined;
            if (posOrderInfo.orderType === DELIVERY) {
                if (posOrderInfo?.addressList && posOrderInfo?.addressList?.length > 0) {

                    for (let i = 0; i < posOrderInfo.addressList.length; i++) {
                        if (posOrderInfo.addressList[i].selected) {
                            deliveryAddress = posOrderInfo.addressList[i];
                            break;
                        }
                    }

                } else {
                    deliveryAddress = posOrderInfo.address;
                }
            }

            const data = {
                cartDetailsList,
                payments: _paymentTypes.length <= 0 ? [{type: "CASH", amount: 0}] : _paymentTypes,
                countryCode: posOrderInfo?.countryCode,
                fullName: posOrderInfo?.fullName,
                mobileNumber: posOrderInfo?.mobileNumber,
                email: posOrderInfo?.email,
                guestCount: posOrderInfo.guestCount,
                specialNote: posOrderInfo?.specialNote,
                tableList: posOrderInfo?.tableList,
                orderType: posOrderInfo?.orderType,
                discount: posCartInvoice.discount,
                tips: posOrderInfo?.tips,
                autoComplete,
                deliveryAddress
            }

            const res = await BranchOrderService.createBranchOrder(data);

            Toast("success", "Created", "Order has been created successfully");

            clearPosOrderLocalStorage();

            setCreateOrderLoading(false);

            return res.data;

        } catch (error) {

            setCreateOrderLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null;
        }
    }

    const updateBranchOrder = async (autoComplete = false, id) => {
        try {

            setCreateOrderLoading(true);

            const cartDetailsList = convertCartDetailsMap();

            const _paymentTypes = posOrderInfo.paymentTypes.filter(payment => payment.isSelected);

            let deliveryAddress = undefined;
            if (posOrderInfo.orderType === DELIVERY) {
                if (posOrderInfo?.addressList && posOrderInfo?.addressList?.length > 0) {

                    for (let i = 0; i < posOrderInfo.addressList.length; i++) {
                        if (posOrderInfo.addressList[i].selected) {
                            deliveryAddress = posOrderInfo.addressList[i];
                            break;
                        }
                    }

                } else {
                    deliveryAddress = posOrderInfo.address;
                }
            }

            delete posOrderInfo.paymentTypes;
            delete posOrderInfo?.addressList;
            delete posOrderInfo?.address;

            const data = {
                cartDetailsList,
                payments: _paymentTypes,
                ...posOrderInfo,
                autoComplete,
                deliveryAddress,
                discount: posCartInvoice.discount
            }

            const res = await BranchOrderService.updateBranchOrder(id, data);

            Toast("success", "Created", "Order has been updated successfully");

            if (res) {
                clearPosOrderLocalStorage();
            }

            setCreateOrderLoading(false);

            return res.data;

        } catch (error) {

            setCreateOrderLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null;
        }
    }

    const convertCartDetailsMap = () => {

        let cartDetailsList = [];

        for (let key in posOrderMap) {
            cartDetailsList.push(posOrderMap[key]);
        }

        return cartDetailsList;
    }

    const addToHold = () => {

        const id = new Date().getMilliseconds();

        const data = {
            posOrderMap,
            posOrderInfo,
            posCartInvoice,
            id
        }

        const _posOrderHoldList = [...posOrderHoldList, data]

        setPosOrderHoldList(_posOrderHoldList);
        LocalStorageUtils.setItemForBranch(POS_ORDER_HOLD_LIST, JSON.stringify(_posOrderHoldList));

        Toast("success", "Added", "Your cart has been added to hold successfully");

        clearPosOrderLocalStorage();

    }

    const clearPosOrderLocalStorage = () => {
        setPosOrderInfo(
            {
                orderType: "DINE_IN",
                paymentTypes: _paymentTypes
            }
        );
        setPosOrderMap(null);
        setPosCartInvoice(initPosCartInvoice);

        LocalStorageUtils.removeItemForBranch(POS_ORDER_CART);
        LocalStorageUtils.removeItemForBranch(POS_ORDER_INFO);
        LocalStorageUtils.removeItemForBranch(POS_ORDER_CART_INVOICE);
    }

    const resumeHoldOrder = id => {

        const findPosHoldOrder = posOrderHoldList.find(posHoldOrder => posHoldOrder.id === id);

        setPosOrderMap(findPosHoldOrder.posOrderMap);
        setPosCartInvoice(findPosHoldOrder.posCartInvoice);
        setPosOrderInfo(findPosHoldOrder.posOrderInfo);

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(findPosHoldOrder.posOrderMap));
        LocalStorageUtils.setItemForBranch(POS_ORDER_CART_INVOICE, JSON.stringify(findPosHoldOrder.posCartInvoice));
        LocalStorageUtils.setItemForBranch(POS_ORDER_INFO, JSON.stringify(findPosHoldOrder.posOrderInfo));

        let _posHoldOrderList = posOrderHoldList.filter(posHoldOrder => posHoldOrder.id !== id);

        if (posOrderMap) {

            const id = new Date().getMilliseconds();

            const data = {
                posOrderMap,
                posOrderInfo,
                posCartInvoice,
                id
            }

            _posHoldOrderList = [..._posHoldOrderList, data]
        }

        setPosOrderHoldList(_posHoldOrderList);
        LocalStorageUtils.setItemForBranch(POS_ORDER_HOLD_LIST, JSON.stringify(_posHoldOrderList));

    }

    const removeHoldOrder = id => {

        const filteredHoldList = posOrderHoldList.filter(posHoldOrder => posHoldOrder.id !== id);

        setPosOrderHoldList(filteredHoldList);
        LocalStorageUtils.setItemForBranch(POS_ORDER_HOLD_LIST, JSON.stringify(filteredHoldList));

        Toast("success", "Removed", "Hold order has been removed successfully");

    }

    const editPosOrder = async data => {

        const _posOrderMap = {};

        let addressList = [];

        if (data?.customer && data.orderType === DELIVERY) {

            const res = await CustomerService.getAllAddressByCustomer(data.customer.id);

            addressList = res.data.content.map(address => {
                if (address.id === data.deliveryAddress.id) {
                    return {
                        ...address,
                        selected: true
                    }
                } else {
                    return {...address, selected: false}
                }
            })
        }

        data.cartDetailsList.forEach(cartDetails => {

            const _productId = `${cartDetails.branchProduct.id}-${new Date().getMilliseconds()}`

            _posOrderMap[_productId] = cartDetails;
        });

        const totalPrice = (data.invoice.totalPrice + data.invoice.sdCharge + data.invoice.serviceCharge) - data.invoice.discountAmount;
        // const subTotal = data.invoice.totalPrice - data.invoice.discountAmount;

        const _posCartInvoice = {
            ...posCartInvoice,
            totalProductPrice: data.invoice.totalProductPrice,
            discount: {
                discountValue: data.invoice.discount.discountValue,
                discountType: data.invoice.discount.discountType ? data.invoice.discount.discountType : "FLAT"
            },
            subTotal: data.invoice.totalPrice,
            totalPrice,
            finalPrice: data.invoice.exactFinalPrice,
            returnAmount: data.invoice.exactFinalPrice,
            tax: data.invoice.taxAmount,
            vatAmount: data.invoice.vat,
            sdChargeAmount: data.invoice.sdCharge,
            serviceChargeAmount: data.invoice.serviceCharge,
            readOnlyDiscount: data.invoice.discountAmount,
        }

        const paymentTypes = _paymentTypes.map(paymentType => {

            const find = data.payments.find(resPayment => {
                return resPayment.type === paymentType.type
            });

            if (find) {
                return {
                    ...paymentType,
                    isSelected: true,
                    amount: find.amount
                }
            } else {
                return paymentType
            }
        })

        const _orderInfo = {
            orderType: data.orderType,
            paymentTypes,
            fullName: data.fullName,
            guestCount: data.guestCount,
            mobileNumber: data.mobileNumber,
            email: data?.email,
            specialNote: data.specialNote,
            countryCode: data.countryCode,
            tips: data.tips,
            tableList: data.tableList,
            address: data?.deliveryAddress,
            addressList
        }

        setPosOrderMap(_posOrderMap);
        setPosCartInvoice(_posCartInvoice);
        setPosOrderInfo(_orderInfo);

        LocalStorageUtils.setItemForBranch(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        LocalStorageUtils.setItemForBranch(POS_ORDER_CART_INVOICE, JSON.stringify(_posCartInvoice));
        LocalStorageUtils.setItemForBranch(POS_ORDER_INFO, JSON.stringify(_orderInfo));

    }

    const clearPosOrderCart = () => {

        clearPosOrderLocalStorage();

    }

    // this is for customer return amount calculation
    const handlePaidAmountChange = value => {

        const _posCartInvoice = {
            ...posCartInvoice,
            customerPaid: value,
            returnAmount: value > 0 ? value - posCartInvoice?.finalPrice : posCartInvoice.finalPrice
        }

        setPosCartInvoice(_posCartInvoice);
        LocalStorageUtils.setItemForBranch(POS_ORDER_CART_INVOICE, JSON.stringify(_posCartInvoice));

    }

    return (
        <PosOrderContext.Provider
            value={{
                posOrderMap,
                addPosOrder,
                removeItem,
                incrementHandle,
                addPosCartAddOn,
                incrementAddOn,
                removeAddOn,
                handleDialPadChange,
                posOrderSelectedItemId,
                setPosOrderSelectedItemId,
                handleDeleteDialPad,
                clearDialPad,
                posCartInvoice,
                changePosCartDiscount,
                handlePosOrderInfoChange,
                posOrderInfo,
                createOrderLoading,
                createBranchOrder,
                addToHold,
                posOrderHoldList,
                resumeHoldOrder,
                removeHoldOrder,
                editPosOrder,
                clearPosOrderCart,
                updateBranchOrder,
                clearPosOrderInfoCustomer,
                posOrderProductMultiplyCount,
                setPosOrderProductMultiplyCount,
                clearOrderInfoAddress,
                handlePaidAmountChange,
                setPosOrderMap,
                setPosCartInvoice,
                setPosOrderInfo,
                initPosCartInvoice,
                _paymentTypes,
                setPosOrderHoldList,
            }}
        >
            {children}
        </PosOrderContext.Provider>
    );
}

export default PosOrderContextProvider;
