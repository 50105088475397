import {useContext, useEffect, useState} from 'react';
import {RESTAURANT_TOKEN, STATIC_DATA} from "../helpers/Constant";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import GlobalConfigService from "../services/GlobalConfigService";
import LocalStorageUtils from "../helpers/LocalStorageUtils";
import {ThemeContext} from "../contexts/ThemeContextProvider";

const useRestaurantTokenInitializer = () => {

    const {setStaticData} = useContext(ThemeContext);

    const restaurantToken = LocalStorageUtils.getItem(RESTAURANT_TOKEN);


    // let domain = "192.168.10.13:3000";
    // let domain = "zaag.zenresto.com";
    // let domain = "manik.zenresto.com";
    let domain = window.origin
        .replaceAll("http://", "")
        .replaceAll("https://", "");


    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (!restaurantToken) {

            getRestaurantTokenByDomain();

        } else {

            setLoading(false);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRestaurantTokenByDomain = async () => {

        try {

            const res = await GlobalConfigService.getRestaurantTokenByDomain({domain});
            LocalStorageUtils.setItem(STATIC_DATA, JSON.stringify(res.data));
            LocalStorageUtils.setItem(RESTAURANT_TOKEN, res.data.token);

            setStaticData(res.data);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return {loading};
};

export default useRestaurantTokenInitializer;
