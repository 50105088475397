import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchIngredientService {

    static createIngredient = data => privateAPI.post(
        `${URL.CREATE_INGREDIENT}`, data
    );

    static getAllIngredientForBranch = params => privateAPI.get(
        `${URL.GET_BRANCH_INGREDIENT_LIST}/${getBranchId()}`, {params}
    );

    static getIngredientBranchById = categoryId => privateAPI.get(
        `${URL.GET_BRANCH_INGREDIENT_LIST_BY_ID}/${categoryId}/${getBranchId()}`
    );

    static updateIngredientBranch = (categoryId, data) => privateAPI.put(
        `${URL.UPDATE_INGREDIENT_BRANCH_LIST_BY_ID}/${categoryId}/${getBranchId()}`, data
    );

    static getAllUnits = params => privateAPI.get(
        `${URL.GET_ALL_UNIT}`, {params}
    );

    static getBranchIngredientReport = params => privateAPI.get(
        `${URL.GET_BRANCH_INGREDIENT_REPORT}/${getBranchId()}`, {params}
    );

}
