import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import {getBranchId} from "../helpers/Utils";

export default class BranchSupplierService {

    static createBranchSupplier = data => privateAPI.post(
        `${URL.POST_SUPPLIER}`, data
    );
    static assignIngredientToSupplier = (supplierId, data) => privateAPI.post(
        `${URL.ASSIGN_INGREDIENT_TO_SUPPLIER}/${supplierId}/${getBranchId()}`, data
    );

    static getAllBranchSuppliers = params => privateAPI.get(
        `${URL.GET_SUPPLIER_LIST}`, {params}
    );

    static getBranchSupplierById = categoryId => privateAPI.get(
        `${URL.GET_SUPPLIER_BY_ID}/${categoryId}`
    );

    static updateBranchSupplier = (categoryId, data) => privateAPI.put(
        `${URL.UPDATE_SUPPLIER}/${categoryId}`, data
    );

    static getSupplierByByIngredientId = ingredientId=> privateAPI.get(

        `${URL.GET_SUPPLIER_BY_INGREDIENT_ID}/${ingredientId}/${getBranchId()}`
    );
}
