import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import BranchSupplierService from "../services/BranchSupplierService";


export const BranchSupplierContext = createContext("BranchSupplierContext");

const BranchSupplierContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [branchSupplierTotalElements, setBranchSupplierTotalElements] = useState(0);
    const [branchSupplierList, setBranchSupplierList] = useState([]);

    const getAllBranchSuppliers = async params => {
        try {

            setLoading(true);

            const res = await BranchSupplierService.getAllBranchSuppliers(params);
            setBranchSupplierList(res.data.content);
            setBranchSupplierTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <BranchSupplierContext.Provider
            value={{
                loading,
                branchSupplierTotalElements,
                branchSupplierList,
                getAllBranchSuppliers,
            }}
        >
            {children}
        </BranchSupplierContext.Provider>
    );
}

export default BranchSupplierContextProvider;
