import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import SupplierService from "../services/SupplierService";

export const SupplierContext = createContext("SupplierContext");

const SupplierContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [supplierTotalElements, setSupplierTotalElements] = useState(0);
    const [supplierList, setSupplierList] = useState([]);

    const getAllSuppliers = async params => {
        try {

            setLoading(true);

            const res = await SupplierService.getAllSuppliers(params);
            setSupplierList(res.data.content);
            setSupplierTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <SupplierContext.Provider
            value={{
                loading,
                supplierTotalElements,
                supplierList,
                getAllSuppliers,
            }}
        >
            {children}
        </SupplierContext.Provider>
    );
}

export default SupplierContextProvider;
