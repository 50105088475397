import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class AgentPaymentService {

    static createAgentPayment = data => privateAPI.post(
        `${URL.CREATE_AGENT_PAYMENT_URL}/${getBranchId()}`, data
    );

    static createStockPayment = (data, stockTransactionId) => privateAPI.post(
        `${URL.CREATE_STOCK_PAYMENT_URL}/${getBranchId()}/${stockTransactionId}`, data, {params: {adjusted: data.adjusted}}
    );

    static getAllAgentPayment = params => privateAPI.get(
        `${URL.GET_ALL_AGENT_PAYMENT_URL}/${getBranchId()}`, {params}
    );

    static getAgentPaymentById = id => privateAPI.get(
        `${URL.GET_AGENT_PAYMENT_BY_ID_URL}/${id}/${getBranchId()}`
    );

    static updateAgentPayment = (id, data) => privateAPI.put(
        `${URL.UPDATE_GET_AGENT_PAYMENT_URL}/${id}/${getBranchId()}`, data
    );

    static cancelAgentPayment = id => privateAPI.put(
        `${URL.CANCEL_AGENT_PAYMENT_URL}/${id}/${getBranchId()}`, null
    );
}
