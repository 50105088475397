import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";

export default class IngredientCategoryService {

    static createCategory = data => privateAPI.post(
        `${URL.CREATE_INGREDIENT_PRODUCT_CATEGORY}`, data
    );

    static getAllIngredientCategory = params => privateAPI.get(
        `${URL.GET_ALL_INGREDIENT_PRODUCT_CATEGORY}`, {params}
    );

    static getIngredientCategoryById = categoryId => privateAPI.get(
        `${URL.GET_ALL_INGREDIENT_PRODUCT_CATEGORY_ID}/${categoryId}`
    );

    static updateIngredientCategory = (categoryId, data) => privateAPI.put(
        `${URL.UPDATE_INGREDIENT_PRODUCT_CATEGORY}/${categoryId}`, data
    );

}
