import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import BranchCateringGlobalMenuService from "../../services/branch_catering/BranchCateringGlobalMenuService";

export const BranchCateringGlobalMenuContext = createContext("BranchCateringGlobalMenuContext");

const BranchCateringGlobalMenuContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [branchGlobalCateringMenuList, setBranchGlobalCateringMenuList] = useState([]);

    const [branchCateringMenuAssignedIds, setBranchGlobalCateringMenuAssignedIds] = useState([]);

    const getAllBranchGlobalCateringMenu = async params => {
        try {

            setLoading(true);

            const res = await BranchCateringGlobalMenuService.getAllGlobalBranchCateringMenu(params);

            setBranchGlobalCateringMenuList(res.data.globalCateringMenus.content);
            setBranchGlobalCateringMenuAssignedIds(res.data.branchCateringMenuIds);
            setTotalElements(res.data.globalCateringMenus.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchCateringGlobalMenuContext.Provider
            value={{
                loading,
                totalElements,
                branchGlobalCateringMenuList,
                branchCateringMenuAssignedIds,
                getAllBranchGlobalCateringMenu,
            }}
        >
            {children}
        </BranchCateringGlobalMenuContext.Provider>
    );
}

export default BranchCateringGlobalMenuContextProvider;
