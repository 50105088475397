import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";

export default class ProductService {

    static createProduct = data => privateAPI.post(
        `${URL.CREATE_PRODUCT}`, data
    );

    static getAllProduct = params => privateAPI.get(
        `${URL.GET_ALL_PRODUCT}`, {
            params,
            paramsSerializer: {
                indexes: null // by default: false
            }
        }
    );

    static getProductById = productId => privateAPI.get(
        `${URL.GET_PRODUCT_BY_ID}/${productId}`
    );

    static updateProduct = (productId, data) => privateAPI.put(
        `${URL.UPDATE_PRODUCT}/${productId}`, data
    );

    // requested product
    static getAllRequestedProduct = params => privateAPI.get(
        `${URL.GET_ALL_REQUESTED_PRODUCT}`, {
            params,
            paramsSerializer: {
                indexes: null // by default: false
            }
        }
    );


}
