import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchRequestAddOnsService {
    static createBranchRequestAddOns = data => privateAPI.post(
        `${URL.CREATE_BRANCH_REQUEST_ADD_ONS}/${getBranchId()}`, data
    );

    static getAllBranchRequestAddOns = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH_REQUEST_ADD_ONS}/${getBranchId()}`, {params}
    );

    static getBranchRequestAddOnsById = id => privateAPI.get(
        `${URL.GET_BRANCH_REQUEST_ADD_ONS_BY_ID}/${id}/${getBranchId()}`
    );

    static updateBranchRequestAddOns = (id, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH_REQUEST_ADD_ONS}/${id}/${getBranchId()}`, data
    );

}
