import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import BillingService from "../services/BillingService";

export const BillingContext = createContext("BillingContext");

const BillingContextProvider = ({children}) => {

    // const intl = useIntl();

    // const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [totalElements, setTotalElements] = useState(0);

    const [couponCode, setCouponCode] = useState(null);
    const [systemConfigLoading, setSystemConfigLoading] = useState(false);

    const [systemConfigList, setSystemConfigList] = useState(null);

    const [payableAmount, setPayableAmount] = useState(null);

    const [transactionList, setTransactionList] = useState(null);
    const [transactionLoading, setTransactionLoading] = useState(false);

    const getAllValidateCoupon = async params => {
        try {

            setLoading(true);

            const res = await BillingService.getAllValidateCoupon(params);
            setCouponCode({
                amount: res.data,
                title: params.code
            });

            setPayableAmount({
                ...payableAmount,
                disCountAmount: payableAmount?.currentAmount,
                // amount: payableAmount.amount - res.data // get amount after coupon apply
                amount: payableAmount?.currentAmount - res.data // get amount after coupon apply
            });

            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getSystemConfigs = async params => {
        try {

            setSystemConfigLoading(true);

            const res = await BillingService.getAllSystemConfig(params);
            setSystemConfigList(res.data);
            setPayableAmount({
                ...payableAmount,
                amount: res.data?.subscriptionRate,
                currentAmount: res.data?.subscriptionRate
            });

            setSystemConfigLoading(false);

        } catch (error) {

            setSystemConfigLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getAllTransactions = async params => {
        try {

            setTransactionLoading(true);

            const res = await BillingService.getAllTransactions(params);
            setTransactionList(res.data);

            setTransactionLoading(false);

        } catch (error) {

            setTransactionLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const removeCouponCode = () => {
        setPayableAmount({
            amount: payableAmount?.amount + couponCode?.amount,
            disCountAmount: null
        });
        setCouponCode(null);
    }

    const clearPayment = () => {
        setCouponCode(null);
        setPayableAmount(null);
        setTransactionList(null);
    }

    return (
        <BillingContext.Provider
            value={{
                loading,
                totalElements,
                couponCode,
                getAllValidateCoupon,
                systemConfigLoading,
                systemConfigList,
                getSystemConfigs,
                removeCouponCode,
                payableAmount,
                getAllTransactions,
                transactionList,
                transactionLoading,
                clearPayment
            }}
        >
            {children}
        </BillingContext.Provider>
    );
}

export default BillingContextProvider;