import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchCateringGlobalMenuService {

    static assignBranchCateringMenu = data => privateAPI.post(
        `${URL.ASSIGN_BRANCH_CATERING_MENU_URL}/${getBranchId()}`,
        data
    );
    static getAllBranchCateringMenu = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH_CATERING_MENU_URL}/${getBranchId()}`,
        {params}
    );
    static getBranchCateringMenuById = id => privateAPI.get(
        `${URL.GET_BRANCH_CATERING_MENU_BY_ID_URL}/${id}/${getBranchId()}`
    );
    static updateBranchCateringMenu = (data, id) => privateAPI.put(
        `${URL.UPDATE_BRANCH_CATERING_MENU_URL}/${id}/${getBranchId()}`, data
    );

    static getGlobalCateringMenuById = id => privateAPI.get(
        `${URL.GET_GLOBAL_BRANCH_CATERING_MENU_BY_ID_URL}/${id}/${getBranchId()}`
    );

    static requestBranchCateringMenu = data => privateAPI.post(
        `${URL.REQUEST_BRANCH_CATERING_MENU_URL}/${getBranchId()}`, data
    );

    static updateRequestedBranchCateringMenu = (id, data) => privateAPI.put(
        `${URL.UPDATE_REQUESTED_BRANCH_CATERING_MENU_URL}/${id}/${getBranchId()}`, data
    );


    // branch all catering menu -> global
    static getAllGlobalBranchCateringMenu = params => privateAPI.get(
        `${URL.GET_ALL_GLOBAL_BRANCH_CATERING_MENU_URL}/${getBranchId()}`,
        {params});

}
