import * as URL from "../configs/APIUrl";
import axios from "axios";

export default class PrintService {

    static createPrint = data => {

        console.log()

        // return privateAPI.post(
        //     `${URL.PRINT_URL}`, data,
        //     {
        //         headers: {
        //             'Content-Type': 'text/plain'
        //         },
        //     }
        // );

        return axios.post(`${URL.PRINT_URL}`, data, {
            headers: {
                'Content-Type': 'text/plain'
            },
        });
    }
}