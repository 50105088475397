import React, {createContext, useState} from 'react';
import StockTransactionService from "../../services/StockTransactionService";
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";

export const StockTransactionContext = createContext("StockTransactionContext");

const StockTransactionContextProvider = ({children}) => {


    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [stockTransactionList, setStockTransactionList] = useState([]);

    const [stocksLoading, setStocksLoading] = useState(false);
    const [stocksTotalElements, setStocksTotalElements] = useState(0);
    const [stockList, setStockList] = useState([]);

    const getAllStockTransaction = async params => {
        try {

            setLoading(true);

            const res = await StockTransactionService.getAllStockTransaction(params);
            setStockTransactionList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllStock = async params => {
        try {

            setStocksLoading(true);

            const res = await StockTransactionService.getStockTransactionsByIngredientId(params);
            setStockList(res.data.content);
            setStocksTotalElements(res.data.totalElements);

            setStocksLoading(false);

        } catch (error) {

            setStocksLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <StockTransactionContext.Provider
            value={{
                loading,
                totalElements,
                stockTransactionList,
                stocksLoading,
                stocksTotalElements,
                stockList,
                setStockList,
                getAllStockTransaction,
                getAllStock,
            }}
        >
            {children}
        </StockTransactionContext.Provider>
    );
}

export default StockTransactionContextProvider;
