import React, {createContext, useState} from 'react';
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";
import BranchReportService from "../services/BranchReportService";


export const BranchReportContext = createContext("BranchReportContext");

const BranchReportContextProvider = ({children}) => {

    const [branchReportTotalLoading, setBranchReportTotalLoading] = useState(false);
    const [branchReportTotal, setBranchReportTotal] = useState(null);

    const [orderSummaryLoading, setOrderSummaryLoading] = useState(false);
    const [orderSummary, setOrderSummary] = useState(null);
    const [orderSummaryDonut, setOrderSummaryDonut] = useState([]);

    const [orderTypeLoading, setOrderTypeLoading] = useState(false);
    const [orderType, setOrderType] = useState(null);
    const [orderTypeDonut, setOrderTypeDonut] = useState([]);

    const [orderReviewLoading, setOrderReviewLoading] = useState(false);
    const [orderReviewList, setOrderReviewList] = useState([]);

    const [productReviewLoading, setProductReviewLoading] = useState(false);
    const [productReviewList, setProductReviewList] = useState([]);

    const [branchTopProductLoading, setBranchTopProductLoading] = useState(false);
    const [branchTopProductList, setBranchTopProductList] = useState([]);

    const [branchOrderLineChartLoading, setBranchOrderLineChartLoading] = useState(false);
    const [branchOrderLineChartList, setBranchOrderLineChartList] = useState([]);
    const [totalBranchOrderLineChart, setTotalBranchOrderLineChart] = useState(0);

    const orderTypeName = {
        dine_in: "Dine-in",
        delivery: "Delivery",
        takeaway: "Take-away",
        catering: "Catering"
    }

    const getBranchReportTotalCount = async params => {
        try {

            setBranchReportTotalLoading(true);

            const res = await BranchReportService.getBranchReportTotalCount(params);
            setBranchReportTotal(res.data);

            setBranchReportTotalLoading(false);

        } catch (error) {

            setBranchReportTotalLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getBranchReportOrderSummary = async params => {
        try {

            setOrderSummaryLoading(true);

            const res = await BranchReportService.getBranchReportOrderSummary(params);

            const orderSummary = {
                completed: res.data.delivered,
                ongoing: res.data.ongoing,
                cancelled: res.data.cancelled,
                total: (res.data.delivered + res.data.cancelled + res.data.ongoing)
            }

            const list = Object.entries(res.data).map(([key, value]) => {
                return {
                    type: key,
                    value
                }
            });

            setOrderSummaryDonut(list);
            setOrderSummary(orderSummary);

            setOrderSummaryLoading(false);

        } catch (error) {

            setOrderSummaryLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getBranchOrderType = async params => {
        try {

            setOrderTypeLoading(true);

            const res = await BranchReportService.getBranchReportOrderType(params);

            const orderType = {
                delivery: res.data.delivery,
                takeaway: res.data.takeaway,
                dineIn: res.data.dine_in,
                catering: res.data.catering,
                total: (res.data.delivery + res.data.takeaway + res.data.dine_in + res.data.catering)
            }

            const list = Object.entries(res.data).map(([key, value]) => {
                return {
                    type: orderTypeName[key],
                    value
                }
            });

            setOrderTypeDonut(list);
            setOrderType(orderType);

            setOrderTypeLoading(false);

        } catch (error) {

            setOrderTypeLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }
    }

    const getBranchReportOrderReview = async params => {
        try {

            setOrderReviewLoading(true);

            const res = await BranchReportService.getBranchReportReview(params);

            setOrderReviewList(res.data);

            setOrderReviewLoading(false);

        } catch (error) {

            setOrderReviewLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }
    }

    const getBranchReportProductReview = async params => {
        try {

            setProductReviewLoading(true);

            const res = await BranchReportService.getBranchReportReview(params);

            setProductReviewList(res.data);

            setProductReviewLoading(false);

        } catch (error) {

            setProductReviewLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }
    }

    const getBranchReportTopProduct = async params => {
        try {

            setBranchTopProductLoading(true);

            const res = await BranchReportService.getBranchReportTopProduct(params);

            setBranchTopProductList(res.data);

            setBranchTopProductLoading(false);

        } catch (error) {

            setBranchTopProductLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }
    }

    const getBranchOrderLineChart = async params => {
        try {

            setBranchOrderLineChartLoading(true);

            const res = await BranchReportService.getBranchReportOrderLineChart(params);

            const map = Object.entries(res.data.data).map(([key, value]) => {
                return {
                    date: key,
                    order: value
                }
            });

            setBranchOrderLineChartList(map);
            setTotalBranchOrderLineChart(res.data.total);

            setBranchOrderLineChartLoading(false);

        } catch (error) {

            setBranchOrderLineChartLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchReportContext.Provider
            value={{
                branchReportTotalLoading,
                branchReportTotal,
                getBranchReportTotalCount,
                orderSummaryLoading,
                orderSummary,
                orderSummaryDonut,
                getBranchReportOrderSummary,
                orderTypeLoading,
                orderType,
                orderTypeDonut,
                getBranchOrderType,
                orderReviewLoading,
                orderReviewList,
                getBranchReportOrderReview,
                productReviewLoading,
                productReviewList,
                getBranchReportProductReview,
                branchTopProductLoading,
                branchTopProductList,
                getBranchReportTopProduct,
                branchOrderLineChartLoading,
                branchOrderLineChartList,
                totalBranchOrderLineChart,
                getBranchOrderLineChart,
            }}
        >
            {children}
        </BranchReportContext.Provider>
    );
}

export default BranchReportContextProvider;
