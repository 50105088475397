const {REACT_APP_SOCKET_URL} = process.env;

export const SOCKET_URL = REACT_APP_SOCKET_URL + '/ws';

export const BRANCH_SUBSCRIPTION_PATH = '/branch';
export const BRANCH_ASK_HOST_SUBSCRIPTION_PATH = '/ask-host';
export const RESTAURANT_SUBSCRIPTION_PATH = '/restaurant';

export const BRANCH_SUBSCRIPTION_LEVEL = 'BRANCH_ROOM';
export const RESTAURANT_SUBSCRIPTION_LEVEL = 'RESTAURANT_ROOM';

export const SOCKET_RECONNECT_TIME_SEC = 5 * 1000;

