import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import BranchProductService from "../../services/branch_product/BranchProductService";

export const BranchProductContext = createContext("BranchProductContext");

const BranchProductContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [branchProductList, setBranchProductList] = useState([]);
    const [branchProductRelatedList, setBranchProductRelatedList] = useState([]);

    const [reportBranchProductList, setReportBranchProductList] = useState([]);
    const [totalReportsElements, setTotalReportsElements] = useState(0);
    const [loadingReport, setLoadingReport] = useState(false);


    const [relatedProductLoading, setRelatedProductLoading] = useState(false);
    const [relatedProductList, setRelatedProductList] = useState([]);

    const [qrCodeProductLoading, setQrCodeProductLoading] = useState(false);
    const [qrCodeProductList, setQrCodeProductList] = useState([]);
    const [qrCodeProductTotalElements, setQrCodeProductTotalElements] = useState(0);

    const [restaurant, setRestaurant] = useState([]);


    const getAllBranchProduct = async params => {
        try {

            setLoading(true);

            const res = await BranchProductService.getAllBranchProduct(params);
            setBranchProductList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllReportBranchProduct = async params => {
        try {

            setLoadingReport(true);

            const res = await BranchProductService.getAllBranchProduct(params);
            setReportBranchProductList(res.data.content);
            setTotalReportsElements(res.data.totalElements);

            setLoadingReport(false);

        } catch (error) {

            setLoadingReport(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllBranchProductForRelatedProduct = async params => {
        try {

            setLoading(true);

            const res = await BranchProductService.getAllBranchProduct(params);
            setBranchProductRelatedList(res.data.content);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllRelatedProduct = async id => {
        try {

            setRelatedProductLoading(true);

            const res = await BranchProductService.getAllRelatedProduct(id);
            setRelatedProductList(res.data);

            setRelatedProductLoading(false);

            return res?.data;

        } catch (error) {

            setRelatedProductLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return [];

        }
    }

    const getLoadMoreBranchProducts = async params => {
        try {

            setLoading(true);

            const res = await BranchProductService.getAllBranchProduct(params);

            setBranchProductList([...branchProductList, ...res.data.content]);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllBranchProductByQrCode = async (id, params) => {
        try {

            setQrCodeProductLoading(true);

            const res = await BranchProductService.getAllBranchProductByQrCode(id, params);
            setQrCodeProductList(res.data.content);
            setQrCodeProductTotalElements(res.data.totalElements);

            setQrCodeProductLoading(false);

        } catch (error) {

            setQrCodeProductLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getLoadMoreQrCodeBranchProducts = async (id, params) => {
        try {

            setQrCodeProductLoading(true);

            const res = await BranchProductService.getAllBranchProductByQrCode(id, params);

            setQrCodeProductList([...qrCodeProductList, ...res.data.content]);
            setQrCodeProductTotalElements(res.data.totalElements);

            setQrCodeProductLoading(false);

        } catch (error) {

            setQrCodeProductLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getRestaurantByBranchId = async () => {
        try {

            setQrCodeProductLoading(true);

            const res = await BranchProductService.getRestaurantById();
            setRestaurant(res.data);

            setQrCodeProductLoading(false);

        } catch (error) {

            setQrCodeProductLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchProductContext.Provider
            value={{
                loading,
                relatedProductLoading,
                totalElements,
                branchProductList,
                branchProductRelatedList,
                relatedProductList,
                qrCodeProductLoading,
                qrCodeProductList,
                qrCodeProductTotalElements,
                getAllBranchProduct,
                getAllRelatedProduct,
                getAllBranchProductForRelatedProduct,
                getLoadMoreBranchProducts,
                getAllBranchProductByQrCode,
                getLoadMoreQrCodeBranchProducts,
                reportBranchProductList,
                totalReportsElements,
                loadingReport,
                getAllReportBranchProduct,
                getRestaurantByBranchId,
                restaurant
            }}
        >
            {children}
        </BranchProductContext.Provider>
    );
}

export default BranchProductContextProvider;
