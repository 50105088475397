import React from 'react';
import {FormattedMessage} from "react-intl";

const Translator = (id, value = {}) => {

    if (!id) {
        console.error("i18_key is 'undefined'");
        return "i18_key is 'undefined'";
    }

    return <FormattedMessage id={id} values={{...value}}/>;
};

export default Translator;
